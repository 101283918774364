/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EventService, InactivityService, NavEvent } from '@tdoe/design-system';
import { HeaderNavItemsService } from './services/header-nav-items/header-nav-items.service';
import { registerMgtLoginComponent } from '@microsoft/mgt-components';
import { ConfigService } from './services/config/config.service';
import packageInfo from '../../package.json';
import { combineLatest, debounceTime, firstValueFrom, map, startWith } from 'rxjs';
import { UserService } from './services/user/user.service';
import { Providers } from '@microsoft/mgt';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public viewModel$ = combineLatest([
    this._configService.getApiVersion().pipe(startWith(undefined)),
    this._eventService.onLoadingTextChange.pipe(startWith('Loading...')),
    this._headerNavItemsService.getNavItems(),
    this._userService.userProfile$
  ]).pipe(
    debounceTime(300),
    map(([apiVersion, loadingText, navItems, profile]) => ({apiVersion, loadingText, navItems, profile})),
    map(data => ({
      apiVersion: data.apiVersion?.version,
      clientVersion: packageInfo.version,
      loadingText: data.loadingText ?? 'Loading...',
      navItems: data.navItems,
      profile: data.profile
    }))
  );

  public showPanel = false;

  public constructor(
    private readonly _router: Router,
    private readonly _headerNavItemsService: HeaderNavItemsService,
    private readonly _inactivityService: InactivityService,
    private readonly _configService: ConfigService,
    private readonly _eventService: EventService,
    private readonly _userService: UserService,
    private readonly msalService: MsalService) {
    console.log('AppComponent -> constructor');
  }

  public async ngOnInit(): Promise<void> {
    console.log('AppComponent -> ngOnInit -> Enter');
    this._inactivityService.startMonitoring();
    await this.msalService.instance.initialize();
    await firstValueFrom(this.msalService.initialize());
    console.log('AppComponent -> constructor -> Exit');
  }

  public onNavClick($event: NavEvent): void {
    if ($event.source.children) return;
    this._router.navigate([$event.source.id]);
  }

  public onPanelClosed(): void {
    console.debug('AppComponent -> onPanelClosed');
    this.showPanel = false;
  }

  public togglePanel(): void {
    console.debug('AppComponent -> togglePanel', { showPanel: this.showPanel });
    this.showPanel = !this.showPanel;
  }
}
