import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ScopeContextFilterComponent } from 'app/components/shared/scope-context-filter/scope-context-filter.component';
import { ScopeContextFilterModel } from 'app/components/shared/scope-context-filter/scope-context-filter.model';
import { PageScope } from 'app/enums/page-scope';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';
import { StaffFilterComponent } from '../shared/staff-filter/staff-filter.component';
import { StaffTableComponent } from '../shared/staff-table/staff-table.component';
import { StaffModel } from 'app/services/staff/staff.model';
import { TableDataModel } from 'app/models/table-data-model';
import { StaffService } from 'app/services/staff/staff.service';

@Component({
  selector: 'app-data-lookup-staff',
  standalone: true,
  imports: [
    CommonModule,
    ScopeContextFilterComponent,
    StaffFilterComponent,
    StaffTableComponent,
  ],
  templateUrl: './data-lookup-staff.component.html',
  styleUrls: ['./data-lookup-staff.component.scss']
})
export class DataLookupStaffComponent {
  private _staffTablePaginationSubject$ = new BehaviorSubject<TableDataModel.Pagination>({ pageIndex: 0, pageSize: 50 });
  private _staffTableSortingSubject$ = new BehaviorSubject<TableDataModel.Sorting>({});
  private _staffSearchTermsSubject$ = new BehaviorSubject<StaffModel.StaffSearchTerms>({});
  private _pageScopeSubject$ = new BehaviorSubject<PageScope>(PageScope.State);
  private _selectedYearSubject$ = new BehaviorSubject<number>(new Date().getFullYear());

  protected viewModel$ = combineLatest([
    this._pageScopeSubject$,
    this._selectedYearSubject$,
    this._staffSearchTermsSubject$,
    this._staffTablePaginationSubject$,
    this._staffTableSortingSubject$
  ]).pipe(
    debounceTime(300),
    switchMap(([pageScope, year, searchTerms]) => {
      const combinedSearchTerms: StaffModel.StaffSearchTerms = {
        ...searchTerms,
        schoolIds: searchTerms.schoolIds,
        districtIds: searchTerms.districtIds
      };

      return this.staffService.getStaffBySearchTerms(combinedSearchTerms).pipe(
        catchError(error => {
          console.error('Error fetching staff members:', error);
          return of([]);
        }),
        map((staffData) => ({
          userPageScope: pageScope,
          selectedPageScope: pageScope,
          selectedYear: year,
          selectedSchoolIds: searchTerms.schoolIds,
          selectedDistrictIds: searchTerms.districtIds,
          staffData,
          staffDataTotalRecords: staffData.length,
          selectedScopeDescriptor: `${year} - ${this.getScopeDescriptor(pageScope)}`
        }))
      );
    })
  );

  public constructor(private staffService: StaffService) { }

  protected onStaffTableSortClicked(sorting: TableDataModel.Sorting): void {
    this._staffTableSortingSubject$.next(sorting);
  }

  protected onStaffTablePageChanged(pagination: TableDataModel.Pagination): void {
    this._staffTablePaginationSubject$.next(pagination);
  }

  protected onContextFilterSearchClicked(selectedData: ScopeContextFilterModel.SelectedData): void {
    const { schoolIds, districtIds, year, pageScope } = selectedData;
    this._pageScopeSubject$.next(pageScope);
    this._selectedYearSubject$.next(year);

    const updatedSearchTerms: StaffModel.StaffSearchTerms = {
      ...this._staffSearchTermsSubject$.value,
      schoolIds: schoolIds && schoolIds.length > 0 ? schoolIds : undefined,
      districtIds: districtIds && districtIds.length > 0 ? districtIds : undefined
    };

    this._staffSearchTermsSubject$.next(updatedSearchTerms);
  }

  protected onStaffFilterSearchClicked(staffSearchTerms: StaffModel.StaffSearchTerms): void {
    this._staffSearchTermsSubject$.next(staffSearchTerms);
  }

  protected onStaffFilterResetClicked(): void {
    const resetSearchTerms: StaffModel.StaffSearchTerms = {};
    this._staffSearchTermsSubject$.next(resetSearchTerms);
    this.onContextFilterSearchClicked({
      pageScope: PageScope.State,
      year: new Date().getFullYear(),
      schoolIds: undefined,
      districtIds: undefined
    });
  }

  private getScopeDescriptor(pageScope: PageScope): string {
    switch (pageScope) {
      case PageScope.State:
        return 'Statewide';
      case PageScope.District:
        return 'District';
      case PageScope.School:
        return 'School';
      default:
        return '';
    }
  }
}