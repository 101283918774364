export * as StudentModel from './student.model';

import { StudentDemographics, StudentEnrollment } from '../../dto';

/**
 * @deprecated Use sword.client\src\app\dto\student.ts instead
 */
export interface Student {
  id: string,
  nameFirst: string,
  nameLast: string,
  nameMiddle: string,
  ssid: string,
  serviceType: string,
  grade: number;
  enrollmentStartDate: Date,
  enrollmentEndDate: Date,
  code: string,
  withdrawalEndDate: Date,
  withdrawalCode: string,
  language: string,
  ethnicity: string,
  gender: string,
  district: string,
  serviceDistrict: string,
  school: string,
  serviceSchool: string,
  attendanceDate: Date,
  attendanceType: string,
  standardDayMinutes: number;
  label: string,
  localClassNumber: string,
  courseCode: string,
  classType: string,
  vocOutsideIP: string,
  enrolmentPeriodStartDate: Date,
  enrolmentPeriodEndDate: Date,
  assignmentStartDates: Date,
  assignmentEndDates: Date,
  classStartDates: Date,
  classification: string,
  beginDate: Date,
  endDate: Date,
  clubID: string,
  enrollmentPeriodStart: Date,
  enrollmentPeriodEnd: Date,
  disciplinaryPeriodBegin: Date,
  disciplinaryPeriodEnd: Date,
  disciplinaryType: string,
  disciplinaryReason: string,
  sped: string,
  zeroTolerance: string,
  demographics: StudentDemographics;
  enrollment: StudentEnrollment;
}

export interface StudentSearchTerms {
  year?: number,
  schoolIds?: string[],
  districtIds?: string[],
  nameLast?: string,
  nameFirst?: string,
  nameMiddle?: string,
  ssid?: string,
  dateOfBirth?: Date,
  grades?: string[],
  serviceType?: string,
  enrollmentStartDate?: Date,
  enrollmentCode?: string,
  enrollmentClassification?: string,
  tos?: string,
  fundingEligibility?: string,
  withdraw?: string,
  attendanceDate?: Date,
  attendanceType?: string,
  label?: string,
  localClassNumber?: string,
  courseCode?: string,
  classType?: string,
  vocOutsideIP?: string,
  enrolmentPeriodStartDate?: Date,
  enrolmentPeriodEndDate?: Date,
  assignmentStartDates?: Date,
  assignmentEndDates?: Date,
  classStartDates?: Date,
  classification?: string,
  beginDate?: Date,
  classificationEndDate?: Date,
  creditsEarned?: string,
  numericGrade?: string,
  optionLevel?: string,
  optionNumber?: string,
  specEdBeginDate?: Date,
  specEdEndDate?: Date,
  specEdDisabilityLevel?: string,
  specEdDisabilityType?: string,
  specEdDisabilityBeginDate?: Date,
  specEdDisabilityEndDate?: Date,
  standardReportPeriod?: string,
  standardStudentAdm?: string,
  standardStudentAda?: string,
  vocationalReportPeriod?: string,
  vocationalProgramItem?: string,
  vocationalStudentAdm?: string,
  vocationalStudentAda?: string,
  clubID?: string,
  clubBeginDate?: Date,
  clubEndDate?: Date,
  enrollmentPeriodStart?: Date,
  enrollmentPeriodEnd?: Date,
  disciplinaryPeriodBegin?: Date,
  disciplinaryPeriodEnd?: Date,
  disciplinaryType?: string,
  disciplinaryReason?: string,
  sped?: string,
  zeroTolerance?: string,
}

export interface GenderSummary {
  gender: string,
  count: number;
}

export interface EthnicitySummary {
  ethnicity: string,
  count: number;
}