import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { BreadcrumbComponent } from 'app/components/shared/breadcrumb/breadcrumb.component';
import { ScopeContextFilterComponent } from 'app/components/shared/scope-context-filter/scope-context-filter.component';
import { ScopeContextFilterModel } from 'app/components/shared/scope-context-filter/scope-context-filter.model';
import { PageScope } from 'app/enums/page-scope';
import { BehaviorSubject, combineLatest, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { StaffFilterComponent } from '../shared/staff-filter/staff-filter.component';
import { StaffTableComponent } from '../shared/staff-table/staff-table.component';
import { StaffModel } from 'app/services/staff/staff.model';
import { TableDataModel } from 'app/models/table-data-model';

@Component({
  selector: 'app-data-lookup-staff',
  standalone: true,
  imports: [
    CommonModule,
    BreadcrumbComponent,
    ScopeContextFilterComponent,
    StaffFilterComponent,
    StaffTableComponent
  ],
  templateUrl: './data-lookup-staff.component.html',
  styleUrls: ['./data-lookup-staff.component.scss']
})
export class DataLookupStaffComponent {
  private _staffTablePaginationSubject$ = new BehaviorSubject<TableDataModel.Pagination>({ pageIndex: 0, pageSize: 50 });
  private _staffTableSortingSubject$ = new BehaviorSubject<TableDataModel.Sorting>({});
  private _scopeContextFilterSelectedDataSubject$ = new Subject<ScopeContextFilterModel.SelectedData>();
  private _staffSearchTermsSubject$ = new BehaviorSubject<StaffModel.StaffSearchTerms>({});

  protected viewModel$ = combineLatest([
    of({
      pageScope: PageScope.State,
      year: new Date().getFullYear(),
      schoolIds: [],
      districtIds: []
    }),
    this._staffSearchTermsSubject$,
    this._staffTablePaginationSubject$,
    this._staffTableSortingSubject$
  ]).pipe(
    map(([userProfile]) => ({
      userPageScope: userProfile.pageScope,
      userScopeId: 0,
      selectedPageScope: userProfile.pageScope,
      selectedYear: userProfile.year,
      selectedSchoolId: userProfile.schoolIds,
      selectedDistrictId: userProfile.districtIds,
      staffData: [],
      staffDataTotalRecords: 0,
      selectedScopeDescriptor: `${userProfile.year} - Statewide`
    }))
  );

  public constructor(
  ) {
  }

  protected onStaffTableSortClicked(sorting: TableDataModel.Sorting): void {
    this._staffTableSortingSubject$.next(sorting);
  }

  protected onStaffTablePageChanged(pagination: TableDataModel.Pagination): void {
    this._staffTablePaginationSubject$.next(pagination);
  }

  protected onContextFilterSearchClicked(selectedScopeContextFilterData: ScopeContextFilterModel.SelectedData): void {
    this._scopeContextFilterSelectedDataSubject$.next(selectedScopeContextFilterData);
  }

  protected onStaffFilterSearchClicked(staffSearchTerms: StaffModel.StaffSearchTerms): void {
    this._staffSearchTermsSubject$.next(staffSearchTerms);
  }

  protected onStaffFilterResetClicked(): void {
    this._staffSearchTermsSubject$.next({});
  }
}
