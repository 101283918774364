import { ExtendedFormlyFieldConfig } from 'app/models/extendedFormlyFieldConfig';

export const formlyFieldConfigs: Record<string, ExtendedFormlyFieldConfig> = {
  // --------------
  // Static Columns
  // --------------
  nameFirst: {
    key: 'nameFirst',
    type: 'input',
    props: {
      type: 'text'
    }
  },
  nameMiddle: {
    key: 'nameMiddle',
    type: 'input',
    props: {
      type: 'text'
    }
  },
  nameLast: {
    key: 'nameLast',
    type: 'input',
    props: {
      type: 'text'
    }
  },
  email: {
    key: 'email',
    type: 'input',
    props: {
      type: 'email'
    }
  },
  district: {
    key: 'district',
    type: 'input',
    props: {
      type: 'text'
    }
  },
  school: {
    key: 'school',
    type: 'input',
    props: {
      type: 'text'
    }
  },
  gender: {
    key: 'gender',
    type: 'select',
    props: {
      options: [
        { label: '' },
        { label: 'Male', value: 'M' },
        { label: 'Female', value: 'F' }
      ]
    }
  },
  teacherLicenseNumber: {
    key: 'teacherLicenseNumber',
    type: 'input',
    props: {
      type: 'text'
    }
  },
  licensureCheck: {
    key: 'licensureCheck',
    type: 'select',
    props: {
      options: [
        { label: '' },
        { label: 'N=New Teacher', value: 'N' },
        { label: 'R=ROTC', value: 'R' },
        { label: 'U=University Staff', value: 'U' },
        { label: 'O=Other', value: 'O' },
        { label: 'E=IEA Teacher', value: 'E' }
      ]
    }
  },

  // ----------------------------------
  // Dynamic Columns Filters - Staff Current Assignments
  // ----------------------------------
  'staffAssignment.assignmentCode': {
    key: 'staffAssignment.assignmentCode',
    type: 'input',
    props: {
      type: 'text'
    }
  },
  assignmentBeginDate: {
    key: 'assignmentBeginDate',
    type: 'datepicker'
  },
  assignmentEndDate: {
    key: 'assignmentEndDate',
    type: 'datepicker'
  },

  // ----------------------------------
  // Dynamic Columns Filters - Staff Class Assignments
  // ----------------------------------
  'staffAssignment.assignmentStart': {
    key: 'staffAssignment.assignmentStart',
    type: 'datepicker'
  },
  'staffAssignment.assignmentEnd': {
    key: 'staffAssignment.assignmentEnd',
    type: 'datepicker'
  },
  'staffAssignment.classStart': {
    key: 'staffAssignment.classStart',
    type: 'datepicker'
  },
  'staffAssignment.classEnd': {
    key: 'staffAssignment.classEnd',
    type: 'datepicker'
  },
  'staffAssignment.courseCode': {
    key: 'staffAssignment.courseCode',
    type: 'input',
    props: {
      type: 'text'
    }
  },
  'staffAssignment.localClassNumber': {
    key: 'staffAssignment.localClassNumber',
    type: 'input',
    props: {
      type: 'text'
    }
  },
  'staffAssignment.teacherOfRecord': {
    key: 'staffAssignment.teacherOfRecord',
    type: 'input',
    props: {
      type: 'text'
    }
  },
  'staffAssignment.periodDuration': {
    key: 'staffAssignment.periodDuration',
    type: 'input',
    props: {
      type: 'text'
    }
  },

  // ----------------------------------
  // Dynamic Columns Filters - Staff Licensure & Endorsement Details
  // ----------------------------------
  experienceInYears: {
    key: 'experienceInYears',
    type: 'input',
    props: {
      type: 'number'
    }
  },
  educationLevel: {
    key: 'educationLevel',
    type: 'input',
    props: {
      type: 'text'
    }
  },
  conferralDate: {
    key: 'conferralDate',
    type: 'datepicker'
  },
  addedToFile: {
    key: 'addedToFile',
    type: 'datepicker'
  },
  'additionalInfo.staffMemberLicensureDetails.dateIssued': {
    key: 'additionalInfo.staffMemberLicensureDetails.dateIssued',
    type: 'datepicker'
  },
  'additionalInfo.staffMemberLicensureDetails.type': {
    key: 'additionalInfo.staffMemberLicensureDetails.type',
    type: 'input',
    props: {
      type: 'text'
    }
  },
  'additionalInfo.staffMemberLicensureDetails.expirationDate': {
    key: 'additionalInfo.staffMemberLicensureDetails.expirationDate',
    type: 'datepicker'
  },
  'additionalInfo.staffMemberLicensureDetails.renewedDate': {
    key: 'additionalInfo.staffMemberLicensureDetails.renewedDate',
    type: 'datepicker'
  },
  'additionalInfo.staffMemberLicensureDetails.numberOfRenewals': {
    key: 'additionalInfo.staffMemberLicensureDetails.numberOfRenewals',
    type: 'input',
    props: {
      type: 'text'
    }
  }
};