
<ng-container *ngIf="viewData$ | async as viewData"><div class="container breadcrumb">
    <div class="container">
        <app-breadcrumb/>
        <h1>Error Log</h1>
            <app-district-select 
                *ngIf="viewData.pageScope === PageScope.State && viewData.districts"
                [districts]="viewData.districts" 
                (searchClick)="onSearchClicked($event)" />

            <h2 *ngIf="viewData.pageScope === PageScope.District && viewData.districts">
                {{displayDistricts(viewData.districts)}}
            </h2>

            <h2 *ngIf="viewData.pageScope === PageScope.School && viewData.schools">
                {{displaySchools(viewData.schools)}}
            </h2>
        </div>
    </div>

    <app-error-category-tabs
        [pageScope]="viewData.pageScope"
        [scopeDetail]="userProfile?.scopeDetails!"
        (errorCategoryTabSelected)="onErrorCategoryTabSelected($event)"/>
        
    <router-outlet/>

</ng-container>
