<ng-container *ngIf="viewModel$ | async as viewModel">
    <mat-form-field appearance="outline" subscriptSizing="dynamic" style="width:120px;">
        <mat-label>Select year</mat-label>
        <mat-select [value]="viewModel.selectedYear" (selectionChange)="onYearChange($event.value)">
            @for (year of viewModel.years;track year) {
                <mat-option [value]="+year">{{year}}</mat-option>
            }
        </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" subscriptSizing="dynamic" style="flex-grow:1">
        <mat-label>Select district</mat-label>
        <mat-select [(value)]="viewModel.selectedDistrictIds" (selectionChange)="onDistrictIdChange($event)" multiple>
            @for (district of viewModel.districts;track district) {
                <mat-option [value]="district.districtId">{{district.name}}</mat-option>
            }
        </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" subscriptSizing="dynamic" style="flex-grow:1">
        <mat-label>Select school</mat-label>
        <mat-select [(value)]="viewModel.selectedSchoolIds" (selectionChange)="onSchoolChange($event)" [disabled]="!!!viewModel.selectedDistrictIds" multiple>
            @for(school of viewModel.schools;track school) {
                <mat-option [value]="school.schoolId">{{school.name}}</mat-option>
            }
        </mat-select>
    </mat-form-field>
    <div class="buttons">
        <button tdoe-button [disabled]="!viewModel.isValid" (click)="onSearchClick(viewModel)">Apply</button>
    </div>
</ng-container>