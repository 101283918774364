@if (viewModel$ | async; as viewModel) {
    <mat-table [dataSource]="viewModel.dataSource" matSort>

        <ng-container matColumnDef="grade">
            <mat-header-cell *matHeaderCellDef> Grade </mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.grade}}</mat-cell>
        </ng-container>
    
        <ng-container matColumnDef="year">
            <mat-header-cell *matHeaderCellDef> Year </mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.year}}</mat-cell>
        </ng-container>
    
        <ng-container matColumnDef="district">
            <mat-header-cell *matHeaderCellDef> District </mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.district}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="school">
            <mat-header-cell *matHeaderCellDef> School </mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.school}}</mat-cell>
        </ng-container>
            
        <ng-container matColumnDef="enrollment">
            <mat-header-cell *matHeaderCellDef> Enrollment </mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.enrollment | date}}</mat-cell>
        </ng-container>
            
        <ng-container matColumnDef="withdrawal">
            <mat-header-cell *matHeaderCellDef> Withdrawal </mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.withdrawal | date}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="tos">
            <mat-header-cell *matHeaderCellDef> TOS </mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.tos}}</mat-cell>
        </ng-container>
        
        <ng-container matColumnDef="primaryDistrict">
            <mat-header-cell *matHeaderCellDef> Primary district </mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.primaryDistrict}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="primarySchool">
            <mat-header-cell *matHeaderCellDef> Primary school </mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.primarySchool}}</mat-cell>
        </ng-container>
            
        <mat-header-row *matHeaderRowDef="viewModel.displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: viewModel.displayedColumns"></mat-row>
    
    </mat-table>
}