@if(!isLoading){
    <div class="container page-title">
        <div class="container">
            <h1>Student list</h1>
        </div>
    </div>
    <div class="container scope-context">
        <div class="container">
            <app-scope-context-filter 
                [userPageScope]="userProfile()!.pageScope"
                [selectedYear]="searchTerms().year" 
                [selectedSchoolIds]="searchTerms().schoolIds" 
                [selectedDistrictIds]="searchTerms().districtIds" 
                (searchClick)="onContextFilterSearchClicked($event)" />
        </div>
    </div>

    <div class="container student-filter">
        <div class="container">
            <app-student-filter
                [studentSearchTerms]="searchTerms()"
                (searchClicked)="onStudentFilterSearchClicked($event)"
                (resetClicked)="onStudentFilterResetClicked()"/>
        </div>
    </div>

    <div class="container table">
        <div class="container">
            <app-student-table
                [students]="studentsPagedResponse()?.data ?? undefined"
                [totalRecords]="studentsPagedResponse()?.totalRecords ?? 0"
                [(searchTerms)]="searchTerms"
                (pageChanged)="onStudentTablePageChanged($event)"
                (sortClicked)="onStudentTableSortClicked($event)" />
        </div>
    </div>
}
