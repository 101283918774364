@if (viewModel$ | async; as viewModel) {
    <div class="header">
        @if (filterChips && filterChips.length > 0) {
            <tdoe-chips [chips]="filterChips" (onChipRemove)="filterChipRemoved($event)"/>
        } @else {
            <h3></h3>
        }
        <div class="controls">
            <div class="page-size">
                <span>Results Per Page</span>
                <select name="pageSize" [(ngModel)]="paginator.pageSize" (change)="onPageSizeChange($event)"> 
                    <option *ngFor="let pageSize of paginator.pageSizeOptions" [value]="pageSize">{{pageSize}}</option>
                </select>
            </div>
            <div class="buttons">
                <app-additional-info (fieldSelected)="onAdditionalInfoFieldSelected($event)"/>
                @if (!showFilters) {
                    <button tdoe-button (click)="showFilters = !showFilters">Simple filters</button>
                } @else {
                    <button tdoe-button type="secondary" (click)="showFilters = !showFilters">Advanced filters</button>
                }
                <button tdoe-button [matMenuTriggerFor]="menu">Export</button>
                <mat-menu #menu="matMenu" xPosition="before">
                    <button mat-menu-item>CSV</button>
                    <button mat-menu-item>PDF</button>
                    <button mat-menu-item>Excel</button>
                </mat-menu>
            </div>
        </div>
    </div>
    <div class="table-title">
        <span>Student Information</span>
    </div>
    <mat-table [class.blocked]="showFilters" matSort (matSortChange)="onSortChanged($event)" [dataSource]="viewModel.dataSource">
        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                @if (!showFilters) {
                    <svg-icon *ngIf="!filterModel.name" (click)="onFilterClick($event, contextFilters['name'])" class="head-inline-icon" src="/assets/images/filter-solid.svg" [svgStyle]="{ 'width.px': 16, 'fill': '#EDECE9' }"></svg-icon>
                    <svg-icon *ngIf="filterModel.name" (click)="onFilterClick($event, contextFilters['name'])" class="head-inline-icon" src="/assets/images/filter-circle-xmark-solid.svg" [svgStyle]="{ 'width.px': 16, 'fill': '#0061F6' }"></svg-icon>
                }
                <div>Last, First Middle</div>
                @if (showFilters) {
                    <form class="filter-form" [formGroup]="formly" (click)="$event.stopImmediatePropagation()">
                        <formly-form [form]="formly" [fields]="getInlineFilter('name')" [model]="filterModel"></formly-form>
                    </form>
                }
            </mat-header-cell>
            <mat-cell *matCellDef="let student"> {{student.name}} </mat-cell>
        </ng-container>
    
        <ng-container matColumnDef="ssid">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                @if (!showFilters) {
                    <svg-icon *ngIf="!filterModel.ssid" (click)="onFilterClick($event, contextFilters['ssid'])" class="head-inline-icon" src="/assets/images/filter-solid.svg" [svgStyle]="{ 'width.px': 16, 'fill': '#EDECE9' }"></svg-icon>
                    <svg-icon *ngIf="filterModel.ssid" (click)="onFilterClick($event, contextFilters['ssid'])" class="head-inline-icon" src="/assets/images/filter-circle-xmark-solid.svg" [svgStyle]="{ 'width.px': 16, 'fill': '#0061F6' }"></svg-icon>
                }
                <div>SSID</div>
                @if (showFilters) {
                    <form class="filter-form" [formGroup]="formly" (click)="$event.stopImmediatePropagation()">
                        <formly-form [form]="formly" [fields]="getInlineFilter('ssid')" [model]="filterModel"></formly-form>
                    </form>
                }
            </mat-header-cell>
            <mat-cell *matCellDef="let student" > {{student.ssid | ssidFormat}} </mat-cell>
        </ng-container>
    
        <ng-container matColumnDef="dateOfBirth">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                @if (!showFilters) {
                    <svg-icon *ngIf="!filterModel.dateOfBirth" (click)="onFilterClick($event, contextFilters['dateOfBirth'])" class="head-inline-icon" src="/assets/images/filter-solid.svg" [svgStyle]="{ 'width.px': 16, 'fill': '#EDECE9' }"></svg-icon>
                    <svg-icon *ngIf="filterModel.dateOfBirth" (click)="onFilterClick($event, contextFilters['dateOfBirth'])" class="head-inline-icon" src="/assets/images/filter-circle-xmark-solid.svg" [svgStyle]="{ 'width.px': 16, 'fill': '#0061F6' }"></svg-icon>
                }
                <div>Birth date</div>
                @if (showFilters) {
                    <form class="filter-form" [formGroup]="formly" (click)="$event.stopImmediatePropagation()">
                        <formly-form [form]="formly" [fields]="getInlineFilter('dateOfBirth')" [model]="filterModel"></formly-form>
                    </form>
                }
            </mat-header-cell>
            <mat-cell *matCellDef="let student"> {{student.dateOfBirth}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="grade">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                @if (!showFilters) {
                    <svg-icon *ngIf="!filterModel.grade" (click)="onFilterClick($event, contextFilters['grade'])" class="head-inline-icon" src="/assets/images/filter-solid.svg" [svgStyle]="{ 'width.px': 16, 'fill': '#EDECE9' }"></svg-icon>
                    <svg-icon *ngIf="filterModel.grade" (click)="onFilterClick($event, contextFilters['grade'])" class="head-inline-icon" src="/assets/images/filter-circle-xmark-solid.svg" [svgStyle]="{ 'width.px': 16, 'fill': '#0061F6' }"></svg-icon>
                }
                <div>Grade</div>
                @if (showFilters) {
                    <form class="filter-form" [formGroup]="formly" (click)="$event.stopImmediatePropagation()">
                        <formly-form [form]="formly" [fields]="getInlineFilter('grade')" [model]="filterModel"></formly-form>
                    </form>
                }
            </mat-header-cell>
            <mat-cell *matCellDef="let student"> {{student.grade}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="enrollmentStartDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                @if (!showFilters) {
                    <svg-icon *ngIf="!filterModel.enrollmentStartDate" (click)="onFilterClick($event, contextFilters['enrollmentStartDate'])" class="head-inline-icon" src="/assets/images/filter-solid.svg" [svgStyle]="{ 'width.px': 16, 'fill': '#EDECE9' }"></svg-icon>
                    <svg-icon *ngIf="filterModel.enrollmentStartDate" (click)="onFilterClick($event, contextFilters['enrollmentStartDate'])" class="head-inline-icon" src="/assets/images/filter-circle-xmark-solid.svg" [svgStyle]="{ 'width.px': 16, 'fill': '#0061F6' }"></svg-icon>
                }
                <div>Enrollment Start Date</div>
                @if (showFilters) {
                    <form class="filter-form" [formGroup]="formly" (click)="$event.stopImmediatePropagation()">
                        <formly-form [form]="formly" [fields]="getInlineFilter('enrollmentStartDate')" [model]="filterModel"></formly-form>
                    </form>
                }
            </mat-header-cell>
            <mat-cell *matCellDef="let student"> {{student.enrollmentStartDate | date}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="enrollmentEndDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                @if (!showFilters) {
                    <svg-icon *ngIf="!filterModel.enrollmentEndDate" (click)="onFilterClick($event, contextFilters['enrollmentEndDate'])" class="head-inline-icon" src="/assets/images/filter-solid.svg" [svgStyle]="{ 'width.px': 16, 'fill': '#EDECE9' }"></svg-icon>
                    <svg-icon *ngIf="filterModel.enrollmentEndDate" (click)="onFilterClick($event, contextFilters['enrollmentEndDate'])" class="head-inline-icon" src="/assets/images/filter-circle-xmark-solid.svg" [svgStyle]="{ 'width.px': 16, 'fill': '#0061F6' }"></svg-icon>
                }
                <div>Enrollment End Date</div>
                @if (showFilters) {
                    <form class="filter-form" [formGroup]="formly" (click)="$event.stopImmediatePropagation()">
                        <formly-form [form]="formly" [fields]="getInlineFilter('enrollmentEndDate')" [model]="filterModel"></formly-form>
                    </form>
                }
            </mat-header-cell>
            <mat-cell *matCellDef="let student"> {{student.enrollmentEndDate | date}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="code">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                @if (!showFilters) {
                    <svg-icon *ngIf="!filterModel.code" (click)="onFilterClick($event, contextFilters['code'])" class="head-inline-icon" src="/assets/images/filter-solid.svg" [svgStyle]="{ 'width.px': 16, 'fill': '#EDECE9' }"></svg-icon>
                    <svg-icon *ngIf="filterModel.code" (click)="onFilterClick($event, contextFilters['code'])" class="head-inline-icon" src="/assets/images/filter-circle-xmark-solid.svg" [svgStyle]="{ 'width.px': 16, 'fill': '#0061F6' }"></svg-icon>
                }
                <div>Code</div>
                @if (showFilters) {
                    <form class="filter-form" [formGroup]="formly" (click)="$event.stopImmediatePropagation()">
                        <formly-form [form]="formly" [fields]="getInlineFilter('code')" [model]="filterModel"></formly-form>
                    </form>
                }
            </mat-header-cell>
            <mat-cell *matCellDef="let student"> {{student.code}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="district" *ngIf="viewModel.displayedColumns.includes('district')">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                @if (!showFilters) {
                    <svg-icon *ngIf="!filterModel.district" (click)="onFilterClick($event, contextFilters['district'])" class="head-inline-icon" src="/assets/images/filter-solid.svg" [svgStyle]="{ 'width.px': 16, 'fill': '#EDECE9' }"></svg-icon>
                    <svg-icon *ngIf="filterModel.district" (click)="onFilterClick($event, contextFilters['district'])" class="head-inline-icon" src="/assets/images/filter-circle-xmark-solid.svg" [svgStyle]="{ 'width.px': 16, 'fill': '#0061F6' }"></svg-icon>
                }
                <div>District</div>
                @if (showFilters) {
                    <form class="filter-form" [formGroup]="formly" (click)="$event.stopImmediatePropagation()">
                        <formly-form [form]="formly" [fields]="getInlineFilter('district')" [model]="filterModel"></formly-form>
                    </form>
                }
            </mat-header-cell>
            <mat-cell *matCellDef="let student"> {{student.district}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="school" *ngIf="viewModel.displayedColumns.includes('school')">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                @if (!showFilters) {
                    <svg-icon *ngIf="!filterModel.school" (click)="onFilterClick($event, contextFilters['school'])" class="head-inline-icon" src="/assets/images/filter-solid.svg" [svgStyle]="{ 'width.px': 16, 'fill': '#EDECE9' }"></svg-icon>
                    <svg-icon *ngIf="filterModel.school" (click)="onFilterClick($event, contextFilters['school'])" class="head-inline-icon" src="/assets/images/filter-circle-xmark-solid.svg" [svgStyle]="{ 'width.px': 16, 'fill': '#0061F6' }"></svg-icon>
                }
                <div>School</div>
                @if (showFilters) {
                    <form class="filter-form" [formGroup]="formly" (click)="$event.stopImmediatePropagation()">
                        <formly-form [form]="formly" [fields]="getInlineFilter('school')" [model]="filterModel"></formly-form>
                    </form>
                }
            </mat-header-cell>
            <mat-cell *matCellDef="let student"> {{student.school}} </mat-cell>
        </ng-container>

        @if (viewModel.dynamicColumns.length > 0) {
            @for (col of viewModel.dynamicColumns; track col)
            {
                <ng-container [matColumnDef]="col.key">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        @if (!showFilters) {
                            <svg-icon *ngIf="!filterModel[col.key]" (click)="onFilterClick($event, col.filter)" class="head-inline-icon" src="/assets/images/filter-solid.svg" [svgStyle]="{ 'width.px': 16, 'fill': '#EDECE9' }"></svg-icon>
                            <svg-icon *ngIf="filterModel[col.key]" (click)="onFilterClick($event, col.filter)" class="head-inline-icon" src="/assets/images/filter-circle-xmark-solid.svg" [svgStyle]="{ 'width.px': 16, 'fill': '#0061F6' }"></svg-icon>        
                        }
                        <div>{{col.name}}</div>
                        @if (showFilters && col.filter) {
                            <form class="filter-form" [formGroup]="formly" (click)="$event.stopImmediatePropagation()">
                                <formly-form [form]="formly" [fields]="inlineFilter(col.filter)" [model]="filterModel"></formly-form>
                            </form>
                        }
                    </mat-header-cell>
                    <mat-cell *matCellDef="let student"> {{student[col.key]}} </mat-cell>
                </ng-container>
            }
        }

        <mat-header-row *matHeaderRowDef="viewModel.displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let student; columns: viewModel.displayedColumns" [attr.student-id]="student.id" (click)="onRowClicked(student)"></mat-row>
                
        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No data matching applied filters</td>
        </tr>
    </mat-table>

    <mat-paginator
        #paginator
        [length]="viewModel.totalRecords"
        pageSize="50"
        [pageSizeOptions]="[25, 50, 100, 500, 1000]"
        showFirstLastButtons
        (page)="onPageChanged($event)">
    </mat-paginator>
}
@else {
    <h1>Skeleton</h1>
}
