import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { MembershipModel } from './membership.model';
import { HttpParamsUtilities } from 'app/utilities/http-params-utilities/http-params-utilities';

@Injectable({
  providedIn: 'root'
})
export class MembershipService {
  public static StateMembershipSummaryUrl = environment.apiBaseUrl + 'Membership/StateMembershipSummary';
  public static DistrictMembershipSummaryUrl = environment.apiBaseUrl + 'Membership/DistrictMembershipSummary';
  public static SchoolMembershipSummaryUrl = environment.apiBaseUrl + 'Membership/SchoolMembershipSummary';
  public static MembershipByGradeSummaryUrl = environment.apiBaseUrl + 'Membership/MembershipByGradeSummary';

  public constructor(private http: HttpClient) { }

  public getStateMembershipSummary(year: number): Observable<MembershipModel.MembershipSummary[]> {
    return this.http.get<MembershipModel.MembershipSummary[]>(MembershipService.StateMembershipSummaryUrl, { params: { year } });
  }

  public getDistrictMembershipSummary(year: number, districtIds: string[]): Observable<MembershipModel.MembershipSummary[]> {
    const httpParams = HttpParamsUtilities.toHttpParams({year, districtId: districtIds});
    return this.http.get<MembershipModel.MembershipSummary[]>(MembershipService.DistrictMembershipSummaryUrl, {params: httpParams});
  }

  public getSchoolMembershipSummary(year: number, schoolIds: string[]): Observable<MembershipModel.MembershipSummary[]> {
    const httpParams = HttpParamsUtilities.toHttpParams({year, schoolId: schoolIds});
    return this.http.get<MembershipModel.MembershipSummary[]>(MembershipService.SchoolMembershipSummaryUrl, {params: httpParams});
  }

  public getSchoolMembershipByGradeSummary(year: number, schoolIds: string[]): Observable<MembershipModel.MembershipByGradeSummary[]> {
    const httpParams = HttpParamsUtilities.toHttpParams({year, schoolId: schoolIds});
    return this.http.get<MembershipModel.MembershipByGradeSummary[]>(MembershipService.MembershipByGradeSummaryUrl, {params: httpParams});
  }

  public getDistrictMembershipByGradeSummary(year: number, districtIds: string[]): Observable<MembershipModel.MembershipByGradeSummary[]> {
    const httpParams = HttpParamsUtilities.toHttpParams({year, districtId: districtIds});
    return this.http.get<MembershipModel.MembershipByGradeSummary[]>(MembershipService.MembershipByGradeSummaryUrl, {params: httpParams});
  }

  public getStateMembershipByGradeSummary(year: number): Observable<MembershipModel.MembershipByGradeSummary[]> {
    return this.http.get<MembershipModel.MembershipByGradeSummary[]>(MembershipService.MembershipByGradeSummaryUrl, {params: {year}});
  }
}
