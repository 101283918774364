<ng-container *ngIf="viewModel$ | async as viewModel">
    <div class="container">
        <div class="container">
            <app-scope-context-filter [userPageScope]="viewModel.userPageScope" [selectedYear]="viewModel.selectedYear"
                [(selectedSchoolIds)]="viewModel.selectedSchoolId"
                [(selectedDistrictIds)]="viewModel.selectedDistrictId"
                (searchClick)="onContextFilterSearchClicked($event)">
            </app-scope-context-filter>
        </div>
    </div>

    <div class="container school-filter">
        <div class="container">
            <app-school-filter (searchClicked)="onSchoolFilterSearchClicked($event)"
                (resetClicked)="onSchoolFilterResetClicked()">
            </app-school-filter>
        </div>
    </div>

    <div class="container table">
        <div class="container">
            <app-school-table [schools]="viewModel.schoolData" [totalRecords]="viewModel.schoolDataTotalRecords"
                [pageScope]="viewModel.selectedPageScope" [selectedDistrictIds]="viewModel.selectedDistrictId"
                [selectedSchoolIds]="viewModel.selectedSchoolId" (pageChanged)="onSchoolTablePageChanged($event)"
                (sortClicked)="onSchoolTableSortClicked($event)">
            </app-school-table>
        </div>
    </div>
</ng-container>