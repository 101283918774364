/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { LoggingService } from '@tdoe/design-system';
import { ClassDetail } from 'app/dto';
import { ClassService } from 'app/services/class/class.service';
import { firstValueFrom, of } from 'rxjs';
import { AdditionalInfoComponent } from '../shared/additional-info/additional-info.component';
import { Category } from 'app/services/additional-info/additional-info.model';
import { MatExpansionModule } from '@angular/material/expansion';
import { AsyncPipe, CommonModule, DatePipe } from '@angular/common';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { DayOfWeek } from 'app/models/dayOfWeek';
import { AdditionalInfoService } from 'app/services/additional-info/additional-info.service';
import { StaffTableComponent } from '../shared/staff-table/staff-table.component';
import { StudentTableComponent } from '../shared/student-table/student-table.component';

@Component({
  selector: 'app-class-view',
  standalone: true,
  imports: [
    MatIconModule,
    AdditionalInfoComponent,
    MatExpansionModule,
    DatePipe,
    MatButtonToggleModule,
    AsyncPipe,
    StaffTableComponent,
    CommonModule,
    StudentTableComponent,
    RouterModule
],
  templateUrl: './class-view.component.html',
  styleUrl: './class-view.component.scss'
})
export class ClassViewComponent implements OnChanges, OnInit {

  // #region Inputs
  @Input() public classDetail!: ClassDetail;
  @Input() public classId!: string;
  // #endregion

  // #region D.I.
  private readonly additionalInfoService = inject(AdditionalInfoService);
  private readonly classService = inject(ClassService);
  private readonly logger = inject(LoggingService);
  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);
  // #endregion

  public daysOfWeek = DayOfWeek;
  public readonly categories = of([]); //firstValueFrom(this.additionalInfoService.getFieldState());
  public readonly breadcrumbData: any;

  public constructor() {
    const routeData = this.router.getCurrentNavigation()?.extras.state;
    if (routeData) {

      // Extract class detail from route data, if exists
      const routeClass = routeData['class-detail'];
      if (routeClass) {
        this.classDetail = routeClass;
      }

      // Extract breadcrumb data from route data, if exists
      const breadcrumbData = routeData['breadcrumb-data'];
      if (breadcrumbData) {
        this.breadcrumbData = breadcrumbData;
      }
    }
  }

  private async loadClassDetail(classId: string): Promise<void> {
    this.classDetail = await firstValueFrom(this.classService.getClass(classId));
  }

  public async ngOnChanges(changes: SimpleChanges): Promise<void> {

    if (changes['classId']?.currentValue) {
      this.loadClassDetail(this.classId);
    }

  }

  public async ngOnInit(): Promise<void> {
    // Do not continue initializing if input parameter is provided by a parent control, route data or injection
    if (this.classDetail) return;

    // Check route params for class ID
    const params = await firstValueFrom(this.route.params);
    if (params) {
      const classId: string = params['id'];
      if (classId) {
        // Request class details from service API
        this.loadClassDetail(classId);
      }
    }
  }

  public onFieldSelected(e: Category[]): void {
    this.logger.debug('ClassViewComponent -> onFieldSelected', { data: { e } });
    // TODO: As part of SWORD-235
  }

}
