import { Component, EventEmitter, Output } from '@angular/core';
import { SchoolSearchTerms } from './school-filter.model';
import { TdoeDsModule } from '@tdoe/design-system';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ObjectUtilities } from 'app/utilities/object-utilities/object-utilities';
import { MatNativeDateModule } from '@angular/material/core';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { FormlyMatSelectModule } from '@ngx-formly/material/select';

@Component({
  selector: 'app-school-filter',
  templateUrl: './school-filter.component.html',
  styleUrls: ['./school-filter.component.scss'],
  standalone: true,
  imports: [
    TdoeDsModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyModule,
    FormlyMaterialModule,
    FormlyMatSelectModule,
    MatNativeDateModule,
    FormlyMatDatepickerModule
  ]
})
export class SchoolFilterComponent {

  @Output() public searchClicked = new EventEmitter<SchoolSearchTerms>();

  protected schoolSearchTerms: SchoolSearchTerms = {};

  protected formGroup = new FormGroup({});
  protected formlyFields: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'display-flex',
      fieldGroup: [
        {
          key: 'name',
          type: 'input',
          props: {
            label: 'School Name',
            type: 'text',
            appearance: 'outline'
          }
        },
        {
          key: 'schoolId',
          type: 'input',
          props: {
            label: 'School ID',
            type: 'text',
            appearance: 'outline'
          },
          className: 'filter-2'
        },
        {
          key: 'schoolNumber',
          type: 'input',
          props: {
            label: 'School Number',
            type: 'number',
            appearance: 'outline'
          },
          className: 'filter-2'
        },
        {
          key: 'districtId',
          type: 'input',
          props: {
            label: 'District ID',
            type: 'text',
            appearance: 'outline'
          },
          className: 'filter-2'
        },
        {
          key: 'districtNumber',
          type: 'input',
          props: {
            label: 'District Number',
            type: 'number',
            appearance: 'outline'
          },
          className: 'filter-2'
        },
        {
          key: 'districtName',
          type: 'input',
          props: {
            label: 'District Name',
            type: 'text',
            appearance: 'outline'
          },
          className: 'filter-2'
        }
      ]
    }
  ];

  constructor() { }

  protected onResetClick(): void {
    this.schoolSearchTerms = {};
    this.formGroup.reset();
  }

  protected onSearchClick(): void {
    this.searchClicked.emit(
      ObjectUtilities.setEmptyStringsToUndefined(
        ObjectUtilities.trimStrings(this.schoolSearchTerms)
      )
    );
  }
}
