
export type DayOfWeek = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export const DayOfWeek = {
    Sunday: 0 as DayOfWeek,
    Monday: 1 as DayOfWeek,
    Tuesday: 2 as DayOfWeek,
    Wednesday: 3 as DayOfWeek,
    Thursday: 4 as DayOfWeek,
    Friday: 5 as DayOfWeek,
    Saturday: 6 as DayOfWeek
};