import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { StudentEnrollmentClassification } from 'app/dto';
import { BehaviorSubject, map } from 'rxjs';

@Component({
  selector: 'app-enrollment-classification',
  standalone: true,
  imports: [
    MatTableModule,
    MatSortModule,
    CommonModule
  ],
  templateUrl: './enrollment-classification.component.html',
  styleUrl: './enrollment-classification.component.scss'
})
export class EnrollmentClassificationComponent {

  @Input({ required: true })
  public set classifications(classifications: StudentEnrollmentClassification[]){
    this._classificationsSubject$.next(classifications);
  }

  private _classificationsSubject$ = new BehaviorSubject<StudentEnrollmentClassification[]>([]);

  private _dataSource = new MatTableDataSource<StudentEnrollmentClassification>();

  private _displayedCols = ['classificationCode', 'classificationDateStart', 'classificationDateEnd', 'homelessResidence', 'homelessMckinnyVento', 'homelessUnaccompaniedYouth'];

  public viewModel$ = this._classificationsSubject$.pipe(
    map(classifications => {
      this._dataSource.data = classifications;
      return {
        dataSource: this._dataSource,
        displayedColumns: this._displayedCols
      };
    })
  );
}
