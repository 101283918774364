import { Component } from '@angular/core';
import { BreadcrumbComponent } from '../shared/breadcrumb/breadcrumb.component';
import { Router, RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ErrorCategoryTabsComponent } from './shared/error-category-tabs/error-category-tabs.component';
import { Observable, filter, map, mergeMap } from 'rxjs';
import { SchoolService } from 'app/services/school/school.service';
import { DistrictSelectComponent } from './shared/district-select/district-select.component';
import { PageScope } from 'app/enums/page-scope';
import { ErrorLogModel } from './error-log.model';
import { UserService } from 'app/services/user/user.service';
import { District, School } from 'app/dto';
import { ConcatStringPipe } from 'app/pipes/string-concat/concat-string.pipe';
import { UserProfile } from 'app/services/user/user.model';

@Component({
  selector: 'app-error-log',
  standalone: true,
  imports: [
    BreadcrumbComponent, 
    ErrorCategoryTabsComponent,
    RouterOutlet, 
    CommonModule, 
    DistrictSelectComponent,
    ConcatStringPipe
  ],
  templateUrl: './error-log.component.html',
  styleUrl: './error-log.component.scss'
})
export class ErrorLogComponent {

  public viewData$: Observable<ErrorLogModel.ViewModel> = this._userService.userProfile$.pipe(
    filter(userProfile => !!userProfile),
    mergeMap(userProfile => {
      this.userProfile = userProfile;
      switch (userProfile!.pageScope) {
        case PageScope.State:
          return this.handleStateScope();
        case PageScope.District:
          return this.handleDistrictScope(userProfile!.scopeDetails.districtIds);
        case PageScope.School:
          return this.handleSchoolScope(userProfile!.scopeDetails.schoolIds);
        default:
          throw new Error('User has no page scope.');
      }
    })
  );

  protected PageScope = PageScope;
  protected userProfile?: UserProfile;

  public constructor(
    private _userService: UserService,
    private _router: Router,
    private _schoolService: SchoolService) {
    //
  }

  protected displayDistricts(districts: District[]): string {
    return districts.map(_ => _.name).join(', ');
  }

  protected displaySchools(schools: School[]): string {
    return schools.map(_ => _.name).join(', ');
  }

  protected onErrorCategoryTabSelected(tabName: string): void { 
    this._router.navigate([`/error-log/${tabName}`]);
  }

  protected onSearchClicked(districts: District[]): void {
    // session state service??
    console.log(districts);
  }

  private handleStateScope(): Observable<ErrorLogModel.ViewModel> {
    return this._schoolService.getDistricts().pipe(
        map(districts => ({
          pageScope: PageScope.State,
          districts: districts
        }))
      );
  }
  
  private handleDistrictScope(districtIds: string[]): Observable<ErrorLogModel.ViewModel> {
    return this._schoolService.getDistricts(districtIds).pipe(
      map(districts => ({
        pageScope: PageScope.District,
        districts: districts,
        entityIds: districtIds
      }))
    );
  }
  
  private handleSchoolScope(schoolIds: string[]): Observable<ErrorLogModel.ViewModel> {
    return this._schoolService.getSchools(schoolIds).pipe(
      map(schools => ({
        pageScope: PageScope.School,
        schools: schools,
        entityIds: schoolIds
      }))
    );
  }
}
