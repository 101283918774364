// school.model.ts
export * as SchoolModel from './school.model';

// school.model.ts

export interface School {
    schoolId: string;
    name: string;
    schoolNumber: number;
    districtId: string;
    districtNumber: number;
    districtName: string;
    website?: string;
    addressType?: string;
    address?: string;
    city?: string;
    propertyName?: string;
    zip?: string;

    // Columns to be added to API
    calendarNumber?: string | null;
    instructionalCalendar?: string | null;
    scheduledDays?: number | null;
    stockpileInclementWeatherDays?: number | null;
    stockpileProfessionalDevelopmentDays?: number | null;
    inServiceDays?: number | null;
    studentDay?: string | null;
    teacherDay?: string | null;
    blockSchedule?: string | null;
    springBlockBeginDate?: string | null;
}


export interface District {
    districtId: string;
    districtName: string;
    districtNumber: number;
}
