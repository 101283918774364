/**
 * SWORD API
 * SWORD service API to be consumed by SWORD Angular SPA. Interfaces with Entra ID and DataBricks.
 *
 * OpenAPI spec version: v1
 * Contact: donnie.hyatt@tnedu.gov
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface StudentDemographics { 
    calculatedElServiceYears?: string;
    dateFirstEnrolledInUsSchool?: string;
    demographic?: string;
    elServiceYears?: Array<number>;
    englishLanguageBackground?: boolean;
    immigration?: string;
    nativeLanguage?: string;
    race?: string;
    yearEnteredGrade9?: number;
}
