/**
 * SWORD API
 * SWORD service API to be consumed by SWORD Angular SPA. Interfaces with Entra ID and DataBricks.
 *
 * OpenAPI spec version: v1
 * Contact: donnie.hyatt@tnedu.gov
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Student attendance
 */
export interface StudentAttendance { 
    /**
     * Attendance date
     */
    attendanceDate?: string;
    /**
     * Attendance type
     */
    attendanceType?: string;
    /**
     * Reporting begin date
     */
    reportingBeginDate?: string;
    /**
     * Reporting end date
     */
    reportingEndDate?: string;
}
