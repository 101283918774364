/**
 * SWORD API
 * SWORD service API to be consumed by SWORD Angular SPA. Interfaces with Entra ID and DataBricks.
 *
 * OpenAPI spec version: v1
 * Contact: donnie.hyatt@tnedu.gov
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { StaffMemberStaffAssignment } from './staffMemberStaffAssignment';
import { StaffMemberLicensureDetails } from './staffMemberLicensureDetails';
import { StaffMemberLocalCourseNumber } from './staffMemberLocalCourseNumber';

export interface StaffMemberAdditionalInfo { 
    staffMemberLicensureDetails?: StaffMemberLicensureDetails;
    localCourseNumber?: StaffMemberLocalCourseNumber;
    staffAssignments?: Array<StaffMemberStaffAssignment>;
}
