import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { HttpParamsUtilities } from '../../utilities/http-params-utilities/http-params-utilities';
import { TableDataModel } from 'app/models/table-data-model';
import { EthnicitySummary, GenderSummary, Student, StudentSearchTerms } from 'app/dto';


@Injectable({
  providedIn: 'root'
})
export class StudentService {

  public constructor(private http: HttpClient) { }

  public static AvailableYearsUrl = environment.apiBaseUrl + 'Student/AvailableYears';
  public static StudentsUrl = environment.apiBaseUrl + 'Student/Students';
  public static StudentUrl = environment.apiBaseUrl + 'Student/Student';
  public static GenderSummaryUrl = environment.apiBaseUrl + 'Student/GenderSummary';
  public static EthnicitySummaryUrl = environment.apiBaseUrl + 'Student/EthnicitySummary';

  public getStudentsBySearchTerms(studentSearchTerms: StudentSearchTerms, pagination: TableDataModel.Pagination, sorting: TableDataModel.Sorting): Observable<TableDataModel.PagedResponse<Student>> {
    const httpParams = HttpParamsUtilities.toHttpParams(studentSearchTerms, pagination, sorting);
    return this.http.get<TableDataModel.PagedResponse<Student>>(StudentService.StudentsUrl, { params: httpParams });
  }

  public getStudent(studentId: string): Observable<Student>{
    let httpParams = new HttpParams();
    httpParams = httpParams.set('id', studentId);
    return this.http.get<Student>(StudentService.StudentUrl, { params: httpParams});
  }

  public getSchoolGenderSummary(year: number, schoolIds: string[]): Observable<GenderSummary[]> {
    const httpParams = HttpParamsUtilities.toHttpParams({year, schoolId: schoolIds});
    return this.http.get<GenderSummary[]>(StudentService.GenderSummaryUrl, { params: httpParams});
  }

  public getDistrictGenderSummary(year: number, districtIds: string[]): Observable<GenderSummary[]> {
    const httpParams = HttpParamsUtilities.toHttpParams({year, districtId: districtIds});
    return this.http.get<GenderSummary[]>(StudentService.GenderSummaryUrl, {params: httpParams});
  }

  public getStateGenderSummary(year: number): Observable<GenderSummary[]> {
    const httpParams = new HttpParams().append('year', year);
    return this.http.get<GenderSummary[]>(StudentService.GenderSummaryUrl, { params: httpParams});
  }

  public getSchoolEthnicitySummary(year: number, schoolIds: string[]): Observable<EthnicitySummary[]> {
    const httpParams = HttpParamsUtilities.toHttpParams({year, schoolId: schoolIds});
    return this.http.get<EthnicitySummary[]>(StudentService.EthnicitySummaryUrl, {params: httpParams});
  }

  public getDistrictEthnicitySummary(year: number, districtIds: string[]): Observable<EthnicitySummary[]> {
    const httpParams = HttpParamsUtilities.toHttpParams({year, districtId: districtIds});
    return this.http.get<EthnicitySummary[]>(StudentService.EthnicitySummaryUrl, { params: httpParams});
  }

  public getStateEthnicitySummary(year: number): Observable<EthnicitySummary[]> {
    const httpParams = new HttpParams().append('year', year);
    return this.http.get<EthnicitySummary[]>(StudentService.EthnicitySummaryUrl, { params: httpParams});
  }
}
