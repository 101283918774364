import { Subject, EMPTY } from 'rxjs';
import { takeUntil, debounceTime, distinctUntilChanged, tap, catchError } from 'rxjs/operators';
import {
  AfterViewInit,
  OnDestroy,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ViewChild,
  input,
  model,
  output,
  signal,
  computed
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule, FormGroup } from '@angular/forms';

import { MatTableModule } from '@angular/material/table';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginator, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatSortModule, Sort } from '@angular/material/sort';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { TdoeButtonDirective, TdoeChipsComponent, ChipItem } from '@tdoe/design-system';
import { FeatureFlagDirective } from 'app/directives/feature-flag/feature-flag.directive';

import dayjs from 'dayjs';
import { Router } from '@angular/router';

import { StaffMember, StaffSearchTerms, StaffMemberStaffAssignment } from 'app/dto';
import { TableDataModel } from 'app/models/table-data-model';
import { ExtendedFormlyFieldConfig } from 'app/models/extendedFormlyFieldConfig';
import { Dictionary } from 'app/models/dictionary';

import { AdditionalInfoModel } from 'app/services/additional-info/additional-info.service';
import { AdditionalInfoComponent } from '../additional-info/additional-info.component';

import { ObjectUtilities } from 'app/utilities/object-utilities/object-utilities';
import { StringUtilities } from 'app/utilities/string-utilities/string-utilities';

import { additionalInfoFields } from './staff-table.additional-info-config';
import { formlyFieldConfigs } from './staff-table.formly-config';
import { NestedPropertyPipe } from 'app/pipes/nested-property/nested-property.pipe';


interface StaffRow extends StaffMember {
  staffAssignment?: StaffMemberStaffAssignment;
}

@Component({
  selector: 'app-staff-table',
  templateUrl: './staff-table.component.html',
  styleUrls: ['./staff-table.component.scss', '../styles/table.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    FormlyModule,
    FormlySelectModule,
    AdditionalInfoComponent,
    TdoeButtonDirective,
    TdoeChipsComponent,
    NgxSkeletonLoaderModule,
    MatMenuModule,
    FeatureFlagDirective,
    NestedPropertyPipe
  ],
  host: { class: 'sword-table' }
})
export class StaffTableComponent implements AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator)
  public paginator!: MatPaginator;
  public readonly additionalInfoContextKey = 'staff-data-lookup';
  public totalRecords = input.required<number>();
  public pageIndex = input<number>(0);
  public pageSize = signal<number>(50);
  public staffMembers = input.required<StaffMember[] | undefined>();
  public searchTerms = model<StaffSearchTerms>({});
  public pageChanged = output<TableDataModel.Pagination>();
  public sortClicked = output<TableDataModel.Sorting>();

  protected isLoading = true;

  private destroy$ = new Subject<void>();

  public expandedStaffRows = computed<StaffRow[]>(() => {
    const staffList = this.staffMembers();
    if (!staffList) {
      return [];
    }
    const rows: StaffRow[] = [];
    for (const staff of staffList) {
      const assignments = staff?.additionalInfo?.staffAssignments ?? [];
      if (assignments.length === 0) {
        rows.push(staff);
      } else {
        for (const assignment of assignments) {
          rows.push({ ...staff, staffAssignment: assignment });
        }
      }
    }
    return rows;
  });

  public paginatedRows = computed<StaffRow[]>(() => {
    const allRows = this.expandedStaffRows();
    const currentPageSize = this.pageSize();
    const currentPageIndex = this.pageIndex();
    return allRows.slice(currentPageIndex * currentPageSize, (currentPageIndex + 1) * currentPageSize);
  });

  public formlyFormGroup: FormGroup = new FormGroup({});
  public fields: FormlyFieldConfig[] = [];

  public readonly additionalInfoFields: AdditionalInfoModel.Category[] = additionalInfoFields;
  private readonly columnFiltersConfigMap = formlyFieldConfigs;
  private readonly staticFieldLabels = {
    nameFirst: 'First Name',
    nameMiddle: 'Middle Name',
    nameLast: 'Last Name',
    email: 'Staff Organization Email',
    district: 'District',
    school: 'School',
    gender: 'Gender',
    teacherLicenseNumber: 'Teacher License Number',
    licensureCheck: 'Licensure Check'
  };

  public fieldLabels: Record<string, string> = {
    ...this.additionalInfoFields
      .flatMap(category => category.fields)
      .reduce((acc, field) => ({ ...acc, [field.key]: field.name }), {}),
    ...this.staticFieldLabels
  };

  private readonly staticColumns = Object.keys(this.staticFieldLabels);

  protected readonly dynamicColumns = signal<AdditionalInfoModel.Field[]>([]);

  protected displayedColumns = computed(() => [
    ...this.staticColumns,
    ...this.dynamicColumns().map(field => field.key)
  ]);

  private readonly columnFilterConfigsSignal = computed(() => {
    const configs: { [key: string]: ExtendedFormlyFieldConfig[] } = {};
    Object.entries(this.columnFiltersConfigMap).forEach(([key, field]) => {
      const configField: ExtendedFormlyFieldConfig = { ...field };
      if (!configField.hooks?.onInit) {
        configField.hooks = {
          ...configField.hooks,
          onInit: (f: ExtendedFormlyFieldConfig): void => this.onFormlyFieldInit(f)
        };
      }
      configs[key] = [configField];
    });
    return configs;
  });

  public constructor(private readonly _router: Router, private readonly _changeDetector: ChangeDetectorRef) {}

  public ngAfterViewInit(): void {
    this.isLoading = false;
    this._changeDetector.detectChanges();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  protected onResetFiltersClick(): void {
    this.searchTerms.set({});
  }

  protected onPageChanged(pageEvent: PageEvent): void {
    this.pageSize.set(pageEvent.pageSize);
    this.pageChanged.emit({
      pageSize: pageEvent.pageSize,
      pageIndex: pageEvent.pageIndex
    });
  }

  protected onSortChanged(sort: Sort): void {
    this.sortClicked.emit({
      sortColumn: sort.active,
      sortDirection: sort.direction
    });
  }

  protected onAdditionalInfoSelectionChanged(categories: AdditionalInfoModel.Category[]): void {
    const selectedAdditionalInfoFields = categories
      .flatMap(cat => cat.fields)
      .filter(field => field.selected);
    this.dynamicColumns.set(selectedAdditionalInfoFields);
  }

  protected filterChipItems = computed(() =>
    Object.entries(ObjectUtilities.flattenObject(this.searchTerms()))
      .map(([k, v]) => ({ key: k, value: v }))
      .filter(kvp =>
        this.searchTermHasValue(kvp.value) &&
        !['schoolIds', 'districtIds', 'year', 'years', 'pageScope'].includes(kvp.key)
      )
      .map(entry => {
        const dateStr = this.isValidDate(entry.value)
          ? dayjs(entry.value).format('M-D-YYYY')
          : entry.value;
        return {
          text: `${this.fieldLabels[entry.key]}: '${dateStr}'`,
          persistent: false,
          key: entry.key,
          id: entry.key
        } as ChipItem;
      })
  );

  protected filterChipItemRemoved(filterChipItem: ChipItem): void {
    this.removeSearchTerm(filterChipItem.id);
  }

  protected onRowClicked(row: StaffRow): void {
    this._router.navigate([
      'data-lookup',
      'staff-view',
      row.id,
      StringUtilities.FormatStringForUrl(`${row.nameFirst}, ${row.nameLast}, ${row.nameMiddle}`)
    ]);
  }

  private onFormlyFieldInit(field: ExtendedFormlyFieldConfig): void {
    if (!field.formControl || field._initialized) {
      return;
    }
    field._initialized = true;
    field.formControl.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(val => {
          if (val) {
            this.addOrUpdateSearchTerm();
          } else {
            this.removeSearchTerm(field.key as string);
          }
        }),
        catchError(err => {
          console.error('Error in form field processing', err);
          return EMPTY;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private addOrUpdateSearchTerm(): void {
    this.searchTerms.update(searchTerms => ({ ...searchTerms }));
  }

  private removeSearchTerm(filterItemKey: string): void {
    this.searchTerms.update(searchTerms => {
      const updatedSearchTerms: Dictionary = {
        ...ObjectUtilities.removeFalsyStringProperties(searchTerms)
      };
      delete updatedSearchTerms[filterItemKey];
      return updatedSearchTerms as StaffSearchTerms;
    });
  }

  private isValidDate(value: unknown): value is Date {
    return value instanceof Date && !isNaN(value.getTime());
  }

  private searchTermHasValue(value: unknown): boolean {
    return Array.isArray(value) ? value.length > 0 : !!value;
  }

  protected getColumnFilterConfig(key: string): FormlyFieldConfig[] {
    const config = this.columnFilterConfigsSignal()[key];
    if (!config) {
      console.warn('Could not find formly config for key:', key);
      return [];
    }
    return config;
  }

  public trackByFn(index: number, row: StaffRow): string {
    return row?.id ?? index.toString();
  }

  public trackByColumns(index: number, col: string): string {
    return col;
  }
}