import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserProfile } from './user.model';
import { CacheService } from '../cache-service/cache.service';
import { AccountInfo } from '@azure/msal-browser';
import { ProfileService } from '@tdoe/design-system';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public userProfile$ = new BehaviorSubject<UserProfile | undefined>(undefined);

  private GetRoles(accountInfo: AccountInfo): string[] {
    console.log('UserService -> GetRoles -> Enter');
    const idTokenClaims = accountInfo.idTokenClaims;
    if (idTokenClaims) {
      const idTokenClaimValues = (idTokenClaims['scopedRoles'] ?? []);

      if(Array.isArray(idTokenClaimValues)){
        return (idTokenClaimValues as string[]).concat(idTokenClaims?.roles ?? []);
      }
      return ([idTokenClaimValues as string]).concat(idTokenClaims?.roles ?? []);
    }
    return [];
  }

  public constructor(
    private readonly _cacheService: CacheService,
    private readonly profileService: ProfileService
  ) {
    console.log('UserService -> constructor');
    profileService.azureAccount$.subscribe(_ => {
      if (_) this.initializeUserProfile(_);
    });
  }

  public isUserAuthenticated(): boolean {
    console.log('UserService -> isUserAuthenticated');
    const returnVal = !!this.userProfile$.value;
    console.log('UserService -> isUserAuthenticated', returnVal);
    return returnVal;
  }

  public initializeUserProfile(accountInfo: AccountInfo): void {
    console.log('UserService -> initializeUserProfile');
    if (accountInfo) {
      this.userProfile$.next(new UserProfile(this._cacheService, this.GetRoles(accountInfo)));
    } else {
      this.userProfile$.next(undefined);
    }
  }

}
