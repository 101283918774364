import { Component } from '@angular/core';
import { BreadcrumbItem, BreadcrumbService } from '../../../services/breadcrumb/breadcrumb.service';
import { Observable } from 'rxjs';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, RouterModule } from '@angular/router';

@Component({
  selector: 'app-breadcrumb',
  standalone: true,
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  imports: [RouterModule, CommonModule]
})
export class BreadcrumbComponent {
  public breadcrumbs$: Observable<BreadcrumbItem[]> = this._breadcrumbService.getBreadcrumbs();

  public constructor(private _breadcrumbService: BreadcrumbService,
    private _activatedRoute: ActivatedRoute
  ) {
    this._breadcrumbService.initializeBreadcrumbs(this._activatedRoute.root);
  }
}
