import { Category } from 'app/services/additional-info/additional-info.model';

export interface StaffCategoryConfig extends Category {
  alwaysShow?: boolean;
  isArray?: boolean;
  dataPath?: string;
}

export const StaffViewConfig = {
  categories: <StaffCategoryConfig[]>[
    {
      name: 'Staff Profile',
      expanded: true,
      alwaysShow: true,
      isArray: false,
      fields: [
        {
          name: 'Last Name',
          key: 'nameLast',
          selected: true,
          isStatic: true
        },
        {
          name: 'First Name',
          key: 'nameFirst',
          selected: true,
          isStatic: true
        },
        {
          name: 'Middle Name',
          key: 'nameMiddle',
          selected: true,
          isStatic: true
        },
        {
          name: 'Date of Birth',
          key: 'dateOfBirth',
          selected: true,
          isStatic: true
        },
        {
          name: 'Gender',
          key: 'gender',
          selected: true,
          isStatic: true
        },
        {
          name: 'District',
          key: 'district',
          selected: true,
          isStatic: true
        },
        {
          name: 'School',
          key: 'school',
          selected: true,
          isStatic: true
        },
        {
          name: 'Email',
          key: 'email',
          selected: true,
          isStatic: true
        }
      ]
    },
    {
      name: 'Staff Current Assignments',
      expanded: false,
      fields: [
        { name: 'Assignment Start Date', key: 'assignmentBeginDate', selected: false },
        { name: 'Assignment End Date', key: 'assignmentEndDate', selected: false }
      ]
    },
    {
      name: 'Staff Class Assignments',
      expanded: false,
      isArray: true,
      dataPath: 'additionalInfo.staffAssignments',
      fields: [
        { name: 'Assignment Code', key: 'assignmentCode', selected: false },
        { name: 'Assignment Start', key: 'assignmentStart', selected: false },
        { name: 'Assignment End', key: 'assignmentEnd', selected: false },
        { name: 'Class Assignments', key: 'classAssignments', selected: false },
        { name: 'Class Start', key: 'classStart', selected: false },
        { name: 'Class End', key: 'classEnd', selected: false },
        { name: 'Course Code', key: 'courseCode', selected: false },
        { name: 'Current Assignment', key: 'currentAssignment', selected: false },
        { name: 'Federally Funded', key: 'federallyFunded', selected: false },
        { name: 'Grades', key: 'grades', selected: false },
        { name: 'Local Class Number', key: 'localClassNumber', selected: false },
        { name: 'Period Duration', key: 'periodDuration', selected: false },
        { name: 'Teacher of Record', key: 'teacherOfRecord', selected: false },
        { name: 'Title', key: 'title', selected: false }
      ]
    },
    {
      name: 'Staff Licensure & Endorsement Details',
      expanded: false,
      isArray: false,
      // Omit dataPath if fields are mixed paths
      fields: [
        // Fields that live under staffMemberLicensureDetails
        { name: 'Date Issued', key: 'additionalInfo.staffMemberLicensureDetails.dateIssued', selected: false },
        { name: 'Description', key: 'additionalInfo.staffMemberLicensureDetails.description', selected: false },
        { name: 'Expiration Date', key: 'additionalInfo.staffMemberLicensureDetails.expirationDate', selected: false },
        { name: 'Number of Renewals', key: 'additionalInfo.staffMemberLicensureDetails.numberOfRenewals', selected: false },
        { name: 'Renewed Date', key: 'additionalInfo.staffMemberLicensureDetails.renewedDate', selected: false },
        { name: 'Type', key: 'additionalInfo.staffMemberLicensureDetails.type', selected: false },
        // Fields that live at root
        { name: 'Experience', key: 'experienceInYears', selected: false },
        { name: 'Education Level', key: 'educationLevel', selected: false },
        { name: 'Conferral Date', key: 'conferralDate', selected: false },
        { name: 'Date Added to File', key: 'addedToFile', selected: false }
      ]
    }
  ]
};