<div class="class-search-terms">
    @if (viewModel$ | async; as viewModel) {
        <form [formGroup]="viewModel.formlyFormGroup">
            <formly-form [form]="viewModel.formlyFormGroup" [model]="viewModel.studentSearchTerms" [fields]="viewModel.formlyFormFields"></formly-form>
            <div class="action-row">
                <button tdoe-button id="student-reset-button" type="secondary" (click)="onResetClick()">Reset</button>
                <button tdoe-button id="student-search-button"  (click)="onSearchClick()">Search</button>
            </div>
        </form>
    }
</div>
