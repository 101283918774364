import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ScopeContextFilterModel } from '../scope-context-filter.model';
import { StudentService } from 'app/services/student/student.service';
import { SchoolService } from 'app/services/school/school.service';
import { FormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { CommonModule } from '@angular/common';
import { LoggingService, TdoeDsModule } from '@tdoe/design-system';
import { BehaviorSubject, combineLatest, debounceTime, map, mergeMap, Observable, of} from 'rxjs';
import { PageScope } from 'app/enums/page-scope';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { District, School } from 'app/dto';

interface viewModel {
  selectedYear: number;
  selectedDistrictIds?: string[];
  selectedSchoolIds?: string[];
  /** is the state of the component valid so that when the search button is clicked a value can be emitted */
  isValid: boolean;
  years: string[];
  districts: District[];
  schools: School[];
}

@Component({
  selector: 'app-state-scope-context-filter',
  templateUrl: './state-scope-context-filter.component.html',
  styleUrls: ['./state-scope-context-filter.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    MatExpansionModule, 
    CommonModule,
    TdoeDsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule
  ],
  host: { class: 'scope-context-filter' } 
})
export class StateScopeContextFilterComponent {
    @Input()
    public set year(year: number | undefined) {
      if(year){
        this._selectedYearSubject$.next(year);
      }
    }

    @Input()
    public set districtIds(districtIds: string[] | undefined) {
      if(districtIds){
        console.debug('Input setter -> districtIds', districtIds);
        this._selectedDistrictIdSubject$.next(districtIds);
      }
    }
    
    @Input()
    public set schoolIds(schoolIds: string[] | undefined) {
      if(schoolIds){
        this._selectedSchoolIdSubject$.next(schoolIds);
      }
    }
    
    @Output() 
    public districtIdsChange = new EventEmitter<string[] | undefined>();

    @Output() 
    public schoolIdsChange = new EventEmitter<string[] | undefined>();
    
    @Output()
    public searchClick = new EventEmitter<ScopeContextFilterModel.SelectedData> ();

    private _selectedYearSubject$ = new BehaviorSubject<number>(new Date().getFullYear()); 
    private _selectedDistrictIdSubject$ = new BehaviorSubject<string[] | undefined>(undefined); 
    private _selectedSchoolIdSubject$ = new BehaviorSubject<string[] | undefined>(undefined); 

    protected viewModel$ : Observable<viewModel> = combineLatest([
      this._studentService.getAvailableYears(), 
      this._schoolService.getDistricts(),
      this._selectedDistrictIdSubject$,
      this._selectedSchoolIdSubject$,
      this._selectedYearSubject$
    ]).pipe(
      debounceTime(300),
      map(([years, districts, selectedDistrictIds, selectedSchoolIds, selectedYear]) => ({years, districts, selectedDistrictIds, selectedSchoolIds, selectedYear})),
      mergeMap(data => this.getSchools(data.selectedDistrictIds ?? []).pipe(
        map(schools => ({
          selectedYear: data.selectedYear,
          selectedDistrictIds: data.selectedDistrictIds,
          selectedSchoolIds: data.selectedSchoolIds,
          years: data.years,
          districts: data.districts,
          schools,
          isValid: data.selectedYear !== undefined
        }))
      ))
    );

    protected searchTerms?: ScopeContextFilterModel.SelectedData;

    public constructor(
      private readonly _studentService: StudentService,
      private readonly _schoolService: SchoolService,
      private readonly logger: LoggingService
    ) { }

    private getSchools(selectedDistrictIds: string[] ): Observable<School[]> {
      this.logger.debug('StateScopeContextFilterComponent -> getSchools', { data: { selectedDistrictIds }});
      return selectedDistrictIds.length !== 0
        ? this._schoolService.getSchools(selectedDistrictIds)
        : of([]);
    }

    protected onSchoolChange(e: MatSelectChange): void {
      this._selectedSchoolIdSubject$.next(e.value);
    }

    protected onDistrictIdChange(e: MatSelectChange) : void {
      this._selectedDistrictIdSubject$.next(e.value);
      this._selectedSchoolIdSubject$.next(undefined);
    }

    protected onYearChange(year: number): void {
      this._selectedYearSubject$.next(year);
    }

    protected onSearchClick(viewModel: viewModel): void {
      this.searchClick.emit({ 
        year: viewModel.selectedYear, 
        districtIds: viewModel.selectedDistrictIds, 
        schoolIds: viewModel.selectedSchoolIds,
        pageScope: viewModel.selectedSchoolIds
          ? PageScope.School 
          : viewModel.selectedDistrictIds
            ? PageScope.District
            : PageScope.State
      });
    }
}
