import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { BehaviorSubject, map, Observable, of } from 'rxjs';
import { CommonModule } from '@angular/common';
import { MatNativeDateModule } from '@angular/material/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { FormlyMatSelectModule } from '@ngx-formly/material/select';
import { TdoeDsModule } from '@tdoe/design-system';
import { ClassQuery } from 'app/dto';

@Component({
  selector: 'app-class-filter',
  standalone: true,
  imports: [
    CommonModule,
    TdoeDsModule, 
    FormsModule,
    ReactiveFormsModule,
    FormlyModule,
    FormlyMaterialModule,
    FormlyMatSelectModule,
    MatNativeDateModule,
    FormlyMatDatepickerModule
  ],
  templateUrl: './class-filter.component.html',
  styleUrl: './class-filter.component.scss'
})
export class ClassFilterComponent {
  @Output() public searchClicked = new EventEmitter<ClassQuery>();
  @Output() public resetClicked = new EventEmitter<void>();
  
  @Input()
  public set classSearchTerms(classSearchTerms: ClassQuery){
    this._classSearchTermsSubject$.next(classSearchTerms);
  }

  private _classSearchTermsSubject$ = new BehaviorSubject<ClassQuery>({});

  private _formlyFormGroup = new FormGroup({});
  private _formlyFormFields = [
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
         
          {
            key: 'localClassNumber',
            type: 'input',
            props: {
              label: 'Local Class Number',
              type: 'text',
              appearance: 'outline'
            }
          },
          {
            key: 'courseCode',
            type: 'input',
            props: {
              label: 'Course Code',
              type: 'text',
              appearance: 'outline'
            }
          },
          {
            key: 'classBeginDate',
            type: 'datepicker',
            props: {
              label: 'Begin Date',
              appearance: 'outline'
            }
          },
          {
            key: 'classEndDate',
            type: 'datepicker',
            props: {
              label: 'End Date',
              appearance: 'outline'
            }
          },
          {
            key: 'teacherOfRecord',
            type: 'select',
            props: {
              label: 'Teacher of Record',
              options: [
                { label: 'Yes', value: true },
                { label: 'No', value: false }
              ],
              appearance: 'outline'
            }
          },
          {
            key: 'teacherTLN',
            type: 'input',
            props: {
              label: 'Teacher TLN',
              type: 'text',
              appearance: 'outline'
            }
          },
          {
            key: 'testWindow',
            type: 'select',
            props: {
              label: 'Test Window',
              options: [
                { label: '(F) Fall Block', value: 'F' },
                { label: '(S) Spring Block', value: 'S' },
                { label: '(T) Traditional', value: 'T' }
              ],
              appearance: 'outline',
            }
          }
        ]
      }
    ] as FormlyFieldConfig[];

  public viewModel$ = this._classSearchTermsSubject$.pipe(
    map(studentSearchTerms => ({
        formlyFormGroup: this._formlyFormGroup,
        formlyFormFields: this._formlyFormFields,
        studentSearchTerms
      
    }))
  );
 
  protected onSearchClick(): void {
    this.searchClicked.emit(ClassFilterComponent.setEmptyStringsToUndefined(this._formlyFormGroup.value));
  }

  protected onResetClick(): void {
    this.classSearchTerms = {};
    this.resetClicked.emit();
  }

  // Clark: move this to a utilities class
  public static setEmptyStringsToUndefined(studentSearchTerms: ClassQuery): ClassQuery {
    const trimmedStudentSearchTerms = ClassFilterComponent.trimStrings(studentSearchTerms);
    return Object.fromEntries(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        Object.entries(trimmedStudentSearchTerms).filter(([_, value]) => value !== '' && value !== undefined)
    ) as ClassQuery;
  }

  public static trimStrings(terms: ClassQuery): ClassQuery {
    return Object.fromEntries(
        Object.entries(terms).map(([key, value]) => [
            key,
            typeof value === 'string' ? value.trim() : value
        ])
    ) as ClassQuery;
  }

  private getYears(): Observable<Array<{ label: string, value: number }>> {
    const years = Array.from({ length: 5 }, (_, i) => new Date().getFullYear() - i);
    return of(
      years.map(year => ({label: year.toString(), value: year}))
    );
  }
}
