import { AfterViewInit, Component, DestroyRef, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { CommonModule, DatePipe } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { FormsModule } from '@angular/forms';
import { TdoeDsModule } from '@tdoe/design-system';
import { ErrorLogServiceModel } from 'app/services/error-log/error-log.model';
import { ErrorLogService } from 'app/services/error-log/error-log.service';
import { filter, switchMap } from 'rxjs';
import { PageScope } from 'app/enums/page-scope';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UserService } from 'app/services/user/user.service';

@Component({
  selector: 'app-error-table',
  templateUrl: './error-table.component.html',
  styleUrl: './error-table.component.scss',
  standalone: true,
  imports: [
    CommonModule, 
    DatePipe, 
    FormsModule, 
    MatMenuModule, 
    MatPaginatorModule, 
    MatTableModule, 
    TdoeDsModule
  ]
})
export class ErrorTableComponent implements  AfterViewInit, OnInit  {

  protected readonly columns = ['calendarNumber', 'dataSourceEndpoint', 'errorDate', 'severityLevel', 'errorReport'];
  protected dataSource = new MatTableDataSource<ErrorLogServiceModel.ErrorItem>();

  @ViewChild(MatPaginator) 
  public paginator!: MatPaginator;

  public constructor(
    private _userService: UserService,
    private _errorLogService: ErrorLogService,
    private _destroyReference: DestroyRef ) {
      // 
  }

  public ngOnInit(): void {
    this._userService.userProfile$.pipe(
      takeUntilDestroyed(this._destroyReference),
      filter(userProfile => !!userProfile),
      switchMap(userProfile => {
        switch (userProfile?.pageScope) {
          case PageScope.State:{
            // hard coded until we can update this component. There is no getStateErrors Method
            return this._errorLogService.getDistrictErrors(userProfile.scopeDetails.districtIds);
          }
          case PageScope.District: {
            return this._errorLogService.getDistrictErrors(userProfile.scopeDetails.districtIds);
          }
          case PageScope.School: {
            // Clark: New info: Session state should have an array of scopeIds. I built the getSchoolErrors method with that in mind.
            return this._errorLogService.getSchoolErrors(userProfile.scopeDetails.schoolIds);
          }
          default: {
            throw new Error('Unknown Scope');
          }
        }
      })
    ).subscribe(errors => {
      this.dataSource.data = errors;
    });
  }

  public ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }
}