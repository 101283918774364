import { Injectable } from '@angular/core';
import { LoggingService, NavItem } from '@tdoe/design-system';
import { Observable, of, map, combineLatest } from 'rxjs';
import { UserService } from '../user/user.service';

interface NavItemFilterable extends NavItem {
  right?: string;
}

@Injectable({
  providedIn: 'root'
})
export class HeaderNavItemsService {

  private _navItemsFilterable$: Observable<NavItemFilterable[]> = of([
    {
      id: 'dashboard',
      text: 'Dashboard',
    },
    {
      id: 'data-lookup',
      text: 'Data Lookup',
      children: [
        { id: 'data-lookup/school', text: 'School' },
        { id: 'data-lookup/class', text: 'Class' },
        { id: 'data-lookup/staff', text: 'Staff' },
        { id: 'data-lookup/student', text: 'Student' },
        { id: 'data-lookup/statewide', text: 'Statewide' }
      ]
    },
    {
      id: 'error-log',
      text: 'Error Log',
      children: [
        { id: 'error-log/school', text: 'School' },
        { id: 'error-log/class', text: 'Class' },
        { id: 'error-log/staff', text: 'Staff' },
        { id: 'error-log/student', text: 'Student' },
        { id: 'error-log/total', text: 'Total' }
      ]
    },
    {
      id: 'reports',
      text: 'Reports'
    },
  ] as NavItemFilterable[]);

  private navItems$ = combineLatest([
    this._navItemsFilterable$,
    this._userService.userProfile$
  ]).pipe(
    map(([navItems, userProfile]) => ({ navItems, userProfile })),
    map(result => result.navItems)
  );

  public constructor(
    private _userService: UserService,
    private logger: LoggingService) { }

  public getNavItems(): Observable<NavItem[]> {
    return this.navItems$;
  }

  private filterNavItems(navItems: NavItemFilterable[], userRights: string[]): NavItem[] {
    this.logger.debug('HeaderNavItemsService -> filterNavItems', {
      data: {
        navItems,
        userRights
      }
    });
    return navItems.filter(navItem => {
      if (navItem.right && !userRights.includes(navItem.right)) {
        return false;
      }
      if (navItem.children) {
        navItem.children = this.filterNavItems(navItem.children, userRights);
        return navItem.children.length > 0;
      }
      return true;
    });
  }
}