/**
 * SWORD API
 * SWORD service API to be consumed by SWORD Angular SPA. Interfaces with Entra ID and DataBricks.
 *
 * OpenAPI spec version: v1
 * Contact: donnie.hyatt@tnedu.gov
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { SchoolAdditionalInformation } from './schoolAdditionalInformation';

/**
 * TN DOE School Entity
 */
export interface School { 
    /**
     * TEDS Unique LEA Identifier
     */
    districtId?: string;
    /**
     * District Number
     */
    districtNumber?: number;
    /**
     * District name
     */
    districtName?: string;
    /**
     * School Name
     */
    name?: string;
    /**
     * Weekly schedule
     */
    scheduledDays?: string;
    /**
     * TEDS Unique School Identifier  TEDS Column: k_school
     */
    schoolId?: string;
    /**
     * School number  TEDS Column: school_id
     */
    schoolNumber?: number;
    /**
     * School year
     */
    schoolYear?: number;
    additionalInformation?: SchoolAdditionalInformation;
}
