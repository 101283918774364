<div class="container header-title">
    <div class="container">
        <h1>School Details</h1>
    </div>
</div>

<div class="container" *ngIf="viewModel$ | async as vm">
    <div class="container" *ngIf="vm.school as school">
        <div class="header">
            <div class="back-link">
                <a [routerLink]="['/data-lookup', 'school']"><mat-icon>arrow_back</mat-icon> Back to School List</a>
            </div>
            <div class="buttons">
                <app-additional-info
                    [additionalInfoFields]="additionalInfoFields"
                    [contextKey]="'SchoolViewComponent'"
                    (fieldSelected)="onFieldSelected($event)"/>
                <button tdoe-button size="large" [matMenuTriggerFor]="menu">Export</button>
                <mat-menu #menu="matMenu" xPosition="before">
                    <button mat-menu-item>PDF</button>
                </mat-menu>
            </div>
        </div>
        <div class="mat-accordion" mat-accordion>
            <mat-expansion-panel expanded>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        School Directory Info
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="data-list">
                    <div class="field name-identity">
                        <span class="name">School Name and Number</span>
                        <span class="value">
                            <span [innerText]="school.name"></span>
                        </span>
                    </div>
                    <div class="field">
                        <span class="name">Principal Name</span>
                        <span class="value" [innerText]="school.additionalInformation?.directoryInfo?.principalName"></span>
                    </div>
                    <div class="field">
                        <span class="name">Principal Email Address</span>
                        <span class="value" [innerText]="school.additionalInformation?.directoryInfo?.principalEmailAddress"></span>
                    </div>
                    <div class="field">
                        <span class="name">Office Number</span>
                        <span class="value" [innerText]="school.additionalInformation?.directoryInfo?.officeNumber"></span>
                    </div>
                    <div class="field">
                        <span class="name">Address</span>
                        <span class="value" [innerText]="school.additionalInformation?.directoryInfo?.address"></span>
                    </div>
                    <div class="field">
                        <span class="name">City</span>
                        <span class="value" [innerText]="school.additionalInformation?.directoryInfo?.city"></span>
                    </div>
                    <div class="field">
                        <span class="name">State / Zip Code</span>
                        <span class="value">
                            <span [innerText]="school.additionalInformation?.directoryInfo?.state"></span>
                            <span>,&nbsp;</span>
                            <span [innerText]="school.additionalInformation?.directoryInfo?.zipCode"></span>
                        </span>
                    </div>
                    <div class="field">
                        <span class="name">Grade</span>
                        <span class="value" [innerText]="school.additionalInformation?.directoryInfo?.grade"></span>
                    </div>
                    <div class="field">
                        <span class="name">Programs</span>
                        <span class="value" [innerText]="school.additionalInformation?.directoryInfo?.programs"></span>
                    </div>
                    <div class="field">
                        <span class="name">Web Address</span>
                        <span class="value" [innerText]="school.additionalInformation?.directoryInfo?.webAddress"></span>
                    </div>
                </div>
            </mat-expansion-panel>
        </div>
        <div class="mat-accordion" mat-accordion>
            <mat-expansion-panel expanded>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        School Calendar Info
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="data-list">
                    <div class="field">
                        <span class="name">School Name</span>
                        <span class="value" [innerText]="school.name"></span>
                    </div>
                    <div class="field">
                        <span class="name">School Number</span>
                        <span class="value" [innerText]="school.schoolNumber"></span>
                    </div>
                    <div class="field">
                        <span class="name">Calendar Number</span>
                        <span class="value" [innerText]="school.additionalInformation?.schoolCalendarOverview?.calendarNumber"></span>
                    </div>
                    <div class="field">
                        <span class="name">Scheduled Days</span>
                        <span class="value" [innerText]="school.scheduledDays"></span>
                    </div>
                </div>
            </mat-expansion-panel>
        </div>
        <div class="mat-accordion" *ngIf="school.additionalInformation?.schoolCalendarOverview as overview" mat-accordion>
            <mat-expansion-panel expanded>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        School Calendar Overview
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="kpi-card-wrap">
                    <div class="kpi-tiles">
                        <tdoe-kpi-card [metricValue]="(overview.instructionalDays ?? 0).toString()"
                                       [metricName]="'Instructional Days'" />
                        <tdoe-kpi-card [metricValue]="(overview.abbreviatedDays ?? 0).toString()"
                                       [metricName]="'Abbreviated Days'" />
                        <tdoe-kpi-card [metricValue]="(overview.inServiceDays ?? 0).toString()"
                                       [metricName]="'In-Service Days'" />
                        <tdoe-kpi-card [metricValue]="(overview.inServiceOptionalDays ?? 0).toString()"
                                       [metricName]="'In-Service Optional Days'" />
                        <tdoe-kpi-card [metricValue]="(overview.makeUpDays ?? 0).toString()"
                                       [metricName]="'Make-up Days'" />
                        <tdoe-kpi-card [metricValue]="(overview.discretionaryDays ?? 0).toString()"
                                       [metricName]="'Discretionary Days'" />
                        <tdoe-kpi-card [metricValue]="(overview.parentTeacherConfDays ?? 0).toString()"
                                       [metricName]="'Parent Teacher Conference Days'" />
                        <tdoe-kpi-card [metricValue]="(overview.inclementWeatherDays ?? 0).toString()"
                                       [metricName]="'Stockpile-Inclement Weather Days'" />
                        <tdoe-kpi-card [metricValue]="(overview.professionalDevelopmentDays ?? 0).toString()"
                                       [metricName]="'Stockpile - Professional Development Days'" />
                        <tdoe-kpi-card [metricValue]="(overview.teacherVacationDays ?? 0).toString()"
                                       [metricName]="'Teacher Vacation Days'" />
                        <tdoe-kpi-card [metricValue]="(overview.waivedDays ?? 0).toString()"
                                       [metricName]="'Waived Days'" />
                        <tdoe-kpi-card [metricValue]="vm.totalDays.toString()"
                                       [metricName]="'Total Days'"
                                       [status]="'warn'" />
                    </div>
                </div>
            </mat-expansion-panel>
        </div>
        <div class="mat-accordion" *ngIf="school.additionalInformation?.reportingPeriods as periods" mat-accordion>
            <mat-expansion-panel expanded>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Reporting Periods
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                    <mat-table class="selectable-table" [dataSource]="periods">

                        <ng-container matColumnDef="periodNumber">
                            <mat-header-cell *matHeaderCellDef>Period Number</mat-header-cell>
                            <mat-cell *matCellDef="let period">Period {{period.periodNumber}}</mat-cell>
                            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                        </ng-container>

                        <ng-container matColumnDef="beginDate">
                            <mat-header-cell *matHeaderCellDef>Begin Date</mat-header-cell>
                            <mat-cell *matCellDef="let period">{{period.beginDate}}</mat-cell>
                            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                        </ng-container>

                        <ng-container matColumnDef="endDate">
                            <mat-header-cell *matHeaderCellDef>End Date</mat-header-cell>
                            <mat-cell *matCellDef="let period">{{period.endDate}}</mat-cell>
                            <mat-footer-cell *matFooterCellDef>Total Days</mat-footer-cell>
                        </ng-container>

                        <ng-container matColumnDef="numberOfDays">
                            <mat-header-cell *matHeaderCellDef>Number of Days</mat-header-cell>
                            <mat-cell *matCellDef="let period">{{period.numberOfDays}}</mat-cell>
                            <mat-footer-cell *matFooterCellDef>{{vm.totalPeriodDays}}</mat-footer-cell>
                        </ng-container>

                        <mat-header-row *matHeaderRowDef="vm.reportingPeriodDisplayedColumns"/>
                        <mat-row [class.tdoe-selected]="period===vm.reportingPeriod" *matRowDef="let period; columns: vm.reportingPeriodDisplayedColumns" (click)="onReportingPeriodRowClicked(period)" />
                        <mat-footer-row *matFooterRowDef="vm.reportingPeriodDisplayedColumns"/>

                        <!-- Row shown when there is no matching data. -->
                        <tr *matNoDataRow>
                            <td colspan="4">No reporting periods assigned</td>
                        </tr>
                    </mat-table>
                </div>
            </mat-expansion-panel>
        </div>
        @if(vm.reportingPeriod && vm.reportingPeriod.calendarDays) {
            <div class="mat-accordion calendar" mat-accordion>
                <mat-expansion-panel expanded>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <div class="dock-panel">
                                <span>School Calendar Days <span *ngIf="!vm.viewCalendar" class="header-muted">(Reporting period {{vm.reportingPeriod.periodNumber}})</span></span>
                                <tdoe-button size="small" [text]="'Switch to ' + (vm.viewCalendar ? 'List' : 'Calendar') + ' View'" (click)="toggleCalendarView()"/>
                            </div>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div>
                        @if (vm.viewCalendar) {
                            <app-calendar-days [reportingPeriod]="vm.reportingPeriod" [resolver]="vm.getEventTypeDisplay"></app-calendar-days>
                        } @else {
                            <mat-table class="selectable-table" [dataSource]="vm.reportingPeriod.calendarDays">

                                <ng-container matColumnDef="eventType">
                                    <mat-header-cell *matHeaderCellDef>Event Type</mat-header-cell>
                                    <mat-cell *matCellDef="let day">{{vm.getEventTypeDisplay(day.eventType)}}</mat-cell>
                                </ng-container>
        
                                <ng-container matColumnDef="eventDate">
                                    <mat-header-cell *matHeaderCellDef>Event Date</mat-header-cell>
                                    <mat-cell *matCellDef="let day">{{day.eventDate | date}}</mat-cell>
                                </ng-container>
        
                                <ng-container matColumnDef="duration">
                                    <mat-header-cell *matHeaderCellDef>Duration</mat-header-cell>
                                    <mat-cell *matCellDef="let day">{{day.duration}}</mat-cell>
                                </ng-container>
        
                                <ng-container matColumnDef="schoolDayType">
                                    <mat-header-cell *matHeaderCellDef>School Day Type</mat-header-cell>
                                    <mat-cell *matCellDef="let day">{{day.schoolDayType}}</mat-cell>
                                </ng-container>
        
                                <mat-header-row *matHeaderRowDef="vm.calendarDayDisplayedColumns" />
                                <mat-row *matRowDef="let day; columns: vm.calendarDayDisplayedColumns"/>
    
                                <!-- Row shown when there is no matching data. -->
                                <tr *matNoDataRow>
                                    <td colspan="4">No calendar days assigned</td>
                                </tr>
                            </mat-table>
                        }
                    </div>
                </mat-expansion-panel>
            </div>
        }
        <ng-container *ngFor="let category of vm.selectedAdditionalInfoFields">
            @if(category.key) {
                <ng-template [ngTemplateOutlet]="categoryPanelTable" [ngTemplateOutletContext]="{category: category, dataSource: getNestedProperty(vm.school,category.key)}"></ng-template>}
            @else {
                <ng-template [ngTemplateOutlet]="categoryPanel" [ngTemplateOutletContext]="{category: category, dataSource: vm.school}"></ng-template>
            }
        </ng-container>
    </div>
</div>

<ng-template #categoryPanel let-category="category" let-dataSource="dataSource">
    <div class="mat-accordion" mat-accordion>
        <mat-expansion-panel [(expanded)]="category.expanded">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{category.name}}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="data-list">
                <ng-container *ngFor="let field of category.fields">
                    <div class="field">
                        <span class="name">{{field.name}}</span>
                        <span class="value">{{ getNestedProperty(dataSource, field.key) }}</span>
                    </div>
                </ng-container>
            </div>
        </mat-expansion-panel>
    </div>
</ng-template>

<ng-template #categoryPanelTable let-category="category" let-dataSource="dataSource">
    <div class="mat-accordion" mat-accordion>
        <mat-expansion-panel [(expanded)]="category.expanded">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{category.name}}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
                <mat-table [dataSource]="dataSource">
                    @for (field of category.fields; track $index) {
                        <ng-container [matColumnDef]="field.key">
                            <mat-header-cell *matHeaderCellDef>{{field.name}}</mat-header-cell>
                            <mat-cell *matCellDef="let item">{{getNestedProperty(item, field.key)}}</mat-cell>
                        </ng-container>
                    }
                    <mat-header-row *matHeaderRowDef="category.displayedColumns" />
                    <mat-row *matRowDef="let field; columns: category.displayedColumns"/>
                    <!-- Row shown when there is no matching data. -->
                    <tr *matNoDataRow>
                        <td [colSpan]="category.displayedColumns.length">No {{category.name}} data.</td>
                    </tr>
                </mat-table>
            </div>
        </mat-expansion-panel>
    </div>
</ng-template>