import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { TableDataModel } from 'app/models/table-data-model';
import { ClassModel } from 'app/services/class/class.model';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-class-table',
  standalone: true,
  imports: [
    CommonModule,
    MatPaginatorModule,
    MatTableModule
  ],
  templateUrl: './class-table.component.html',
  styleUrl: './class-table.component.scss',
  host: { class: 'sword-table' } 
})
export class ClassTableComponent {
  @Input({required: true})
  public set totalRecords( totalRecords: number) {
    this._totalRecordsSubject$.next(totalRecords);
  }

  @Input({required: true})
  public set classes(classes: ClassModel.Class[]) {
    this._classSubject$.next(classes);
  }

  // @Input()
  // public set pageScope(pageScope: PageScope){
  //   this._pageScopeSubject$.next(pageScope);
  //   this._changeDetector.markForCheck();
  // }

  @Output()
  public pageChanged = new EventEmitter<TableDataModel.Pagination>();

  @Output()
  public sortClicked = new EventEmitter<TableDataModel.Sorting>();

  // @ViewChild(MatPaginator)
  // public paginator!: MatPaginator;

  // @ViewChild(MatSort)
  // public sort!: MatSort;

  private _totalRecordsSubject$ = new BehaviorSubject<number>(0);
  private _classSubject$ = new BehaviorSubject<ClassModel.Class[]>([]);
  //private _dynamicColumnsSubject$ = new BehaviorSubject<AdditionalInfoModel.Field[]>([]);
  //private _pageScopeSubject$ = new BehaviorSubject<PageScope>(PageScope.State);
  private _columns = [ 'localClassNumber', 'CourseCode', 'beginData', 'endDate', 'teacherOfRecord', 'teacherTLN', 'testWindow'];
  
}
