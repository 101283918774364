<div class="student-search-terms">
    @if (viewModel$ | async; as viewModel) {
        <div [formGroup]="viewModel.formlyFormGroup">
            <formly-form [form]="viewModel.formlyFormGroup" [model]="viewModel.studentSearchTerms" [fields]="viewModel.formlyFormFields"/>
            <div class="action-row">
                <button tdoe-button id="student-reset-button" type="secondary" (click)="onResetClick()">Reset</button>
                <button tdoe-button id="student-search-button"  (click)="onSearchClick()">Search</button>
            </div>
        </div>
    }
    @else {
        <ngx-skeleton-loader count="2" appearance="line"/>
    }
</div>
