<div class="container">
    <div class="container">
        <div class="header">
            <div class="controls">
                <div class="buttons">
                    <button tdoe-button>Additional Info</button>
                    <button tdoe-button (click)="toggleFilter()">
                        {{ isSimpleFilter ? 'Simple Filters' : 'Advanced Filters' }}
                    </button>
                    <button tdoe-button [matMenuTriggerFor]="menu">Export</button>
                    <mat-menu #menu="matMenu" xPosition="before">
                        <button mat-menu-item>CSV</button>
                        <button mat-menu-item>PDF</button>
                        <button mat-menu-item>Excel</button>
                    </mat-menu>
                </div>
            </div>
        </div>
        <div class="table-title">
            <span>School Information</span>
        </div>
        <table mat-table [dataSource]="dataSource">

            <ng-container matColumnDef="calendarNumber">
                <th mat-header-cell *matHeaderCellDef [matTooltip]="'Calendar Number'" matTooltipPosition="above"
                    matTooltipShowDelay="500" matTooltipHideDelay="200">
                    Calendar Number
                </th>
                <td mat-cell *matCellDef="let school" [matTooltip]="school.calendarNumber || 'N/A'"
                    matTooltipPosition="above" matTooltipShowDelay="500" matTooltipHideDelay="200">
                    {{school.calendarNumber || 'N/A'}}
                </td>
            </ng-container>

            <ng-container matColumnDef="schoolId">
                <th mat-header-cell *matHeaderCellDef [matTooltip]="'School'" matTooltipPosition="above"
                    matTooltipShowDelay="500" matTooltipHideDelay="200">
                    School
                </th>
                <td mat-cell *matCellDef="let school" [matTooltip]="school.schoolId || 'N/A'" matTooltipPosition="above"
                    matTooltipShowDelay="500" matTooltipHideDelay="200">
                    {{school.schoolId || 'N/A'}}
                </td>
            </ng-container>

            <ng-container matColumnDef="instructionalCalendar">
                <th mat-header-cell *matHeaderCellDef [matTooltip]="'Instructional Calendar'" matTooltipPosition="above"
                    matTooltipShowDelay="500" matTooltipHideDelay="200">
                    Instructional Calendar
                </th>
                <td mat-cell *matCellDef="let school" [matTooltip]="school.instructionalCalendar || 'N/A'"
                    matTooltipPosition="above" matTooltipShowDelay="500" matTooltipHideDelay="200">
                    {{school.instructionalCalendar || 'N/A'}}
                </td>
            </ng-container>

            <ng-container matColumnDef="scheduledDays">
                <th mat-header-cell *matHeaderCellDef [matTooltip]="'Scheduled Days'" matTooltipPosition="above"
                    matTooltipShowDelay="500" matTooltipHideDelay="200">
                    Scheduled Days
                </th>
                <td mat-cell *matCellDef="let school" [matTooltip]="school.scheduledDays || 'N/A'"
                    matTooltipPosition="above" matTooltipShowDelay="500" matTooltipHideDelay="200">
                    {{school.scheduledDays || 'N/A'}}
                </td>
            </ng-container>

            <ng-container matColumnDef="stockpileInclementWeatherDays">
                <th mat-header-cell *matHeaderCellDef [matTooltip]="'Stockpile Inclement Weather Days'"
                    matTooltipPosition="above" matTooltipShowDelay="500" matTooltipHideDelay="200">
                    Stockpile Inclement Weather Days
                </th>
                <td mat-cell *matCellDef="let school" [matTooltip]="school.stockpileInclementWeatherDays || 'N/A'"
                    matTooltipPosition="above" matTooltipShowDelay="500" matTooltipHideDelay="200">
                    {{school.stockpileInclementWeatherDays || 'N/A'}}
                </td>
            </ng-container>

            <ng-container matColumnDef="stockpileProfessionalDevelopmentDays">
                <th mat-header-cell *matHeaderCellDef [matTooltip]="'Stockpile Professional Development Days'"
                    matTooltipPosition="above" matTooltipShowDelay="500" matTooltipHideDelay="200">
                    Stockpile Professional Development Days
                </th>
                <td mat-cell *matCellDef="let school"
                    [matTooltip]="school.stockpileProfessionalDevelopmentDays || 'N/A'" matTooltipPosition="above"
                    matTooltipShowDelay="500" matTooltipHideDelay="200">
                    {{school.stockpileProfessionalDevelopmentDays || 'N/A'}}
                </td>
            </ng-container>

            <ng-container matColumnDef="inServiceDays">
                <th mat-header-cell *matHeaderCellDef [matTooltip]="'In-Service Days'" matTooltipPosition="above"
                    matTooltipShowDelay="500" matTooltipHideDelay="200">
                    In-Service Days
                </th>
                <td mat-cell *matCellDef="let school" [matTooltip]="school.inServiceDays || 'N/A'"
                    matTooltipPosition="above" matTooltipShowDelay="500" matTooltipHideDelay="200">
                    {{school.inServiceDays || 'N/A'}}
                </td>
            </ng-container>

            <ng-container matColumnDef="studentDay">
                <th mat-header-cell *matHeaderCellDef [matTooltip]="'Student Day'" matTooltipPosition="above"
                    matTooltipShowDelay="500" matTooltipHideDelay="200">
                    Student Day
                </th>
                <td mat-cell *matCellDef="let school" [matTooltip]="school.studentDay || 'N/A'"
                    matTooltipPosition="above" matTooltipShowDelay="500" matTooltipHideDelay="200">
                    {{school.studentDay || 'N/A'}}
                </td>
            </ng-container>

            <ng-container matColumnDef="teacherDay">
                <th mat-header-cell *matHeaderCellDef [matTooltip]="'Teacher Day'" matTooltipPosition="above"
                    matTooltipShowDelay="500" matTooltipHideDelay="200">
                    Teacher Day
                </th>
                <td mat-cell *matCellDef="let school" [matTooltip]="school.teacherDay || 'N/A'"
                    matTooltipPosition="above" matTooltipShowDelay="500" matTooltipHideDelay="200">
                    {{school.teacherDay || 'N/A'}}
                </td>
            </ng-container>

            <ng-container matColumnDef="blockSchedule">
                <th mat-header-cell *matHeaderCellDef [matTooltip]="'Block Schedule'" matTooltipPosition="above"
                    matTooltipShowDelay="500" matTooltipHideDelay="200">
                    Block Schedule
                </th>
                <td mat-cell *matCellDef="let school" [matTooltip]="school.blockSchedule || 'N/A'"
                    matTooltipPosition="above" matTooltipShowDelay="500" matTooltipHideDelay="200">
                    {{school.blockSchedule || 'N/A'}}
                </td>
            </ng-container>

            <ng-container matColumnDef="springBlockBeginDate">
                <th mat-header-cell *matHeaderCellDef [matTooltip]="'Spring Block Begin Date'"
                    matTooltipPosition="above" matTooltipShowDelay="500" matTooltipHideDelay="200">
                    Spring Block Begin Date
                </th>
                <td mat-cell *matCellDef="let school" [matTooltip]="school.springBlockBeginDate || 'N/A'"
                    matTooltipPosition="above" matTooltipShowDelay="500" matTooltipHideDelay="200">
                    {{school.springBlockBeginDate || 'N/A'}}
                </td>
            </ng-container>

            <!-- Header and Row Definitions -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator #paginator [pageSize]="50" [pageSizeOptions]="[25, 50, 100, 500, 1000]"
            [showFirstLastButtons]="false" [hidePageSize]="false" aria-label="Select page">
        </mat-paginator>
    </div>
</div>