/**
 * SWORD API
 * SWORD service API to be consumed by SWORD Angular SPA. Interfaces with Entra ID and DataBricks.
 *
 * OpenAPI spec version: v1
 * Contact: donnie.hyatt@tnedu.gov
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { StudentEnrollmentClassification } from './studentEnrollmentClassification';
import { StudentEnrollmentClubMembership } from './studentEnrollmentClubMembership';
import { StudentEnrollmentHistory } from './studentEnrollmentHistory';
import { StudentEnrollmentEndOfService } from './studentEnrollmentEndOfService';

export interface StudentEnrollment { 
    classifications?: Array<StudentEnrollmentClassification>;
    endOfService?: StudentEnrollmentEndOfService;
    clubMembership?: Array<StudentEnrollmentClubMembership>;
    enrollmentHistory?: Array<StudentEnrollmentHistory>;
}
