import { AfterViewInit, ChangeDetectorRef, Component, computed, input, model, output, signal, ViewChild } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { AdditionalInfoComponent } from '../additional-info/additional-info.component';
import { CommonModule } from '@angular/common';
import { SsidFormatPipe } from 'app/pipes/ssid-format/ssid-format.pipe';
import { MatMenuModule } from '@angular/material/menu';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StringUtilities } from 'app/utilities/string-utilities/string-utilities';
import { Router } from '@angular/router';
import { TableDataModel } from 'app/models/table-data-model';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { catchError, debounceTime, distinctUntilChanged, Observable, tap } from 'rxjs';
import { ChipItem, TdoeButtonDirective, TdoeChipsComponent } from '@tdoe/design-system';
import { AdditionalInfoModel } from 'app/services/additional-info/additional-info.model';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { AngularSvgIconModule } from 'angular-svg-icon';
import dayjs from 'dayjs';
import { Dictionary } from 'app/models/dictionary';
import { ObjectUtilities } from 'app/utilities/object-utilities/object-utilities';
import * as dto from 'app/dto/student';
import { StudentModel } from 'app/services/student/student.model';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@Component({
  selector: 'app-student-table',
  templateUrl: './student-table.component.html',
  styleUrl: './student-table.component.scss',
  standalone: true,
  imports: [
    CommonModule, 
    FormsModule, 
    MatMenuModule, 
    MatPaginatorModule, 
    MatTableModule, 
    MatSortModule,
    FormlyModule,
    FormlySelectModule,
    ReactiveFormsModule,
    AngularSvgIconModule,
    NgxSkeletonLoaderModule,
    SsidFormatPipe,
    TdoeButtonDirective,
    AdditionalInfoComponent, 
    TdoeChipsComponent,
  ],
  host: { class: 'sword-table' } 
})
export class StudentTableComponent implements AfterViewInit {
  public additionalInfoContextKey = 'StudentTableComponent';

  public totalRecords = input.required<number>();

  public students = input.required<dto.Student[] | undefined>();

  public searchTerms = model<StudentModel.StudentSearchTerms>({ });

  public pageChanged = output<TableDataModel.Pagination>();

  public sortClicked = output<TableDataModel.Sorting>();

  @ViewChild(MatPaginator)
  public paginator!: MatPaginator;

  @ViewChild(MatSort)
  public sort!: MatSort;

  @ViewChild(AdditionalInfoComponent)
  protected additionalInfoComponent!: AdditionalInfoComponent;

  protected readonly dynamicColumns = signal<AdditionalInfoModel.Field[]>([]);

  protected isLoading = true;


  protected readonly formlyFormGroup = new FormGroup({});

  protected readonly additionalInfoFields: AdditionalInfoModel.Category[] = [
    {
      name: 'Student Demographic',
      expanded: true,
      fields: [
        {
          name: 'Immigrant',
          key: 'immigration',
          selected: false
        },
        {
          name: 'Date First Enrolled in US School',
          key: 'dateFirstEnrolledInUsSchool',
          selected: false
        },
        {
          name: 'Year Entered 9th Grade',
          key: 'yearEnteredGrade9',
          selected: false
        },
        {
          name: 'Native Language',
          key: 'nativeLanguage',
          selected: false
        },
        {
          name: 'EL Service Years',
          key: 'elServiceYears',
          selected: false
        },
        {
          name: 'Calculated EL Service Years',
          key: 'calculatedElServiceYears',
          selected: false
        },
        {
          name: 'English Language Background',
          key: 'englishLanguageBackground',
          selected: false
        },
        {
          name: 'Race',
          key: 'race',
          selected: false
        }
      ]
    },
    {
      name: 'Enrollment Info',
      expanded: true,
      fields: [
        {
          name: 'Enrollment Start Date',
          key: 'enrollmentStartDate',
          selected: false
        },
        {
          name: 'Enrollment Code',
          key: 'enrollmentCode',
          selected: false
        },
        {
          name: 'Classification',
          key: 'enrollmentClassification',
          selected: false
        },
        {
          name: 'TOS',
          key: 'tos',
          selected: false
        },
        {
          name: 'Funding Ineligibility',
          key: 'fundingEligibility',
          selected: false
        },
        {
          name: 'Withdraw Date',
          key: 'enrollmentEndDate',
          selected: false
        },
        {
          name: 'Withdraw Reason',
          key: 'withdrawReason',
          selected: false
        }
      ]
    },
    {
      name: 'Class Assignment',
      expanded: true,
      fields: [
        {
          name: 'Local Class Number',
          key: 'localClassNumber',
          selected: false
        },
        {
          name: 'Course Code',
          key: 'courseCode',
          selected: false
        },
        {
          name: 'Class Type',
          key: 'classType',
          selected: false
        },
        {
          name: 'Voc. Outside IP',
          key: 'vocOutsideIP',
          selected: false
        },
        {
          name: 'Enrolment Period Start Date',
          key: 'enrolmentPeriodStartDate',
          selected: false
        },
        {
          name: 'Enrolment Period End Date',
          key: 'enrolmentPeriodEndDate',
          selected: false
        },
        {
          name: 'Assignment Start Dates',
          key: 'assignmentStartDates',
          selected: false
        },
        {
          name: 'Assignment End Dates',
          key: 'assignmentEndDates',
          selected: false
        },
        {
          name: 'Class Start Dates',
          key: 'classStartDates',
          selected: false
        },
      ],
    },
    {
      name: 'Classification',
      expanded: false,
      fields: [
        {
          name: 'Classification',
          key: 'classification',
          selected: false
        },
        {
          name: 'Begin Date',
          key: 'classificationBeginDate',
          selected: false
        },
        {
          name: 'End Date',
          key: 'classificationEndDate',
          selected: false
        }
      ],
    },
    {
      name: 'Final Grade',
      expanded: false,
      fields: [
        {
          name: 'Local Class No.',
          //Hack: I added FG at the end because there is another field in the table with the same key 
          key: 'localClassNumberFG',
          selected: false
        },
        {
          name: 'Course Code',
          //Hack: I added FG at the end because there is another field in the table with the same key 
          key: 'courseCodeFG',
          selected: false
        },
        {
          name: 'Credits Earned',
          key: 'creditsEarned',
          selected: false
        },
        {
          name: 'Alpha Grade',
          key: 'alphaGrade',
          selected: false
        },
        {
          name: 'Numeric Grade',
          key: 'numericGrade',
          selected: false
        }
      ]
    },
    {
      name: 'Special Ed Options',
      expanded: false,
      fields: [
        {
          name: 'Option Level',
          key: 'optionLevel',
          selected: false
        },
        {
          name: 'Option Number',
          key: 'optionNumber',
          selected: false
        },
        {
          name: 'Begin Date',
          key: 'specEdBeginDate',
          selected: false
        },
        {
          name: 'End Date',
          key: 'specEdEndDate',
          selected: false
        }
      ]
    },
    {
      name: 'Special Ed Disabilities',
      expanded: false,
      fields: [
        {
          name: 'Disability Level',
          key: 'specEdDisabilityLevel',
          selected: false
        },
        {
          name: 'Disability Type',
          key: 'specEdDisabilityType',
          selected: false
        },
        {
          name: 'Begin Date',
          key: 'specEdDisabilityBeginDate',
          selected: false
        },
        {
          name: 'End Date',
          key: 'specEdDisabilityEndDate',
          selected: false
        }
      ]
    },
    {
      name: 'Standard AMA/ADA',
      expanded: false,
      fields: [
        {
          name: 'Report Period',
          key: 'standardReportPeriod',
          selected: false
        },
        {
          name: 'Student ADM',
          key: 'standardStudentAdm',
          selected: false
        },
        {
          name: 'Student ADA',
          key: 'standardStudentAda',
          selected: false
        }
      ]
    },
    {
      name: 'Vocational ADM/ADA',
      expanded: false,
      fields: [
        {
          name: 'Report Period',
          key: 'vocationalReportPeriod',
          selected: false
        },
        {
          name: 'Program Item',
          key: 'vocationalProgramItem',
          selected: false
        },
        {
          name: 'Student ADM',
          key: 'vocationalStudentAdm',
          selected: false
        },
        {
          name: 'Student ADA',
          key: 'vocationalStudentAda',
          selected: false
        }
      ]
    },
    {
      name: 'Club Membership',
      expanded: false,
      fields: [
        {
          name: 'Club ID',
          key: 'clubID',
          selected: false
        },
        {
          name: 'Begin Date',
          key: 'clubBeginDate',
          selected: false
        },
        {
          name: 'End Date',
          key: 'clubEndDate',
          selected: false
        },
      ],
    },
    {
      name: 'Disciplinary Actions',
      expanded: false,
      fields: [
        {
          name: 'Enrollment Period Start',
          key: 'enrollmentPeriodStart',
          selected: false
        },
        {
          name: 'Enrollment Period End',
          key: 'enrollmentPeriodEnd',
          selected: false
        },
        {
          name: 'Disciplinary Period Begin',
          key: 'disciplinaryPeriodBegin',
          selected: false
        },
        {
          name: 'Disciplinary Period End',
          key: 'disciplinaryPeriodEnd',
          selected: false
        },
        {
          name: 'Disciplinary Type',
          key: 'disciplinaryType',
          selected: false
        },
        {
          name: 'Disciplinary Reason',
          key: 'disciplinaryReason',
          selected: false
        },
        {
          name: 'Sped',
          key: 'sped',
          selected: false
        },
        {
          name: 'Zero Tolerance',
          key: 'zeroTolerance',
          selected: false
        },
      ],
    },
  ];

  protected filterChipItems = computed(() => 
    Object.entries(this.searchTerms())
      .map(([key, value]) => ({ key, value }))
      .filter(kvp => !!kvp.value && !['schoolIds', 'districtIds', 'year', 'pageScope'].includes(kvp.key))
      .map(entry => ({
        text: `${entry.key}: '${this.isValidDate(entry.value) ? dayjs(entry.value).format('M-D-YYYY') : entry.value }'`,
        persistent: false,
        key: entry.key,
        id: entry.key
      } as ChipItem)));
  
  protected displayedColumns = computed(() => [
      ...this._staticColumns,
      ...this.dynamicColumns().map(field => field.key),
      'school',
      'district'
    ]);
  
  private readonly _staticColumns = [ 'nameLast', 'nameFirst', 'nameMiddle', 'ssid', 'dateOfBirth', 'grade', 'code'];  
  
  private readonly _columnFiltersConfig: { [key: string]: FormlyFieldConfig } = {
    'grade': {
      key: 'grade',
      type: 'select',
      props: {
        options: [
          {
            label: 'K',
            value: 'K'
          },
          {
            label: '1',
            value: '1'
          },
          {
            label: '2',
            value: '2'
          },
          {
            label: '3',
            value: '3'
          },
          {
            label: '4',
            value: '4'
          },
          {
            label: '5',
            value: '5'
          },
          {
            label: '6',
            value: '6'
          },
          {
            label: '7',
            value: '7'
          },
          {
            label: '8',
            value: '8'
          },
          {
            label: '9',
            value: '9'
          },
          {
            label: '10',
            value: '10'
          },
          {
            label: '11',
            value: '11'
          },
          {
            label: '12',
            value: '12'
          }
        ],
        multiple: true
      }
    },
    'ssid': {
      key: 'ssid',
      type: 'input',
      props: {
        type: 'text'
      }
    },
    'nameFirst': {
      key: 'nameFirst',
      type: 'input'
    },
    'nameLast': {
      key: 'nameLast',
      type: 'input'
    },
    'nameMiddle': {
      key: 'nameMiddle',
      type: 'input'
    },
    'dateOfBirth': {
      key: 'dateOfBirth',
      type: 'datepicker'
    },
    'district': {
      key: 'district',
      type: 'input'
    },
    'code': {
      key: 'code',
      type: 'input'
    },
    'school': {
      key: 'school',
      type: 'input'
    },
    'immigration': {
      key: 'immigration',
      type: 'input'
    },
    'dateFirstEnrolledInUsSchool': {
      key: 'dateFirstEnrolledInUsSchool',
      type: 'datepicker'
    },
    'yearEnteredGrade9': {
      key: 'yearEnteredGrade9',
      type: 'input'
    },
    'nativeLanguage': {
      key: 'nativeLanguage',
      type: 'input'
    },
    'elServiceYears': {
      key: 'elServiceYears',
      type: 'input'
    },
    'calculatedElServiceYears': {
      key: 'calculatedElServiceYears',
      type: 'input'
    },
    'englishLanguageBackground': {
      key: 'englishLanguageBackground',
      type: 'input'
    },
    'race': {
      key: 'race',
      type: 'input'
    },
    'enrollmentCode': {
      key: 'enrollmentCode',
      type: 'input'
    },
    'enrollmentClassification': {
      key: 'enrollmentClassification',
      type: 'input'
    },
    'tos': {
      key: 'tos',
      type: 'input'
    },
    'fundingEligibility': {
      key: 'fundingEligibility',
      type: 'input'
    },
    'withdrawReason': {
      key: 'withdrawReason',
      type: 'input'
    },
    'enrollmentStartDate': {
      key: 'enrollmentStartDate',
      type: 'datepicker'
    },
    'enrollmentEndDate': {
      key: 'enrollmentEndDate',
      type: 'datepicker'
    },
    'attendanceDate': {
      key: 'attendanceDate',
      type: 'datepicker'
    },
    'attendanceType': {
      key: 'attendanceType',
      type: 'select',
      props: {
        options: [
          { label: 'A', value: 'A' },
          { label: 'H', value: 'H' },
          { label: 'I', value: 'I' },
          { label: 'Y', value: 'Y' },
          { label: 'Z', value: 'Z' }
        ],
        multiple: true
      }
    },
    'label': {
      key: 'label',
      type: 'input'
    },
    'localClassNumber': {
      key: 'localClassNumber',
      type: 'input'
    },
    'courseCode': {
      key: 'courseCode',
      type: 'input'
    },
    'classType': {
      key: 'classType',
      type: 'select',
      props: {
        options: [
          { label: 'C', value: 'C' },
          { label: 'P', value: 'P' },
          { label: 'T', value: 'T' }
        ],
        multiple: true
      }
    },
    'vocOutsideIP': {
      key: 'vocOutsideIP',
      type: 'select',
      props: {
        options: [
          { label: 'Y', value: 'Y' },
          { label: 'N', value: 'N' }
        ]
      }
    },
    'enrolmentPeriodStartDate': {
      key: 'enrolmentPeriodStartDate',
      type: 'datepicker'
    },
    'enrolmentPeriodEndDate': {
      key: 'enrolmentPeriodEndDate',
      type: 'datepicker'
    },
    'assignmentStartDates': {
      key: 'assignmentStartDates',
      type: 'datepicker'
    },
    'assignmentEndDates': {
      key: 'assignmentEndDates',
      type: 'datepicker'
    },
    'classStartDates': {
      key: 'classStartDates',
      type: 'datepicker'
    },
    'classification': {
      key: 'classification',
      type: 'select',
      props: {
        multiple: true,
        options: [
          { label: 'ID619', value: 'ID619' },
          { label: '504', value: '504' },
          { label: 'R', value: 'R' },
          { label: 'DYS02', value: 'DYS02' },
          { label: 'DYS03', value: 'DYS03' },
          { label: 'MF', value: 'MF' },
          { label: 'MR', value: 'MR' },
          { label: 'DYS01', value: 'DYS01' },
          { label: 'A', value: 'A' }
        ]
      }
    },
    'classificationBeginDate': {
      key: 'classificationBeginDate',
      type: 'datepicker'
    },
    'classificationEndDate': {
      key: 'classificationEndDate',
      type: 'datepicker'
    },
    'localClassNumberFG': {
      key: 'localClassNumberFG',
      type: 'input'
    },
    'courseCodeFG': {
      key: 'courseCodeFG',
      type: 'input'
    },
    'creditsEarned': {
      key: 'creditsEarned',
      type: 'input',
      props: {
        type: 'number'
      }
    },
    'alphaGrade': {
      key: 'alphaGrade',
      type: 'input',
      props: {
        type: 'number'
      }
    },
    'numericGrade': {
      key: 'numericGrade',
      type: 'input',
      props: {
        type: 'number'
      }
    },
    'optionLevel': {
      key: 'optionLevel',
      type: 'input'
    },
    'optionNumber': {
      key: 'optionNumber',
      type: 'input',
      props: {
        type: 'number'
      }
    },
    'specEdBeginDate': {
      key: 'specEdBeginDate',
      type: 'datepicker'
    },
    'specEdEndDate': {
      key: 'specEdEndDate',
      type: 'datepicker'
    },
    'specEdDisabilityLevel': {
      key: 'specEdDisabilityLevel',
      type: 'input'
    },
    'specEdDisabilityType': {
      key: 'specEdDisabilityType',
      type: 'input'
    },
    'specEdDisabilityBeginDate': {
      key: 'specEdDisabilityBeginDate',
      type: 'datepicker'
    },
    'specEdDisabilityEndDate': {
      key: 'specEdDisabilityEndDate',
      type: 'datepicker'
    },
    'standardReportPeriod': {
      key: 'standardReportPeriod',
      type: 'input'

    },
    'standardStudentAdm': {
      key: 'standardStudentAdm',
      type: 'input'
    },
    'standardStudentAda': {
      key: 'standardStudentAda',
      type: 'input'
    },
    'vocationalReportPeriod': {
      key: 'vocationalReportPeriod',
      type: 'input',
    },
    'vocationalProgramItem': {
      key: 'vocationalProgramItem',
      type: 'input',
    },
    'vocationalStudentAdm': {
      key: 'vocationalStudentAdm',
      type: 'input',
    },
    'vocationalStudentAda': {
      key: 'vocationalStudentAda',
      type: 'input',
    },
    'clubID': {
      key: 'clubID',
      type: 'input'
    },
    'clubBeginDate': {
      key: 'clubBeginDate',
      type: 'datepicker'
    },
    'clubEndDate': {
      key: 'clubEndDate',
      type: 'datepicker'
    },
    'enrollmentPeriodStart': {
      key: 'enrollmentPeriodStart',
      type: 'datepicker'
    },
    'enrollmentPeriodEnd': {
      key: 'enrollmentPeriodEnd',
      type: 'datepicker'
    },
    'disciplinaryPeriodBegin': {
      key: 'disciplinaryPeriodBegin',
      type: 'datepicker'
    },
    'disciplinaryPeriodEnd': {
      key: 'disciplinaryPeriodEnd',
      type: 'datepicker'
    },
    'disciplinaryType': {
      key: 'disciplinaryType',
      type: 'select',
      props: {
        options: [
          { label: 'I', value: 'I' },
          { label: 'R', value: 'R' }
        ],
        multiple: true,
      }
    },
    'disciplinaryReason': {
      key: 'disciplinaryReason',
      type: 'input'
    },
    'sped': {
      key: 'sped',
      type: 'select',
      props: {
        options: [
          { label: 'Y', value: 'Y' },
          { label: 'N', value: 'N' }
        ]
      }
    },
    'zeroTolerance': {
      key: 'zeroTolerance',
      type: 'select',
      props: {
        options: [
          { label: 'Y', value: 'Y' },
          { label: 'N', value: 'N' }
        ]
      }
    }
  };

  public constructor(
    private readonly _router: Router,
    private readonly _changeDetector: ChangeDetectorRef
  ) {}

  public ngAfterViewInit(): void {
    this.isLoading = false;
    this._changeDetector.detectChanges();
  }

  protected onResetFiltersClick(): void {
    this.searchTerms.set({});
  }

  protected onPageChanged(pageEvent: PageEvent): void {
    this.pageChanged.emit({ 
      pageSize: pageEvent.pageSize, 
      pageIndex: pageEvent.pageIndex } as TableDataModel.Pagination);
  }

  protected onAdditionalInfoSelectionChanged(categories: AdditionalInfoModel.Category[]): void {
    const selectedAdditionalInfoFields = categories.flatMap(additionalInfoCategory => additionalInfoCategory.fields)
      .filter(field => field.selected)
      .map(field => field);
  
    this.dynamicColumns.set(selectedAdditionalInfoFields);
  }
  
  protected getColumnFilterConfig(key: string): FormlyFieldConfig[] {
    const field = this._columnFiltersConfig[key];
    if (!field) {
      return [];
    }
    this.attachOnInit(field);
    return [field];
  }

  protected filterChipItemRemoved(filterChipItem: ChipItem): void {
    this.removeSearchTerm(this.getFilterItemKey(filterChipItem));
  }
  
  protected onSortChanged(sort: Sort) : void {
    this.sortClicked.emit({
      sortColumn: sort.active,
      sortDirection: sort.direction
    } as TableDataModel.Sorting);
  }

  protected onRowClicked(student: dto.Student): void {
    this._router.navigate(['data-lookup','student-view', student.id, StringUtilities.FormatStringForUrl(`${student.nameFirst}, ${student.nameLast}, ${student.nameMiddle}`)]);
  }

  private onFormlyFieldInit(field: FormlyFieldConfig): void {
    field.formControl?.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(val => { 
        // Handle value change logic
        if (val) {
          this.addFilterItemToSearchTerms(field, val);
        } else {
          this.removeSearchTerm(field.key as string);
        }
      }),
      catchError(err => {
        console.debug('Error in form field processing', err);
        return new Observable();
      })
    ).subscribe();
  }

  private getFilterItemKey(filterChipItem: ChipItem): string {
    const textParts = filterChipItem?.text?.split(':');
    if(!textParts || textParts.length < 2 || !textParts[0]?.trim()) throw new Error(`filterChipItem.text is not in the correct format: '${filterChipItem.text}'`);
    return textParts[0];
  }

  private removeSearchTerm(filterItemKey: string): void {
    this.searchTerms.update(searchTerms => {
      const updatedSearchTerms: Dictionary = { ... ObjectUtilities.removeFalsyStringProperties(searchTerms) };
      delete updatedSearchTerms[filterItemKey];
      return updatedSearchTerms  as StudentModel.StudentSearchTerms;
    });
  }

  private addFilterItemToSearchTerms(field: FormlyFieldConfig, value: unknown): void {
    this.searchTerms.update(searchTerms => ({
      ...searchTerms,
      [field.key as string]: value
    }));
  }

  private attachOnInit (field: FormlyFieldConfig) : void
  {
    if(field && !field.hooks?.onInit){
      field.hooks =  {
        ...field?.hooks,
        onInit: (field: FormlyFieldConfig): void => this.onFormlyFieldInit(field)
      };
    }
  }

  private isValidDate(value: unknown): value is Date {
      return value instanceof Date && !isNaN(value.getTime());
  }
}
