export * as StudentFilterModel from './student-filter.model';

export interface StudentSearchTerms {
    nameFirst?: string,
    nameLast?: string,
    nameMiddle?: string,
    ssid?: string,
    dateOfBirth?: Date,
    grades?: string[],
    year?: number
}