export * as StudentFilterModel from './student-filter.model';

export interface StudentSearchTerms {
    firstName?: string,
    lastName?: string,
    middleName?: string,
    ssid?: string,
    dateOfBirth?: Date,
    grade?: string,
    year?: number
}