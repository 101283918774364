import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './components/home/about/about.component';
import { HomeComponent } from './components/home/home.component';
import { LandingComponent } from './components/home/landing/landing.component';
import { ProgramParticipationOneComponent as ProgramParticipationComponent } from './components/home/program-participation/program-participation.component';
import { GradeLevelComponent } from './components/home/grade-level/grade-level.component';
import { DataLookupComponent } from './components/data-lookup/data-lookup.component';
import { DataLookupSchoolComponent } from './components/data-lookup/data-lookup-school/data-lookup-school.component';
import { DataLookupClassComponent } from './components/data-lookup/data-lookup-class/data-lookup-class.component';
import { DataLookupStaffComponent } from './components/data-lookup/data-lookup-staff/data-lookup-staff.component';
import { DataLookupStudentComponent } from './components/data-lookup/data-lookup-student/data-lookup-student.component';
import { DataLookupStatewideComponent } from './components/data-lookup/data-lookup-statewide/data-lookup-statewide.component';
import { StudentViewComponent } from './components/data-lookup/student-view/student-view.component';
import { ErrorLogComponent } from './components/error-log/error-log.component';
import { ErrorLogSchoolComponent } from './components/error-log/error-log-school/error-log-school.component';
import { ErrorLogClassComponent } from './components/error-log/error-log-class/error-log-class.component';
import { ErrorLogStaffComponent } from './components/error-log/error-log-staff/error-log-staff.component';
import { ErrorLogStudentComponent } from './components/error-log/error-log-student/error-log-student.component';
import { ErrorLogTotalComponent } from './components/error-log/error-log-total/error-log-total.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { StaffViewComponent } from './components/data-lookup/staff-view/staff-view.component';
import { MsalGuard } from '@azure/msal-angular';
import { ClassViewComponent } from './components/data-lookup/class-view/class-view.component';
import { SchoolViewComponent } from './components/data-lookup/school-view/school-view.component';

const routes: Routes = [
  {
    path: 'home',
    title: 'Home',
    component: HomeComponent,
    data: {
      
    },
    children: [
      {
        path: '',
        title: 'Home',
        component: LandingComponent,
        data: {
        },
        
      },
      {
        path: 'about',
        title: 'About Sword',
        component: AboutComponent,
        data: {
        }
      },
      {
        path: 'program-participation',
        title: 'Program Participation',
        component: ProgramParticipationComponent,
        data: {
        }
      },
      {
        path: 'grade-level',
        title: 'Grade Level',
        component: GradeLevelComponent,
        data: {
        }
      }
    ]
  },
  {
    path: 'data-lookup',
    title: 'Data Lookup',
    component: DataLookupComponent,
    canActivate: [MsalGuard],
    data: {
    },
    children: [
      {
        path: '',
        redirectTo: 'school',
        pathMatch: 'full'
      },
      {
        path: 'school',
        title: 'School List',
        component: DataLookupSchoolComponent
      },
      {
        path: 'school/:id',
        title: 'School Details',
        component: SchoolViewComponent
      },
      {
        path: 'class',
        title: 'Class List',
        component: DataLookupClassComponent
      },
      {
        path: 'staff',
        title: 'Staff List',
        component: DataLookupStaffComponent
      },
      {
        path: 'student',
        title: 'Student List',
        component: DataLookupStudentComponent
      },
      {
        path: 'statewide',
        title: 'Statewide Search',
        component: DataLookupStatewideComponent,
      },
      {
        path: 'student-view/:id/:name',
        title: 'Student Details',
        component: StudentViewComponent
      },
      {
        path: 'staff-view/:id/:name',
        title: 'Staff Details',
        component: StaffViewComponent
      },
      {
        path: 'class/:id',
        title: 'Class Details',
        component: ClassViewComponent
      }
    ]
  },
  {
    path: 'error-log',
    title: 'Error Log',
    component: ErrorLogComponent,
    canActivate: [MsalGuard],
    data: {
    },
    children: [
      {
        path: '',
        redirectTo: 'all',
        pathMatch: 'full'
      },
      {
        path: 'school',
        title: 'School',
        component: ErrorLogSchoolComponent
      },
      {
        path: 'class',
        title: 'Class',
        component: ErrorLogClassComponent
      },
      {
        path: 'staff',
        title: 'Staff',
        component: ErrorLogStaffComponent
      },
      {
        path: 'student',
        title: 'Student',
        component: ErrorLogStudentComponent
      },
      {
        path: 'all',
        title: 'Total',
        component: ErrorLogTotalComponent
      }
    ]
  },
  {
    path: 'dashboard',
    title: 'Dashboard',
    component: DashboardComponent,
    canActivate: [MsalGuard],
    data: {
    },
    children: [
      {
        path: '',
        redirectTo: 'school',
        pathMatch: 'full'
      },
      {
        path: 'school',
        title: 'School',
        component: DashboardComponent
      },
      {
        path: 'class',
        title: 'Class',
        component: DashboardComponent
      },
      {
        path: 'staff',
        title: 'Staff',
        component: DashboardComponent
      },
      {
        path: 'student',
        title: 'Student',
        component: DashboardComponent
      },
      {
        path: 'statewide',
        title: 'State Wide',
        component: DashboardComponent
      }
    ]
  },
  { 
    path: '', 
    redirectTo: '/home', 
    pathMatch: 'full' 
  },
  { 
    path: '**', 
    redirectTo: '/home'
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
    CommonModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
