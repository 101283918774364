/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component } from '@angular/core';
import { ScopeContextFilterModel, SelectedData } from '../shared/scope-context-filter/scope-context-filter.model';
import { ErrorCategoryCardsComponent } from './error-category-cards/error-category-cards.component';
import { CommonModule } from '@angular/common';
import { filter, map} from 'rxjs';
import { PageScope } from 'app/enums/page-scope';
import { BreadcrumbComponent } from '../shared/breadcrumb/breadcrumb.component';
import { AverageDailyMembershipComponent } from './average-daily-membership/average-daily-membership.component';
import { DemographicsComponent } from './demographics/demographics.component';
import { ScopeContextFilterComponent } from '../shared/scope-context-filter/scope-context-filter.component';
import { UserService } from 'app/services/user/user.service';
import { LoggingService } from '@tdoe/design-system';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    CommonModule,
    BreadcrumbComponent,
    ScopeContextFilterComponent,
    ErrorCategoryCardsComponent,
    AverageDailyMembershipComponent,
    DemographicsComponent
  ],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent {

  protected viewModel$ = this.userService.userProfile$.pipe(
    filter(userProfile => !!userProfile),
    map(data => ({
      userPageScope: data.pageScope,
      selectedPageScope: data.pageScope,
      selectedYears: data.scopeDetails.years,
      selectedSchoolIds: data.getScopeIds('school'),
      selectedDistrictIds: data.getScopeIds('district')
    }))
  );

  public constructor (private userService: UserService,
                      private logger: LoggingService
  ) { }

  public isContextDataValid(searchTerms: ScopeContextFilterModel.SelectedData, userPageScope: PageScope): boolean {
    switch(userPageScope){
      case PageScope.State: {
        return !!searchTerms.years;
      }
      case PageScope.District: 
      case PageScope.School: {
        return !!searchTerms.years && !!searchTerms.districtIds;
      }
      default: {
        throw new Error('User does not have page scope.');
      }
    }
  }

  public onSearchClicked(searchTerms: SelectedData): void {
    this.logger.debug('DashboardComponent -> onSearchClicked', { data: { searchTerms }});
    this.userService.rescopeUser(searchTerms);
  }

}
