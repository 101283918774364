@if (viewModel$ | async; as viewModel) {
    @switch (viewModel.userProfile!.pageScope) {
        @case (PageScope.State) {
            <app-state-scope-context-filter
                [(districtIds)]="viewModel.selectedDistrictIds"
                [(schoolIds)]="viewModel.selectedSchoolIds"
                (searchClick)="onSearchClicked($event)"/>
        }
        @case (PageScope.District) {
            <app-district-scope-context-filter
                [(districtIds)]="viewModel.selectedDistrictIds!"
                [(schoolIds)]="viewModel.selectedSchoolIds!"
                (searchClick)="onSearchClicked($event)"/>
        }
        @case (PageScope.School) {
            <app-school-scope-context-filter
                [(schoolIds)]="viewModel.selectedSchoolIds!"
                (searchClick)="onSearchClicked($event)"/>
        }
    }
}