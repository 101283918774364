import { PageScope } from 'app/enums/page-scope';
export * as UserModel from './user.model';
import parseURI from 'parse-uri';
import { CacheService } from '../cache-service/cache.service';
import { createScopeClaim } from 'app/utilities/string-utilities/string-utilities';

export interface ScopeDetails {
    districtIds: string[];
    schoolIds: string[];
}

export class UserProfile {
    public appRoles?: string[];
    public districtRoles!: string[];
    public isDeveloper = false;
    public isStateUser = false;
    public pageScope!: PageScope;
    public schoolRoles!: string[];
    public scopeDetails!: ScopeDetails;

    private readonly cacheService: CacheService;

    public constructor (cacheService: CacheService, appRoles?: string[]) {
        this.cacheService = cacheService;
        this.appRoles = appRoles;
        if (appRoles) {
            if (appRoles.some(appRole => appRole.toLowerCase().startsWith('state.'))) {
                console.debug('UserProfile -> constructor', {
                    appRoles
                });
                this.isStateUser = true;
            }
            this.isDeveloper = !!appRoles.find(_ => _ === 'App.Developer');
            this.districtRoles = this.getRoles(appRoles, 'district');
            this.schoolRoles = this.getRoles(appRoles, 'school');
            this.pageScope = this.calculatePageScope();
            this.initializeScopeDetails();
        }
    }

    public get districtClaims(): string[] | undefined {
        return this.cacheService.devScopeDistricts?.map(_ => createScopeClaim(_));
    }

    public get schoolClaims(): string[] | undefined {
        return this.cacheService.devScopeSchools?.map(_ => createScopeClaim(_));
    }

    public allowedDistricts(): string[] | undefined {
        return (this.districtClaims ?? this.districtRoles).map(uri => parseURI(uri).host)
            .filter(districtId => !!districtId);
    }

    public allowedSchools(): string[] | undefined {
        return (this.schoolClaims ?? this.schoolRoles).map(uri => parseURI(uri).host)
            .filter(districtId => !!districtId);
    }

    public checkPermission(id: string, scope: 'district' | 'school', permission?: string): boolean {
        switch (scope) {
            case 'district':
                return (this.districtClaims ?? this.districtRoles).map(uriString => parseURI(uriString)).some(uri => uri.host === id && (!permission || uri.userInfo === permission)) ?? false;
            case 'school':
                return (this.schoolClaims ?? this.schoolRoles).map(uriString => parseURI(uriString)).some(uri => uri.host === id && (!permission || uri.userInfo === permission)) ?? false;
        }
    }

    public getScopeIds(type: 'district' | 'school'): string[] | undefined {
        switch (type) {
            case 'district':
                return this.scopeDetails.districtIds ?? this.allowedDistricts();
            case 'school':
                return this.scopeDetails.schoolIds ?? this.allowedSchools();
        }
    }

    private initializeScopeDetails(): void {
        this.scopeDetails = {
            districtIds: [],
            schoolIds: []
        };

        if (this.schoolRoles.length === 1) {
            this.scopeDetails = {
                ...this.scopeDetails,
                schoolIds: this.schoolRoles.map(schoolRoles => parseURI(schoolRoles).host)
                    .filter(districtId => !!districtId)
            };
        }

        if ((this.districtRoles?.length ?? 0) === 1) {
            this.scopeDetails = {
                ...this.scopeDetails,
                districtIds: this.districtRoles.map(districtRole => parseURI(districtRole).host)
                    .filter(districtId => !!districtId)
            };
        }
    }

    private calculatePageScope(): PageScope {
        if (this.isStateUser) return PageScope.State;
        if ((this.districtRoles?.length ?? 0) > 0) return PageScope.District;
        if ((this.schoolRoles?.length ?? 0) > 0) return PageScope.School;
        throw new Error('Minimum RBAC access not available');
    }

    private getRoles(appRoles: string[], type: 'district' | 'school'): string[] {
        return appRoles.filter(appRole => appRole.startsWith(`${type}://`));
    }

}
