import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { StudentEnrollmentClubMembership } from 'app/dto';
import { BehaviorSubject, map } from 'rxjs';

@Component({
  selector: 'app-enrollment-membership',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatSortModule
  ],
  templateUrl: './enrollment-membership.component.html',
  styleUrl: './enrollment-membership.component.scss'
})
export class EnrollmentMembershipComponent {

  @Input({ required: true })
  public set memberships(studentEnrollmentClubMemberships: StudentEnrollmentClubMembership[]){
    this._studentEnrollmentClubMembershipsSubject$.next(studentEnrollmentClubMemberships);
  }
  private _studentEnrollmentClubMembershipsSubject$ = new BehaviorSubject<StudentEnrollmentClubMembership[]>([]);
  private _dataSource  = new MatTableDataSource<StudentEnrollmentClubMembership>(this.memberships);
  private _columns = ['clubID', 'ctsoChapterID', 'ctsoMembershipID', 'beginDate', 'endDate'];

  public viewModel$ = this._studentEnrollmentClubMembershipsSubject$.pipe(
    map(studentEnrollmentClubMemberships => {
      this._dataSource.data = studentEnrollmentClubMemberships;
      return {
        dataSource: this._dataSource,
        displayedColumns: this._columns
      };
    })
  );

}
