<div class="container header-title">
    <div class="container">
        <h1>Student View</h1>
    </div>
</div>
@if (viewModel$ | async; as viewModel){
    <div class="container">
        <div class="container">
            <app-scope-context-filter 
                [userPageScope]="viewModel.userPageScope"
                [selectedYear]="viewModel.selectedYear"
                (searchClick)="onContextFilterSearchClicked($event)" />
        </div>
    </div>
    <div class="container student-filter">
        <div class="container">
            <app-class-filter
                (searchClicked)="onClassFilterSearchClicked($event)"
                (resetClicked)="onClassFilterResetClicked()"/>
        </div>
    </div>
    
    <div class="container table">
        <div class="container">
            <app-class-table
                [classes]="viewModel.classData"
                [totalRecords]="viewModel.totalRecords"
                (pageChanged)="onPageChanged($event)"
                (sortClicked)="onSortClicked($event)"/>
        </div>
    </div>
}