import { FormlyFieldConfig } from '@ngx-formly/core';

export * as AdditionalInfoModel from './additional-info.model';

export interface Category {
    name: string,
    expanded: boolean,
    fields: Field[]
}

export interface Field {
    filter?: FormlyFieldConfig;
    name: string,
    key: string,
    selected: boolean
}