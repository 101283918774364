import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DistrictScopeContextFilterComponent } from './district-scope-context-filter/district-scope-context-filter.component';
import { SchoolScopeContextFilterComponent } from './school-scope-context-filter/school-scope-context-filter.component';
import { StateScopeContextFilterComponent } from './state-scope-context-filter/state-scope-context-filter.component';
import { ScopeContextFilterModel } from './scope-context-filter.model';
import { BehaviorSubject, combineLatest, debounceTime, map } from 'rxjs';
import { PageScope } from 'app/enums/page-scope';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { UserService } from 'app/services/user/user.service';
import { UserProfile } from 'app/services/user/user.model';

interface viewModel {
    selectedYear?: number,
    selectedDistrictIds?: string[],
    selectedSchoolIds?: string[],
    userPageScope?: PageScope;
    userProfile?: UserProfile;
}

@Component({
  selector: 'app-scope-context-filter',
  standalone: true,
  imports: [
    CommonModule,     
    StateScopeContextFilterComponent, 
    DistrictScopeContextFilterComponent, 
    SchoolScopeContextFilterComponent,
    NgxSkeletonLoaderModule
  ],
  templateUrl: './scope-context-filter.component.html',
  styleUrl: './scope-context-filter.component.scss'
})
export class ScopeContextFilterComponent {

  @Input({required: true})
  public set userPageScope(userPageScope: PageScope) {
    this._userPageScopeSubject$.next(userPageScope);
  }

  @Input()
  public set selectedYear(selectedYear: number | undefined) {
    if(selectedYear){
      this._selectedYearSubject$.next(selectedYear);
    }
  }

  @Input()
  public set selectedDistrictIds(selectedDistrictIds: string[] | undefined) {
    if(selectedDistrictIds){
      this._selectedDistrictIdSubject$.next(selectedDistrictIds);
    }
  }
  @Output()
  public selectedDistrictIdsChange = new EventEmitter<string[] | undefined>();

  @Input()
  public set selectedSchoolIds(selectedSchoolIds: string[] | undefined) {
    if(selectedSchoolIds){
      this._selectedSchoolIdSubject$.next(selectedSchoolIds);
    }
  }
  @Output()
  public selectedSchoolIdsChange = new EventEmitter<string[] | undefined>();

  @Output()
  public searchClick = new EventEmitter<ScopeContextFilterModel.SelectedData>();

  public constructor(private users: UserService) { }

  public PageScope = PageScope;

  private _selectedYearSubject$ = new BehaviorSubject<number>(new Date().getFullYear()); 
  private _selectedDistrictIdSubject$ = new BehaviorSubject<string[]  | undefined>(undefined); 
  private _selectedSchoolIdSubject$ = new BehaviorSubject<string[]  | undefined>(undefined); 
  private _userPageScopeSubject$ = new BehaviorSubject<PageScope | undefined>(undefined);

  protected viewModel$ = combineLatest([
    this._selectedYearSubject$,
    this._selectedDistrictIdSubject$,
    this._selectedSchoolIdSubject$,
    this._userPageScopeSubject$,
    this.users.userProfile$
  ]).pipe(
    debounceTime(300),
    map(([year, districtId, schoolId, userPageScope, userProfile]) => ({year, districtId, schoolId, userPageScope, userProfile})),
    map(data => ({
      selectedYear: data.year,
      selectedDistrictId: data.districtId,
      selectedSchoolId: data.schoolId,
      userPageScope: data.userPageScope,
      userProfile: data.userProfile
    } as viewModel))
  );
  
  protected onSearchClicked(selectedData: ScopeContextFilterModel.SelectedData): void {
    this.searchClick.emit(selectedData);
  }
}
