/**
 * SWORD API
 * SWORD service API to be consumed by SWORD Angular SPA. Interfaces with Entra ID and DataBricks.
 *
 * OpenAPI spec version: v1
 * Contact: donnie.hyatt@tnedu.gov
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Model for error log entry
 */
export interface ErrorItem { 
    /**
     * Calendar number
     */
    calendarNumber?: string;
    /**
     * Datasource endpoint
     */
    dataSourceEndpoint?: string;
    /**
     * Entity ID
     */
    entityId?: string;
    /**
     * Error
     */
    error?: string;
    /**
     * Error category
     */
    errorCategory?: string;
    /**
     * Error code
     */
    errorCode?: string;
    /**
     * Error date
     */
    errorDate?: string;
    /**
     * Error report
     */
    errorReport?: string;
    /**
     * Identifier
     */
    id?: string;
    /**
     * Local class number
     */
    localClassNumber?: string;
    /**
     * School identifier
     */
    schoolId?: string;
    /**
     * School name
     */
    schoolName?: string;
    /**
     * School number
     */
    schoolNumber?: number;
    /**
     * Severity level
     */
    severityLevel?: string;
}
