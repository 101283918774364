import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { StudentEnrollmentHistory } from 'app/dto';
import { BehaviorSubject, map } from 'rxjs';

@Component({
  selector: 'app-enrollment-history',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatSortModule
  ],
  templateUrl: './enrollment-history.component.html',
  styleUrl: './enrollment-history.component.scss'
})
export class EnrollmentHistoryComponent {

  @Input({ required: true })
  public set studentEnrollmentHistorySet(studentEnrollmentHistorySet: StudentEnrollmentHistory[]){
    this._studentEnrollmentHistorySetSubject$.next(studentEnrollmentHistorySet);
  }

  private _studentEnrollmentHistorySetSubject$ = new BehaviorSubject<StudentEnrollmentHistory[]>([]);
  private _dataSource = new MatTableDataSource<StudentEnrollmentHistory>();
  private _displayedColumns = ['grade', 'year', 'district', 'school', 'enrollment', 'withdrawal', 'tos', 'primaryDistrict', 'primarySchool'];

  public viewModel$ = this._studentEnrollmentHistorySetSubject$.pipe(
    map(studentEnrollmentHistorySet => {
      this._dataSource.data = studentEnrollmentHistorySet;
      return {
        dataSource: this._dataSource,
        displayedColumns: this._displayedColumns
      };
    })
  );


}
