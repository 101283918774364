import { CommonModule } from '@angular/common';
import { Component, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { MatSelect, MatSelectChange, MatSelectModule } from '@angular/material/select';
import { TdoeDsModule } from '@tdoe/design-system';
import { District } from 'app/dto';

@Component({
  selector: 'app-district-select',
  standalone: true,
  imports: [
    MatSelectModule, 
    CommonModule, 
    TdoeDsModule
  ],
  templateUrl: './district-select.component.html',
  styleUrl: './district-select.component.scss'
})
export class DistrictSelectComponent {

  @Input({required: true})
  public districts!: District[];

  @Output()
  public searchClick = new EventEmitter<District[]>();

  @ViewChild('districtSelect') 
  public districtSelect!: MatSelect;

  private _selectedDistricts?: District[];

  protected onSearchClicked(): void {
    this.searchClick.emit(this._selectedDistricts);
  }
  
  protected onSelectionChanged($event: MatSelectChange): void {
    this._selectedDistricts = this.districts.filter(_ => ($event.value as string[]).includes(_.districtNumber.toString()));
  }
}
