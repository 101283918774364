<div class="container">
    <div class="container">
        <app-breadcrumb/>
        <h1>Dashboard</h1>
    </div>
</div>
<ng-container *ngIf="viewModel$ | async as viewModel">
    <div class="container">
        <div class="container">
            <app-scope-context-filter 
                [userPageScope]="viewModel.userPageScope"
                [selectedYear]="viewModel.selectedYear" 
                [selectedSchoolIds]="viewModel.selectedSchoolIds" 
                [selectedDistrictIds]="viewModel.selectedDistrictIds" 
                (selectedDistrictIdsChange)="onFilterChanged('district', $event)"
                (selectedSchoolIdsChange)="onFilterChanged('school', $event)"
                (searchClick)="onSearchClicked($event)" />
        </div>
    </div>
    <app-error-category-cards 
        *ngIf="viewModel.isSelectedDataValid" 
        [pageScope]="viewModel.selectedPageScope!" 
        [scopeDetails]="{ districtIds: viewModel.selectedDistrictIds ?? [], schoolIds: viewModel.selectedSchoolIds ?? []}"
        [year]="viewModel.selectedYear!" />
    <app-average-daily-membership 
        *ngIf="viewModel.isSelectedDataValid" 
        [pageScope]="viewModel.selectedPageScope!" 
        [scopeDetails]="{ districtIds: viewModel.selectedDistrictIds ?? [], schoolIds: viewModel.selectedSchoolIds ?? []}"
        [year]="viewModel.selectedYear!" />
    <app-demographics 
        *ngIf="viewModel.isSelectedDataValid" 
        [pageScope]="viewModel.selectedPageScope!" 
        [scopeDetails]="{ districtIds: viewModel.selectedDistrictIds ?? [], schoolIds: viewModel.selectedSchoolIds ?? []}"
        [year]="viewModel.selectedYear!" />
</ng-container>