<div class="person-search-terms-wrapper">
    <div class="person-search-terms">
        <form [formGroup]="formGroup">
            <formly-form [form]="formGroup" [model]="personSearchTerms" [fields]="formlyFields"></formly-form>
        </form>
        <div class="action-row">
            <button tdoe-button type="secondary" (click)="onResetClick()">Reset</button>
            <button tdoe-button (click)="onSearchClick()">Search</button>
        </div>
    </div>
</div>