@if(viewModel$ | async; as viewModel){
    <div class="container">
        <div class="container">
            <app-scope-context-filter 
                [userPageScope]="viewModel.userPageScope"
                [selectedYear]="viewModel.selectedYear" 
                [(selectedSchoolIds)]="viewModel.selectedSchoolId" 
                [(selectedDistrictIds)]="viewModel.selectedDistrictId" 
                (searchClick)="onContextFilterSearchClicked($event)" />
        </div>
    </div>

    <div class="container student-filter">
        <div class="container">
            <app-student-filter
                (searchClicked)="onStudentFilterSearchClicked($event)"
                (resetClicked)="onStudentFilterResetClicked()"/>
        </div>
    </div>

    <div class="container table">
        <div class="container">
            <app-student-table
                [students]="viewModel.studentData" 
                [totalRecords]="viewModel.studentDataTotalRecords"
                [pageScope]="viewModel.selectedPageScope"
                [selectedDistrictIds]="viewModel.selectedDistrictId"
                [selectedSchoolIds]="viewModel.selectedSchoolId"
                (pageChanged)="onStudentTablePageChanged($event)"
                (sortClicked)="onStudentTableSortClicked($event)" />
        </div>
    </div>
}
