import { CommonModule } from '@angular/common';
import { Component, inject, } from '@angular/core';
import { MembershipSummary } from 'app/dto';
import { MembershipService } from 'app/services/membership/membership.service';
import { UserService } from 'app/services/user/user.service';
import dayjs from 'dayjs';
import { combineLatest, debounceTime, map, mergeMap, Observable } from 'rxjs';

interface viewModel {
  year0MembershipSummary?: MembershipSummary,
  year1MembershipSummary?: MembershipSummary,
  year2MembershipSummary?: MembershipSummary
}

@Component({
  selector: 'app-average-daily-membership',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './average-daily-membership.component.html',
  styleUrl: './average-daily-membership.component.scss'
})
export class AverageDailyMembershipComponent {

  private readonly userService = inject(UserService);
  private readonly membershipService = inject(MembershipService);

  public viewModel$: Observable<viewModel> =
    combineLatest([
      this.userService.userProfile$
    ]).pipe(
      debounceTime(300),
      map(([profile]) => ({ profile, year: profile?.scopeDetails.years![0] ?? dayjs().year() })),
      mergeMap(data => this.membershipService.getMembershipSummary(
        data.profile?.scopeDetails.years![0] ?? dayjs().year(),
        data.profile?.getScopeIds('district'),
        data.profile?.getScopeIds('school')
      ).pipe(
        map(membershipSummaries =>({
          year0MembershipSummary: this.getMostRecentYear(membershipSummaries),
          year1MembershipSummary: membershipSummaries.find(membershipSummary => membershipSummary.year === data.year! - 1),
          year2MembershipSummary: membershipSummaries.find(membershipSummary => membershipSummary.year === data.year! - 2)
        }))
      ))
    );

  private getMostRecentYear(summaries: MembershipSummary[]): MembershipSummary | undefined {
    let mostRecentYear = 0;

    summaries.forEach(_ => {
      if (_.year! > mostRecentYear) {
        mostRecentYear = _.year ?? 0;
      }
    });

    return summaries.find(_ => _.year === mostRecentYear);
  }

}
