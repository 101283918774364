import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ScopeContextFilterModel } from '../scope-context-filter.model';
import { StudentService } from 'app/services/student/student.service';
import { SchoolService } from 'app/services/school/school.service';
import { FormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { CommonModule } from '@angular/common';
import { TdoeDsModule } from '@tdoe/design-system';
import { BehaviorSubject, combineLatest, map, mergeMap } from 'rxjs';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { PageScope } from 'app/enums/page-scope';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { District, School } from 'app/dto';

 interface viewModel {
  selectedYear: number;
  selectedDistrictIds?: string[];
  selectedSchoolIds?: string[];
  /** is the state of the component valid so that when the search button is clicked a value can be emitted */
  isValid: boolean;
  years: string[];
  districts: District[];
  schools: School[] | never[];
}

@Component({
  selector: 'app-school-scope-context-filter',
  templateUrl: './school-scope-context-filter.component.html',
  styleUrls: ['./school-scope-context-filter.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    MatExpansionModule,
    CommonModule,
    TdoeDsModule,
    NgxSkeletonLoaderModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule
  ],
  host: { class: 'scope-context-filter' } 
})
export class SchoolScopeContextFilterComponent {
    @Input()
    public set year(year: number | undefined) {
      if(year){
        this._selectedYearSubject$.next(year);
      }
    }

    @Input()
    public set districtIds(districtIds: string[] | undefined) {
      this.selectedDistrictIdSubject$.next(districtIds);
    }
    @Output() public districtIdsChange = new EventEmitter<string[] | undefined>();

    @Input({required: true})
    public set schoolIds(schoolIds: string[]) {
      this._selectedSchoolIdSubject$.next(schoolIds);
    }
    @Output() public schoolIdsChange = new EventEmitter<string[] | string[] | undefined>();
    
    @Output()
    public searchClick = new EventEmitter<ScopeContextFilterModel.SelectedData> ();

    private _selectedYearSubject$ = new BehaviorSubject<number>(new Date().getFullYear()); 
    private _selectedSchoolIdSubject$ = new BehaviorSubject<string[] | undefined>(undefined);
    private selectedDistrictIdSubject$ = new BehaviorSubject<string[] | undefined>(undefined);

    protected viewModel$ = combineLatest([
      this._studentService.getAvailableYears(), 
      this._schoolService.getDistricts(),
      this.selectedDistrictIdSubject$,
      this._selectedSchoolIdSubject$,
      this._selectedYearSubject$
    ]).pipe(
      map(([years, districts, selectedDistrictIds, selectedSchoolIds, selectedYear]) => ({years, districts, selectedDistrictIds, selectedSchoolIds, selectedYear})),
      mergeMap(data => this._schoolService.getSchools(data.selectedDistrictIds!).pipe(
        map(schools => ({
          selectedYear: data.selectedYear,
          selectedDistrictIds: data.selectedDistrictIds,
          selectedSchoolIds: data.selectedSchoolIds,
          years: data.years,
          districts: data.districts,
          schools,
          isValid: data.selectedYear !== undefined
              && data.selectedDistrictIds !== undefined
              && data.selectedSchoolIds !== undefined
        }))
      ))
    );

    protected searchTerms?: ScopeContextFilterModel.SelectedData;

    public constructor(
      private _studentService: StudentService,
      private _schoolService: SchoolService ){
      //
    }

    protected onYearChange(year: number): void {
      this._selectedYearSubject$.next(year);
    }

    protected onSearchClick(viewModel: viewModel): void {
      if(viewModel.selectedDistrictIds && viewModel.selectedSchoolIds){
        this.searchClick.emit({ 
          year: viewModel.selectedYear, 
          districtIds: viewModel.selectedDistrictIds, 
          schoolIds: viewModel.selectedSchoolIds,
          pageScope: PageScope.School
        });
      }
    }
}


