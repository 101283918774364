export * as StaffFilterModel from './staff-filter.model';

export interface StaffSearchTerms {
    firstName?: string;
    middleName?: string;
    lastName?: string;
    teacherLicenseNumber?: string; // TLN
    school?: string;
    staffOrganizationEmail?: string;
}
