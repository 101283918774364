@if (isLoading || !schools()) {
    <div class="skeleton-buttons">
        <ngx-skeleton-loader [theme]="{ 'width':'30%', 'min-width': '9rem','height': '2.5rem' }" count="3" appearance="line"/>
    </div>
    <ngx-skeleton-loader [theme]="{ 'height': '2rem' }" count="1" appearance="line"/>
    <ngx-skeleton-loader count="25" appearance="line"/>
} @else {
    <div class="header">
        @if (filterChipItems() && filterChipItems().length > 0) {
            <tdoe-chips [chips]="filterChipItems()" (onChipRemove)="filterChipItemRemoved($event)"/>
        }
        <div class="controls">
            <div class="buttons">
                <app-additional-info
                    [additionalInfoFields]="additionalInfoFields"
                    [contextKey]="additionalInfoContextKey"
                    (fieldSelected)="onAdditionalInfoSelectionChanged($event)" />
                <button tdoe-button size="large" (click)="onResetFiltersClick()" >Reset Filters</button>
                <button tdoe-button size="large" [matMenuTriggerFor]="menu">Export</button>
                <mat-menu #menu="matMenu" xPosition="before">
                    <button mat-menu-item>CSV</button>
                    <button mat-menu-item>PDF</button>
                    <button mat-menu-item>Excel</button>
                </mat-menu>
            </div>
        </div>
    </div>
    <div class="table-title">
        <span>School Section List</span>
    </div>
    <div class="scrollable-container">
        <mat-table matSort (matSortChange)="onSortChanged($event)" [dataSource]="schools()!">

            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    @if (enableColumnFilters) {
                        <form class="filter-form" [formGroup]="formlyFormGroup" (click)="$event.stopImmediatePropagation()">
                            <formly-form [form]="formlyFormGroup" [fields]="getColumnFilterConfig('name')" [model]="query()"></formly-form>
                        </form>
                    } @else {
                        <span>School Name</span>
                    }
                </mat-header-cell>
                <mat-cell *matCellDef="let school"> {{school.name}} ({{school.schoolYear}}) </mat-cell>
            </ng-container>

            <ng-container matColumnDef="schoolNumber">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    @if (enableColumnFilters) {
                        <form class="filter-form" [formGroup]="formlyFormGroup" (click)="$event.stopImmediatePropagation()">
                            <formly-form [form]="formlyFormGroup" [fields]="getColumnFilterConfig('schoolNumber')" [model]="query()"></formly-form>
                        </form>                        
                    } @else {
                        <span>School Number</span>
                    }
                </mat-header-cell>
                <mat-cell *matCellDef="let school"> {{school.schoolNumber}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="calendarName">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    @if (enableColumnFilters) {
                        <form class="filter-form" [formGroup]="formlyFormGroup" (click)="$event.stopImmediatePropagation()">
                            <formly-form [form]="formlyFormGroup" [fields]="getColumnFilterConfig('calendarName')" [model]="query()"></formly-form>
                        </form>
                    } @else {
                        <span>Calendar Name</span>
                    }
                </mat-header-cell>
                <mat-cell *matCellDef="let school"> {{school.additionalInformation.schoolCalendarOverview.calendarName}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="scheduledDays">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    @if (enableColumnFilters)  {
                        <form class="filter-form" [formGroup]="formlyFormGroup" (click)="$event.stopImmediatePropagation()">
                            <formly-form [form]="formlyFormGroup" [fields]="getColumnFilterConfig('scheduledDays')" [model]="query()"></formly-form>
                        </form>
                    } @else {
                        <span>Scheduled Days</span>
                    }
                </mat-header-cell>
                <mat-cell *matCellDef="let school"> {{school.scheduledDays}} </mat-cell>
            </ng-container>

            @if (dynamicColumns().length > 0) {
                @for (col of dynamicColumns(); track col)
                {
                    <ng-container [matColumnDef]="col.key">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                            @if (enableColumnFilters) {
                                <form class="filter-form" [formGroup]="formlyFormGroup" (click)="$event.stopImmediatePropagation()">
                                    <formly-form [form]="formlyFormGroup" [fields]="getColumnFilterConfig(col.key)" [model]="query()"></formly-form>
                                </form>
                            } @else {
                                <span [innerText]="col.name"></span>
                            }
                        </mat-header-cell>
                        <mat-cell *matCellDef="let school"> {{school[col.key]}} </mat-cell>
                    </ng-container>
                }
            }

            <mat-header-row *matHeaderRowDef="displayedColumns()"></mat-header-row>
            <mat-row *matRowDef="let school; columns: displayedColumns()" [attr.data-id]="school.schoolId" [routerLink]="['/data-lookup/school', school.schoolId]" [queryParams]="{ schoolName: formatSchoolName(school) }" [state]="{ school }"></mat-row>
                    
            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching applied filters</td>
            </tr>
        </mat-table>
    </div>
    <mat-paginator
        #paginator
        [length]="totalRecords()"
        pageSize="50"
        [pageSizeOptions]="[25, 50, 100, 500, 1000]"
        showFirstLastButtons
        (page)="onPageChanged($event)">
    </mat-paginator>
}