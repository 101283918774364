<div>
    <label for="districtSelect">Select District</label>
    <div>
        <mat-select #districtSelect (selectionChange)="onSelectionChanged($event)" multiple>
            @for (district of districts; track $index) {
                <mat-option [value]="district.districtNumber">{{district.name}}</mat-option>
            }
        </mat-select>
        <button tdoe-button id="searchButton" (click)="onSearchClicked()">Search</button>
    </div>
</div>
