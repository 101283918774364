import { HttpParams } from '@angular/common/http';
import dayjs from 'dayjs';

export * as HttpParamsUtilities from '../../utilities/http-params-utilities/http-params-utilities';

export function toHttpParams(...paramsObjects: object[]): HttpParams {
  let params = new HttpParams();

  paramsObjects.forEach(paramsObject => {
    Object.entries(paramsObject).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
        if (value instanceof Date) {
          params = params.set(key, dayjs(value).format('MM/DD/YYYY'));
        }
        else if (Array.isArray(value)) {
          value.forEach(item => {
            params = params.append(key, item.toString());
          });
        } else {
          params = params.set(key, value.toString());
        }
      }
    });
  });

  return params;
}
