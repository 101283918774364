import { Injectable } from '@angular/core';
import { District, School } from 'app/dto';

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  public storageKeys = {
    devScopeDistricts: 'DEV_SCOPE_DISTRICTS_KEY',
    devScopeSchools: 'DEV_SCOPE_SCHOOLS_KEY'
  };

  public get devScopeDistricts(): District[] | undefined {
    return this.getValue<District[]>(this.storageKeys.devScopeDistricts);
  }
  public set devScopeDistricts(val: District[] | undefined) {
    this.setValue(this.storageKeys.devScopeDistricts, val);
  }

  public get devScopeSchools(): School[] | undefined {
    return this.getValue<School[]>(this.storageKeys.devScopeSchools);
  }
  public set devScopeSchools(val: School[] | undefined) {
    this.setValue(this.storageKeys.devScopeSchools, val);
  }

  public setValue(key: string, obj?: unknown): void {
    if (obj) {
      localStorage.setItem(key, JSON.stringify(obj));
    } else {
      localStorage.removeItem(key);
    }
  }

  public getValue<T>(key: string): T | undefined {
    const json = localStorage.getItem(key);

    if (json) {
      return JSON.parse(json);
    }
    
    return undefined;
  }

}
