/* eslint-disable @typescript-eslint/no-explicit-any */
import { AfterViewInit, ChangeDetectorRef, Component, ViewChild, inject, input, model, output, computed, signal } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { CommonModule } from '@angular/common';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { Pagination, Sorting, TableDataModel } from 'app/models/table-data-model';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { School, SchoolQuery } from 'app/dto';
import { AdditionalInfoComponent } from '../additional-info/additional-info.component';
import { Category, Field } from 'app/services/additional-info/additional-info.model';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { ChipItem, TdoeButtonDirective, TdoeChipsComponent } from '@tdoe/design-system';
import { StringUtilities } from 'app/utilities/string-utilities/string-utilities';
import { ObjectUtilities } from 'app/utilities/object-utilities/object-utilities';
import { Dictionary } from 'app/models/dictionary';
import { catchError, debounceTime, distinctUntilChanged, Observable, tap } from 'rxjs';

import dayjs from 'dayjs';
import { RouterModule } from '@angular/router';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SchoolTableConfig } from './school-table.config';

@Component({
  selector: 'app-school-table',
  templateUrl: './school-table.component.html',
  styleUrls: ['./school-table.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatMenuModule,
    MatPaginatorModule,
    MatTableModule,
    MatTooltipModule,
    RouterModule,
    NgxSkeletonLoaderModule,
    FormlyModule,
    ReactiveFormsModule,
    TdoeButtonDirective,
    MatSortModule,
    AdditionalInfoComponent,
    TdoeChipsComponent
  ]
})
export class SchoolTableComponent implements AfterViewInit {
  public additionalInfoContextKey = 'SchoolTableComponent';

  public totalRecords = input.required<number>();

  public schools = input.required<School[] | undefined>();

  public query = model<SchoolQuery>({ });

  public pageChanged = output<Pagination>();

  public sortClicked = output<Sorting>();

  @ViewChild(MatPaginator)
  public paginator!: MatPaginator;

  @ViewChild(MatSort)
  public sort!: MatSort;

  @ViewChild(AdditionalInfoComponent)
  protected additionalInfoComponent!: AdditionalInfoComponent;

  protected readonly dynamicColumns = signal<Field[]>([]);

  protected isLoading = true;

  protected readonly formlyFormGroup = new FormGroup({});

  protected readonly additionalInfoFields: Category[] = [];

  public readonly enableColumnFilters = false;

  protected displayedColumns = computed(() => [
      ...this._staticColumns,
      ...this.dynamicColumns().map(field => field.key)
  ]);

  public formatSchoolName(school: School): string {
    return StringUtilities.FormatStringForUrl(school.name ?? '');
  }

  public getChipText(columnName: string): string {
    return this._columnFiltersConfig[columnName]?.props?.label ?? columnName;
  }
  
  protected filterChipItems = computed(() => 
    Object.entries(this.query())
      .map(([key, value]) => ({ key, value }))
      .filter(kvp => 
        ((Array.isArray(kvp.value) && kvp.value.length > 0) || (!!kvp.value))
        && !['schools', 'districts', 'pageScope', 'districtIds', 'schoolIds', 'year', 'years', 'pagination', 'sorting'].includes(kvp.key))
      .map(entry => ({
        text: `${this.getChipText(entry.key)}: '${this._columnFiltersConfig[entry.key]?.type === 'tdoe-date' ? dayjs(entry.value).format('M-D-YYYY') : entry.value }'`,
        persistent: false,
        key: entry.key,
        id: entry.key
      } as ChipItem)));

  public ngAfterViewInit(): void {
    this.isLoading = false;
    this.cdr.detectChanges();
  }
    
  protected onSortChanged(sort: Sort) : void {
    this.sortClicked.emit({
      sortColumn: sort.active,
      sortDirection: sort.direction
    } as TableDataModel.Sorting);
  }

  protected onResetFiltersClick(): void {
    this.query.set({});
  }

  protected onPageChanged(pageEvent: PageEvent): void {
    this.pageChanged.emit({ 
      pageSize: pageEvent.pageSize, 
      pageIndex: pageEvent.pageIndex } as TableDataModel.Pagination);
  }

  protected onAdditionalInfoSelectionChanged(categories: Category[]): void {
    const selectedAdditionalInfoFields = categories.flatMap(additionalInfoCategory => additionalInfoCategory.fields)
      .filter(field => field.selected)
      .map(field => field);
  
    this.dynamicColumns.set(selectedAdditionalInfoFields);
  }

  protected getColumnFilterConfig(key: string): FormlyFieldConfig[] {
    const config = this.columnFilterConfigs()[key];
    if (!config) {
      // Do not delete: this will warn us if there are any missing or misspelled keys.
      console.warn('Could not find formly config for key:', key);
      return [];
    }
    return config;
  }

  protected filterChipItemRemoved(filterChipItem: ChipItem): void {
    this.removeSearchTerm(filterChipItem.id);
  }

  public queryName(school: School): string {
    return StringUtilities.FormatStringForUrl(`${school.name}`);
  }

  private readonly cdr = inject(ChangeDetectorRef);

  private readonly _columnFiltersConfig: { [key: string]: FormlyFieldConfig } = SchoolTableConfig;

  private readonly columnFilterConfigs = computed(() => {
    const configs: { [key: string]: FormlyFieldConfig[] } = {};
    
    // Initialize all configs once
    Object.entries(this._columnFiltersConfig).forEach(([key, field]) => {
      const configField: FormlyFieldConfig  = { ...field }; 
      
      if (configField && !configField.hooks?.onInit) {
        configField.hooks = {
          ...configField.hooks,
          onInit: (field: FormlyFieldConfig): void => this.onFormlyFieldInit(field)
        };
      }
      
      configs[key] = [configField];
    });
    
    return configs;
  });

  private readonly _staticColumns = [
    'name',
    'schoolNumber',
    'calendarName',
    'scheduledDays'
  ];

  private onFormlyFieldInit(field: FormlyFieldConfig): void {
    if (!field.formControl || (field as any)._initialized) {
      return;
    }
    
    (field as any)._initialized = true;
    
    field.formControl.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(val => {
        console.debug('onFormlyFieldInit', {
          val,
          field
        });
        if (val) {
          if (field.type === 'tdoe-date') {
            val = dayjs(val).format('YYYY-MM-DD');
          }
          this.addFilterItemToSearchTerms(field, val);
        } else {
          this.removeSearchTerm(field.key as string);
        }
      }),
      catchError(err => {
        console.error('Error in form field processing', err);
        return new Observable();
      })
    ).subscribe();
  }

  private removeSearchTerm(filterItemKey: string): void {
    this.query.update(searchTerms => {
      const updatedQuery: Dictionary = { ... ObjectUtilities.removeFalsyStringProperties(searchTerms) };
      delete updatedQuery[filterItemKey];
      return updatedQuery  as SchoolQuery;
    });
  }

  private addFilterItemToSearchTerms(field: FormlyFieldConfig, value: unknown): void {
    this.query.update(searchTerms => ({
      ...searchTerms,
      [field.key as string]: value
    }));
  }

}
