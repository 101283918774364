import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  computed,
  inject,
  signal
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { Title } from '@angular/platform-browser';

import { StaffMember } from 'app/dto';
import { StaffService } from 'app/services/staff/staff.service';
import { TdoeButtonDirective } from '@tdoe/design-system';
import { AdditionalInfoComponent } from '../shared/additional-info/additional-info.component';
import { ObjectUtilities } from 'app/utilities/object-utilities/object-utilities';

import { StaffCategoryConfig, StaffViewConfig } from './staff-view.config';
import { NestedPropertyPipe } from 'app/pipes/nested-property/nested-property.pipe';

@Component({
  selector: 'app-staff-view',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatMenuModule,
    MatTableModule,
    TdoeButtonDirective,
    AdditionalInfoComponent,
    NestedPropertyPipe
  ],
  templateUrl: './staff-view.component.html',
  styleUrls: ['./staff-view.component.scss']
})
export class StaffViewComponent implements OnChanges, OnInit, OnDestroy {
  @Input() public set staffDetail(value: StaffMember | undefined) {
    this._staffDetail.set(value);
  }
  public get staffDetail(): StaffMember | undefined {
    return this._staffDetail();
  }

  @Input() public staffId!: string;

  public breadcrumbData: { [key: string]: unknown } | undefined;
  public expandedStates: Record<string, boolean> = {};

  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);
  private readonly titleService = inject(Title);
  private readonly staffService = inject(StaffService);
  private _staffDetail = signal<StaffMember | undefined>(undefined);
  private _categories = signal<StaffCategoryConfig[]>([]);
  private readonly fullCategories = StaffViewConfig.categories;

  public additionalInfoFields: StaffCategoryConfig[] =
    this.fullCategories.filter(cat => !cat.alwaysShow);

  public selectedCategories = computed(() => {
    const userCats = this._categories();
    const alwaysCats = this.fullCategories.filter(cat => cat.alwaysShow);

    let cats = this.mergeAlwaysShow(userCats, alwaysCats);
    cats = this.applyStaticFields(cats);
    cats = this.removeUnselectedFields(cats);
    cats = this.sortCategories(cats);
    return cats;
  });

  public viewModel = computed(() => {
    const selectedCategories = this.selectedCategories();
    const staff = this._staffDetail();
    const tableColumns = selectedCategories.reduce<Record<string, string[]>>((acc, cat) => {
      if (cat.isArray) {
        acc[cat.name] = cat.fields.map(f => this.strip(f.key));
      }
      return acc;
    }, {});

    return { staffDetail: staff, selectedCategories, tableColumns };
  });

  public constructor() {
    this.expandedStates['Staff Profile'] = true;
  }

  public async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes['staffId']?.currentValue) {
      await this.loadStaffDetail(changes['staffId'].currentValue);
    }
  }

  public async ngOnInit(): Promise<void> {
    const routeData = this.router.getCurrentNavigation()?.extras.state;
    if (routeData) {
      this.breadcrumbData = routeData['breadcrumb-data'];
      if (routeData['staff-detail']) {
        this._staffDetail.set(routeData['staff-detail']);
      }
    }

    if (this.staffDetail) {
      this.setPageTitle(this.staffDetail);
    } else {
      const params = await firstValueFrom(this.route.params);
      if (params?.['id']) {
        await this.loadStaffDetail(params['id']);
      }
    }
  }

  public getContextKey(): string {
    if (!this.staffId) {
      return 'StaffViewComponent';
    }
    return `StaffViewComponent:${this.staffId}`;
  }

  private async loadStaffDetail(staffId: string): Promise<void> {
    const staffMember = await firstValueFrom(this.staffService.getStaffMember(staffId));
    this.staffId = staffId;
    this._staffDetail.set(staffMember);
    if (staffMember) {
      this.setPageTitle(staffMember);
    }
  }

  public onFieldSelected(userCats: StaffCategoryConfig[]): void {
    const oldCats = this._categories();
    userCats.forEach(newCat => {
      const oldCat = oldCats.find(c => c.name === newCat.name);
      const oldSelectedCount = oldCat ? oldCat.fields.filter(f => f.selected).length : 0;
      const newSelectedCount = newCat.fields.filter(f => f.selected).length;
      if (oldSelectedCount === 0 && newSelectedCount > 0) {
        this.expandedStates[newCat.name] = true;
      }
    });
    this._categories.set(userCats);
  }

  public getArrayData(staff: StaffMember | undefined, path: string | undefined): unknown[] {
    if (!staff || !path) return [];
    const data = ObjectUtilities.getNestedProperty(staff, path);
    return Array.isArray(data) ? data : [];
  }

  private setPageTitle(staff: StaffMember): void {
    const name = `${staff.nameLast ?? ''} ${staff.nameFirst ?? ''}`.trim();
    this.titleService.setTitle(`${this.titleService.getTitle()} - ${name}`);
  }

  public ngOnDestroy(): void {
    const fullKey = `${this.getContextKey()}:selectedFieldState`;
    sessionStorage.removeItem(fullKey);
  }

  public strip(val: string): string {
    return val.replace(/\./g, '_');
  }

  private mergeAlwaysShow(
    userCats: StaffCategoryConfig[],
    alwaysCats: StaffCategoryConfig[]
  ): StaffCategoryConfig[] {
    const merged: StaffCategoryConfig[] = [...userCats];
    alwaysCats.forEach(ac => {
      if (!merged.some(uc => uc.name === ac.name)) {
        merged.push(ac);
      }
    });
    return merged;
  }

  private applyStaticFields(categories: StaffCategoryConfig[]): StaffCategoryConfig[] {
    return categories.map(cat => ({
      ...cat,
      fields: cat.fields.map(field =>
        field.isStatic ? { ...field, selected: true } : field
      )
    }));
  }

  private removeUnselectedFields(categories: StaffCategoryConfig[]): StaffCategoryConfig[] {
    return categories
      .map(cat => ({
        ...cat,
        fields: cat.alwaysShow
          ? cat.fields
          : cat.fields.filter(f => f.selected)
      }))
      .filter(cat => cat.alwaysShow || cat.fields.length > 0);
  }

  private sortCategories(categories: StaffCategoryConfig[]): StaffCategoryConfig[] {
    const indexMap = new Map<string, number>();
    this.fullCategories.forEach((cfgCat, i) => indexMap.set(cfgCat.name, i));

    return categories.sort((a, b) => {
      const aIndex = indexMap.get(a.name) ?? 9999;
      const bIndex = indexMap.get(b.name) ?? 9999;
      return aIndex - bIndex;
    });
  }

  public trackCategory(_index: number, cat: StaffCategoryConfig): string {
    return cat.name;
  }
}