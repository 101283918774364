<div class="container header-title">
    <div class="container">
        <h1>Class details</h1>
    </div>
</div>
@if (classDetail) {
    <div class="container">
        <div class="container">
            <div class="header">
                <div>
                    <a [routerLink]="['/data-lookup', 'class']" [state]="breadcrumbData"><mat-icon>arrow_back</mat-icon> Back to Search Results</a>
                </div>
                <div>
                    <app-additional-info 
                        [additionalInfoFields]="additionalInfoFields" 
                        [contextKey]="AdditionalInfoContextKey"
                        (fieldSelected)="onFieldSelected($event)" />
                </div>
            </div>
            <div mat-accordion class="mat-accordion">
                <mat-expansion-panel expanded hideToggle disabled>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Class Information
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="data-list">
                        <div class="field name-field">
                            <h3 class="value">{{classDetail.courseName}}</h3>
                            <span class="name">Course name</span>
                        </div>
                        <div class="field">
                            <span class="name">Local class number</span>
                            <span class="value">{{classDetail.localClassNumber}}</span>
                        </div>
                        <div class="field">
                            <span class="name">Class type</span>
                            <span class="value">{{classDetail.classType}}</span>
                        </div>
                        <div class="field">
                            <span class="name">Primary district</span>
                            <span class="value">{{classDetail.primaryDistrict}}</span>
                        </div>
                        <div class="field">
                            <span class="name">Primary school</span>
                            <span class="value">{{classDetail.primarySchool}}</span>
                        </div>
                        <div class="field">
                            <span class="name">Teaching method</span>
                            <span class="value">{{classDetail.teachingMethod}}</span>
                        </div>
                        <div class="field">
                            <span class="name">Class begin date</span>
                            <span class="value">{{classDetail.classStartDate | date}}</span>
                        </div>
                        <div class="field">
                            <span class="name">Class end date</span>
                            <span class="value">{{classDetail.classEndDate | date}}</span>
                        </div>
                        <div class="field">
                            <span class="name">Course code</span>
                            <span class="value">{{classDetail.courseCode}}</span>
                        </div>
                        <div class="field">
                            <span class="name">VEPC</span>
                            <span class="value">{{classDetail.vepc}}</span>
                        </div>
                        <div class="field">
                            <span class="name">Category</span>
                            <span class="value">{{classDetail.category}}</span>
                        </div>
                        <div class="field">
                            <span class="name">Classification</span>
                            <span class="value">{{classDetail.classification}}</span>
                        </div>
                        <div class="field">
                            <span class="name">Test window</span>
                            <span class="value">{{classDetail.testWindow}}</span>
                        </div>
                        <div class="field">
                            <span class="name">Class period</span>
                            <mat-button-toggle-group class="value" [hideMultipleSelectionIndicator]="true" multiple disabled>
                                <mat-button-toggle [checked]="classDetail.classPeriod?.includes(daysOfWeek.Sunday)">S</mat-button-toggle>
                                <mat-button-toggle [checked]="classDetail.classPeriod?.includes(daysOfWeek.Monday)">M</mat-button-toggle>
                                <mat-button-toggle [checked]="classDetail.classPeriod?.includes(daysOfWeek.Tuesday)">T</mat-button-toggle>
                                <mat-button-toggle [checked]="classDetail.classPeriod?.includes(daysOfWeek.Wednesday)">W</mat-button-toggle>
                                <mat-button-toggle [checked]="classDetail.classPeriod?.includes(daysOfWeek.Thursday)">T</mat-button-toggle>
                                <mat-button-toggle [checked]="classDetail.classPeriod?.includes(daysOfWeek.Friday)">F</mat-button-toggle>
                                <mat-button-toggle [checked]="classDetail.classPeriod?.includes(daysOfWeek.Saturday)">S</mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>
                        <div class="field">
                            <span class="name">Bell schedule minutes</span>
                            <span class="value">{{classDetail.bellScheduleMinutes}}</span>
                        </div>
                        <div class="field">
                            <span class="name">Honor flag</span>
                            <span class="value">{{classDetail.honorFlag}}</span>
                        </div>
                        <div class="field">
                            <span class="name">State dual credit</span>
                            <span class="value">
                                @if (classDetail.honorFlag) {
                                    <mat-icon>thumb_up</mat-icon>
                                } @else {
                                    <mat-icon>thumb_down</mat-icon>
                                }
                            </span>
                        </div>
                        <div class="field">
                            <span class="name">Local dual credit</span>
                            <span class="value">
                                @if (classDetail.honorFlag) {
                                    <mat-icon>thumb_up</mat-icon>
                                } @else {
                                    <mat-icon>thumb_down</mat-icon>
                                }
                            </span>
                        </div>
                        <div class="field">
                            <span class="name">Dual enrollment</span>
                            <span class="value">{{classDetail.dualEnrollment}}</span>
                        </div>
                        <div class="field">
                            <span class="name">Post secondary institution</span>
                            <span class="value">{{classDetail.postSecondaryInstitution}}</span>
                        </div>
                    </div>
                </mat-expansion-panel>
                @for (category of categories | async; track category) {
                    <ng-container>
                        <ng-template [ngTemplateOutlet]="categoryPanel" [ngTemplateOutletContext]="{category: category}"></ng-template>
                    </ng-container>
                }
            </div>
            <div mat-accordion class="mat-accordion">
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Course Details
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="data-list">
                        <div class="field">
                            <span class="name">Course code</span>
                            <span class="value">{{classDetail.courseCode}}</span>
                        </div>
                        <div class="field">
                            <span class="name">Course name</span>
                            <span class="value">{{classDetail.courseName}}</span>
                        </div>
                        <div class="field">
                            <span class="name">Abbreviated name</span>
                            <span class="value">{{classDetail.abbreviatedName}}</span>
                        </div>
                        <div class="field">
                            <span class="name">Exclude prelim report</span>
                            <span class="value">
                                @if (classDetail.excludePrelimReport) {
                                    <mat-icon>thumb_up</mat-icon>
                                } @else {
                                    <mat-icon>thumb_down</mat-icon>
                                }
                            </span>
                        </div>
                        <div class="field">
                            <span class="name">VPEC</span>
                            <span class="value">{{classDetail.vepc}}</span>
                        </div>
                        <div class="field">
                            <span class="name">Category</span>
                            <span class="value">{{classDetail.category}}</span>
                        </div>
                        <div class="field">
                            <span class="name">Classification</span>
                            <span class="value">{{classDetail.classification}}</span>
                        </div>
                    </div>
                </mat-expansion-panel>
            </div>
            @if (classDetail.additionalInfo && classDetail.additionalInfo.staff && classDetail.additionalInfo.staff.length > 0) {
                <div mat-accordion class="mat-accordion">
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Staff
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="sub-section">
                            <app-staff-table [staff]="classDetail.additionalInfo.staff"></app-staff-table>
                        </div>
                    </mat-expansion-panel>
                </div>
            }
            @if (classDetail.additionalInfo && classDetail.additionalInfo.students && classDetail.additionalInfo.students.length > 0) {
                <div mat-accordion class="mat-accordion">
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Students
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="sub-section">
                            <app-student-table [totalRecords]="classDetail.additionalInfo.students.length" [students]="classDetail.additionalInfo.students"></app-student-table>
                        </div>
                    </mat-expansion-panel>
                </div>
            }
        </div>
    </div>
}

<ng-template #categoryPanel let-category="category">
    <mat-expansion-panel [(expanded)]="category.expanded" >
        <mat-expansion-panel-header>
            <mat-panel-title>
                {{category.name}}
            </mat-panel-title>
        </mat-expansion-panel-header>
        @for (field of category.fields; track field) {
            <ng-container>
                <div class="field">
                    <span class="name">{{field.name}}</span>
                    <span class="value">{{field.value }}</span>
                </div>
            </ng-container>
        }
    </mat-expansion-panel>
</ng-template>