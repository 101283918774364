import { Component } from '@angular/core';
import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs';
import { StudentFilterComponent, StudentFilterModel } from '../shared/student-filter/student-filter.component';
import { CommonModule, NgTemplateOutlet } from '@angular/common';
import { StudentService } from 'app/services/student/student.service';
import { StudentTableComponent } from '../shared/student-table/student-table.component';
import { MatTableDataSource } from '@angular/material/table';
import { StaffFilterComponent, StaffFilterModel } from '../shared/staff-filter/staff-filter.component';
import { StaffModel, StaffService } from 'app/services/staff/staff.service';
import { StaffTableComponent } from '../shared/staff-table/staff-table.component';
import { TableDataModel } from 'app/models/table-data-model';
import { BehaviorSubject, combineLatest, debounceTime, iif, map, of, shareReplay,  switchMap } from 'rxjs';
import { StudentSearchTerms } from 'app/services/student/student.model';
import { Student } from 'app/dto';

@Component({
  selector: 'app-data-lookup-statewide',
  templateUrl: './data-lookup-statewide.component.html',
  styleUrl: './data-lookup-statewide.component.scss',
  standalone: true,
  imports: [
    MatTabsModule, 
    NgTemplateOutlet, 
    CommonModule, 
    StudentFilterComponent, 
    StaffFilterComponent,  
    StaffTableComponent, 
    StudentTableComponent
  ]
})
export class DataLookupStatewideComponent {
  private static readonly  TabNames = { staff: 'staff', student: 'student' };
 
  protected showStaffTable: boolean;

  protected staffDataSource = new MatTableDataSource<StaffModel.StaffMember>();

  private _studentTablePaginationSubject$ = new BehaviorSubject({ pageIndex: 0, pageSize: 50} as TableDataModel.Pagination);
  private _studentTableSortingSubject$ = new BehaviorSubject({} as TableDataModel.Sorting);
  private _studentSearchTermsSubject$ = new BehaviorSubject<StudentSearchTerms>({ year: new Date().getFullYear() });
  private _selectedTabSubject$ = new BehaviorSubject<string>(DataLookupStatewideComponent.TabNames.student);

  private _studentsPagedResponse$ = combineLatest([
    this._studentSearchTermsSubject$,
    this._studentTablePaginationSubject$,
    this._studentTableSortingSubject$
  ]).pipe(
    debounceTime(300),
    map(([studentSearchTerms, studentPagination, studentSorting]) => ({studentSearchTerms, studentPagination, studentSorting})),
    switchMap(data => 
      iif(() => this.hasSearchTerms(data.studentSearchTerms), 
       this._studentService.getStudentsBySearchTerms(data.studentSearchTerms, data.studentPagination, data.studentSorting).pipe(
          map((studentsPagedResponse: TableDataModel.PagedResponse<Student>) => studentsPagedResponse),
        ),
        of(undefined)
      )
    ),
  );

  private _studentTabViewModel$ = combineLatest([this._studentsPagedResponse$, this._studentSearchTermsSubject$]).pipe(
    map(([studentsPagedResponse, studentSearchTerms]) => ({studentsPagedResponse, studentSearchTerms})),
    map(data => ({
      data: data.studentsPagedResponse?.data ?? [],
      totalRecords: data.studentsPagedResponse?.totalRecords ?? 0,
      hasSearchTerms: this.hasSearchTerms(data.studentSearchTerms),
      searchTerms: data.studentSearchTerms
    })),
    shareReplay(1)
  );

  protected viewModel$ = this._selectedTabSubject$.pipe(
    switchMap(selectedTab => 
      iif(() => selectedTab.toLowerCase() === DataLookupStatewideComponent.TabNames.student.toLowerCase(),
        this._studentTabViewModel$,
        of({data: [], totalRecords: 0, hasSearchTerms: false, searchTerms: {}})
      )
    )
  );
  
  public constructor(
    private _studentService: StudentService, 
    private _staffService: StaffService) {
    this.showStaffTable = false;
  }

  protected onSearchClickedStudent(studentSearchTerms: StudentFilterModel.StudentSearchTerms): void {
    this._studentSearchTermsSubject$.next(studentSearchTerms);
  }

  protected onSearchClickedStaff(staffSearchTerms: StaffFilterModel.StaffSearchTerms): void {
    this.getStaff(staffSearchTerms);
  }

  protected onStudentTableSortClicked(sorting: TableDataModel.Sorting): void {
    this._studentTableSortingSubject$.next(sorting);
  }

  protected onStudentTablePageChanged(pagination: TableDataModel.Pagination): void {
    this._studentTablePaginationSubject$.next(pagination);
  }

  protected onSelectedTabChanged(matTabChangeEvent: MatTabChangeEvent): void {
    this._selectedTabSubject$.next(matTabChangeEvent.tab.textLabel);
  }

  private getStaff(staffSearchTerms: StaffFilterModel.StaffSearchTerms): void {
    if ( !this.hasSearchTerms(staffSearchTerms)) {
      this.staffDataSource.data = [];
      this.showStaffTable = false;
      return;
    }
    // avoid imperative subscriptions 
    this._staffService.getStaffBySearchTerms(staffSearchTerms).subscribe(results => {
      this.staffDataSource.data = results;
      this.showStaffTable = true;
    });
  }

  private hasSearchTerms(searchTerms: object): boolean  {
    return Object.keys(searchTerms).length > 0;
  }
}