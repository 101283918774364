import { AfterViewInit, ChangeDetectorRef, Component, Input, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { SchoolModel } from 'app/services/school/school.model';
import { TableDataModel } from 'app/models/table-data-model';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Sort } from '@angular/material/sort';

@Component({
  selector: 'app-school-table',
  templateUrl: './school-table.component.html',
  styleUrls: ['./school-table.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatMenuModule,
    MatPaginatorModule,
    MatTableModule,
    MatTooltipModule
  ]
})
export class SchoolTableComponent implements AfterViewInit, OnChanges {

  private _schools: SchoolModel.School[] = [];
  private _totalRecords = 0;
  private _selectedDistrictIds: string[] | undefined;
  private _selectedSchoolIds: string[] | undefined;
  private _pageScope: string | undefined;

  @Input({ required: true })
  public set schools(schools: SchoolModel.School[]) {
    this._schools = schools;
    this.dataSource.data = schools;
    this.changeDetectorRef.markForCheck();
  }

  @Input({ required: true })
  public set totalRecords(totalRecords: number) {
    this._totalRecords = totalRecords;
    this.changeDetectorRef.markForCheck();
  }

  @Input()
  public set selectedDistrictIds(value: string[] | undefined) {
    this._selectedDistrictIds = value;
  }

  @Input()
  public set selectedSchoolIds(value: string[] | undefined) {
    this._selectedSchoolIds = value;
  }

  @Input()
  public set pageScope(pageScope: string | undefined) {
    this._pageScope = pageScope;
    this.changeDetectorRef.markForCheck();
  }

  @Output()
  public pageChanged = new EventEmitter<TableDataModel.Pagination>();

  @Output()
  public sortClicked = new EventEmitter<TableDataModel.Sorting>();

  public dataSource = new MatTableDataSource<SchoolModel.School>();
  public displayedColumns: string[] = [
    'calendarNumber', 'schoolId', 'instructionalCalendar', 'scheduledDays', 'stockpileInclementWeatherDays',
    'stockpileProfessionalDevelopmentDays', 'inServiceDays', 'studentDay',
    'teacherDay', 'blockSchedule', 'springBlockBeginDate'
  ];

  public isSimpleFilter = true;

  @ViewChild(MatPaginator)
  public paginator!: MatPaginator;

  public constructor(private changeDetectorRef: ChangeDetectorRef) {
    console.log('SchoolTableComponent constructor');
  }

  public ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.paginator.page.subscribe((event: PageEvent) => this.onPageEvent(event));
    this.changeDetectorRef.detectChanges();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['schools'] && changes['schools'].currentValue) {
      this.dataSource.data = changes['schools'].currentValue;
    }
  }

  public onPageSizeChange(event: Event): void {
    const pageSizeSelect = event.target as HTMLSelectElement;
    this.paginator._changePageSize(Number.parseInt(pageSizeSelect.value, 10));
  }

  public onPageEvent(event: PageEvent): void {
    this.pageChanged.emit({
      pageIndex: event.pageIndex,
      pageSize: event.pageSize
    });
  }

  public onSortEvent(sort: Sort): void {
    this.sortClicked.emit({
      sortColumn: sort.active,
      sortDirection: sort.direction
    });
  }

  public toggleFilter(): void {
    this.isSimpleFilter = !this.isSimpleFilter;
  }
}
