/**
 * SWORD API
 * SWORD service API to be consumed by SWORD Angular SPA. Interfaces with Entra ID and DataBricks.
 *
 * OpenAPI spec version: v1
 * Contact: donnie.hyatt@tnedu.gov
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Student } from './student';

/**
 * Represents a paged response containing a subset of data along with pagination information.
 */
export interface StudentPagedResponse { 
    /**
     * Gets or sets the subset of data.
     */
    data?: Array<Student>;
    /**
     * Gets or sets the page index.
     */
    pageIndex?: number;
    /**
     * Gets or sets the page size.
     */
    pageSize?: number;
    /**
     * Gets or sets the total number of pages.
     */
    totalPages?: number;
    /**
     * Gets or sets the total number of records.
     */
    totalRecords?: number;
    /**
     * Gets or sets the sort column.
     */
    sortColumn?: string;
    /**
     * Gets or sets the sort direction.
     */
    sortDirection?: string;
}
