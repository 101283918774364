import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { ErrorCount, ErrorItem, Pagination, Sorting } from 'app/dto';
import { PagedResponse } from 'app/models';
import { HttpParamsUtilities } from 'app/utilities/http-params-utilities/http-params-utilities';

@Injectable({
  providedIn: 'root'
})
export class ErrorLogService {

  public constructor(private http: HttpClient) { }

  private readonly controllerUri = `${environment.apiBaseUrl}ErrorLog`;

  public getCounts(districtIds?: string[], schoolIds?: string[], years?: number[]): Observable<ErrorCount[]> {
    let params = new HttpParams();
    
    schoolIds?.forEach(schoolId => {
      params = params.append('schoolIds', schoolId);
    });

    districtIds?.forEach(districtId => {
      params = params.append('districtIds', districtId);
    });

    years?.forEach(year => {
      params = params.append('years', year);
    });

    return this.http.get<ErrorCount[]>(`${this.controllerUri}/Counts`, { params });
  }

  public getErrors(districtIds?: string[],
                   schoolIds?: string[],
                   years?: number[],
                   category?: string,
                   pagination?: Pagination,
                   sorting?: Sorting): Observable<PagedResponse<ErrorItem[]>> {
    
    let params = HttpParamsUtilities.toHttpParams(pagination ?? { pageSize: 50 }, sorting ?? {});
    
    schoolIds?.forEach(schoolId => {
      params = params.append('schoolIds', schoolId);
    });

    districtIds?.forEach(districtId => {
      params = params.append('districtIds', districtId);
    });

    years?.forEach(year => {
      params = params.append('years', year);
    });

    if (category) {
      params = params.append('category', category);
    }

    return this.http.get<PagedResponse<ErrorItem[]>>(`${this.controllerUri}/Errors`, { params });
  }

  public hasErrors(districtIds?: string[], schoolIds?: string[], years?: number[]): Observable<boolean> {
    let params = new HttpParams();
    
    schoolIds?.forEach(schoolId => {
      params = params.append('schoolIds', schoolId);
    });

    districtIds?.forEach(districtId => {
      params = params.append('districtIds', districtId);
    });

    years?.forEach(year => {
      params = params.append('years', year);
    });

    return this.http.get<boolean>(`${this.controllerUri}/HasErrors`, { params });
  }

}
