<ng-container *ngIf="viewModel$ | async as viewModel;">
    <div class="container">
        <div class="container">
            <!-- Updated to bind arrays directly from viewModel -->
            <app-scope-context-filter [userPageScope]="viewModel.userPageScope" [selectedYear]="viewModel.selectedYear"
                [selectedSchoolIds]="viewModel.selectedSchoolIds ? viewModel.selectedSchoolIds : []"
                [selectedDistrictIds]="viewModel.selectedDistrictIds ? viewModel.selectedDistrictIds : []"
                (searchClick)="onContextFilterSearchClicked($event)">
            </app-scope-context-filter>
        </div>
    </div>

    <div class="container staff-filter">
        <div class="container">
            <app-staff-filter (searchClicked)="onStaffFilterSearchClicked($event)"
                (resetClicked)="onStaffFilterResetClicked()">
            </app-staff-filter>
        </div>
    </div>

    <div class="container table">
        <app-staff-table [staff]="viewModel.staffData" [totalRecords]="viewModel.staffDataTotalRecords"
            [pageScope]="viewModel.selectedPageScope"
            [selectedDistrictIds]="viewModel.selectedDistrictIds ? viewModel.selectedDistrictIds : []"
            [selectedSchoolIds]="viewModel.selectedSchoolIds ? viewModel.selectedSchoolIds : []"
            (pageChanged)="onStaffTablePageChanged($event)" (sortClicked)="onStaffTableSortClicked($event)">
        </app-staff-table>
    </div>
</ng-container>