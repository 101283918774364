import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { BreadcrumbComponent } from 'app/components/shared/breadcrumb/breadcrumb.component';
import { ScopeContextFilterComponent } from 'app/components/shared/scope-context-filter/scope-context-filter.component';
import { ScopeContextFilterModel } from 'app/components/shared/scope-context-filter/scope-context-filter.model';
import { PageScope } from 'app/enums/page-scope';
import { BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, filter, map, of, shareReplay, startWith, Subject, switchMap, tap } from 'rxjs';
import { StudentFilterComponent } from '../shared/student-filter/student-filter.component';
import { StudentTableComponent } from '../shared/student-table/student-table.component';
import { StudentService } from 'app/services/student/student.service';
import { SchoolService } from 'app/services/school/school.service';
import { UserService } from 'app/services/user/user.service';
import { TableDataModel } from 'app/models/table-data-model';
import { Student } from 'app/dto';
import { StudentSearchTerms } from 'app/services/student/student.model';

@Component({
  selector: 'app-data-lookup-student',
  standalone: true,
  imports: [
    CommonModule,
    BreadcrumbComponent,
    ScopeContextFilterComponent,
    StudentFilterComponent,
    StudentTableComponent
  ],
  templateUrl: './data-lookup-student.component.html',
  styleUrl: './data-lookup-student.component.scss'
})
export class DataLookupStudentComponent {
  private _studentTablePaginationSubject$ = new BehaviorSubject({ pageIndex: 0, pageSize: 50} as TableDataModel.Pagination);
  private _studentTableSortingSubject$ = new BehaviorSubject({} as TableDataModel.Sorting);
  private _scopeContextFilterSelectedDataSubject$ = new Subject<ScopeContextFilterModel.SelectedData>();
  private _studentSearchTermsSubject$ = new BehaviorSubject<StudentSearchTerms>({});
  
  private _scopeContextFilterSelectedData$ = this._userProfileService.userProfile$.pipe(
    
    filter(profile => !!profile),
    switchMap(userProfile =>  this._scopeContextFilterSelectedDataSubject$.pipe(
      startWith({
        pageScope: userProfile!.pageScope,
        year: new Date().getFullYear(),
        schoolIds: userProfile?.scopeDetails.schoolIds,
        districtIds: userProfile?.scopeDetails.districtIds
      }),
      distinctUntilChanged((prev, curr) => this.isCurrentScopeFilterSelectedDataEqualToCurrent(prev, curr)),
      map(searchTerms => ({
        sessionState: userProfile,
        ...searchTerms!
      })),
      shareReplay(1)
    ))
  );

  private _selectedScopeDescriptor$ = this._scopeContextFilterSelectedData$.pipe(
    switchMap(scopeContextFilterData => {
      switch(scopeContextFilterData.pageScope){
        case PageScope.State: {
          return of(`${scopeContextFilterData.year} - Statewide`);
        }
        case PageScope.District: {
          return this._schoolService.getDistricts(scopeContextFilterData.districtIds).pipe(
            map(districts => districts.flatMap(_ =>`${scopeContextFilterData.year} - ${_.name}`))
          );
        }
        case PageScope.School: {
          return this._schoolService.getSchools(scopeContextFilterData.schoolIds!).pipe(
            map(schools => schools.flatMap(_ => `${scopeContextFilterData.year} - ${_.name}`))
          );
        }
      }
    })
  );
  
  private _studentsPagedResponse$ = combineLatest([
    this._studentSearchTermsSubject$,
    this._studentTablePaginationSubject$,
    this._studentTableSortingSubject$
  ]).pipe(
    debounceTime(300),
    map(([studentSearchTerms, studentPagination, studentSorting]) => ({studentSearchTerms, studentPagination, studentSorting})),
    switchMap(data => 
      this._studentService.getStudentsBySearchTerms(data.studentSearchTerms, data.studentPagination, data.studentSorting).pipe(
        map((studentsPagedResponse: TableDataModel.PagedResponse<Student>) => studentsPagedResponse)
    )),
  );

  protected viewModel$ = combineLatest([
    this._userProfileService.userProfile$.pipe(tap(userProfile => console.debug('userProfile:', userProfile))), 
    this._scopeContextFilterSelectedData$.pipe(tap(scopeContextFilterSelectedData => console.debug('scopeContextFilterSelectedData:', scopeContextFilterSelectedData))), 
    this._studentsPagedResponse$.pipe(tap(studentsPagedResponse => console.debug('studentsPagedResponse:', studentsPagedResponse))), 
    this._selectedScopeDescriptor$.pipe(tap(selectedScopeDescriptor => console.debug('selectedScopeDescriptor:', selectedScopeDescriptor))) ]).pipe(
    map(([
      userProfile, 
      scopeContextFilterSelectedData, 
      studentsPagedResponse, 
      selectedScopeDescriptor]) => ({ 
        userProfile,
        scopeContextFilterSelectedData,
        studentsPagedResponse,
        selectedScopeDescriptor
      })),
    tap(data => console.debug('data:', data)),
    map(data => ({
      userPageScope: PageScope.State,
      userScopeId: 0,
      selectedPageScope: data.scopeContextFilterSelectedData.pageScope,
      selectedYear: data.scopeContextFilterSelectedData.year,
      selectedSchoolId: data.scopeContextFilterSelectedData.schoolIds,
      selectedDistrictId: data.scopeContextFilterSelectedData.districtIds,
      studentData: data.studentsPagedResponse?.data ?? [],
      studentDataTotalRecords: data.studentsPagedResponse?.totalRecords ?? 0,
      selectedScopeDescriptor: data.selectedScopeDescriptor
    })),
    shareReplay(1)
  );

  public constructor (
    private _userProfileService: UserService,
    private _studentService: StudentService,
    private _schoolService: SchoolService
  ) {}

  //Clark: move closer to ScopeContextFilterModel.SelectedData so it can be reused
  private isCurrentScopeFilterSelectedDataEqualToCurrent(previous: ScopeContextFilterModel.SelectedData, current: ScopeContextFilterModel.SelectedData): boolean {
    console.debug('onStudentFilterSearchClicked -> isCurrentScopeFilterSelectedDataEqualToCurrent:', previous, current);
    return previous.year === current.year
      && previous.districtIds === current.districtIds
      && previous.schoolIds === current.schoolIds;
  }

  //Clark: move closer to StudentModel.StudentSearchTerms so it can be reused
  private isPreviousStudentSearchTermsEqualToCurrent(previous: StudentSearchTerms, current: StudentSearchTerms): boolean {
    console.debug('onStudentFilterSearchClicked -> isPreviousStudentSearchTermsEqualToCurrent:', previous, current);
    return previous?.year === current?.year
      && previous?.districtIds === current?.districtIds
      && previous?.schoolIds === current?.schoolIds
      && previous?.ssid === current?.ssid
      && previous?.serviceType == current?.serviceType
      && previous?.grade == current?.grade
      && previous?.middleName == current?.middleName
      && previous?.firstName == current?.firstName
      && previous?.lastName == current?.lastName
      && previous?.dateOfBirth == current?.dateOfBirth;
  }

  protected onStudentTableSortClicked(sorting: TableDataModel.Sorting): void {
    this._studentTableSortingSubject$.next(sorting);
  }

  protected onStudentTablePageChanged(pagination: TableDataModel.Pagination): void {
    this._studentTablePaginationSubject$.next(pagination);
  }

  protected onContextFilterSearchClicked(selectedScopeContextFilterData: ScopeContextFilterModel.SelectedData): void {
    console.debug('onContextFilterSearchClicked:', selectedScopeContextFilterData);
    this._scopeContextFilterSelectedDataSubject$.next(selectedScopeContextFilterData);
  }

  protected onStudentFilterSearchClicked(studentSearchTerms: StudentSearchTerms): void {
    console.debug('onStudentFilterSearchClicked -> studentSearchTerms:', studentSearchTerms);
    this._studentSearchTermsSubject$.next(studentSearchTerms);
  }

  protected onStudentFilterResetClicked(): void {
    this._studentSearchTermsSubject$.next({});
  }
}
