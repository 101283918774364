import { Component } from '@angular/core';
import { LoggingService } from '@tdoe/design-system';
import { BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, filter, map, of, shareReplay, startWith, Subject, switchMap, tap } from 'rxjs';
import { CommonModule } from '@angular/common';
import { ScopeContextFilterComponent } from 'app/components/shared/scope-context-filter/scope-context-filter.component';
import { ScopeContextFilterModel } from 'app/components/shared/scope-context-filter/scope-context-filter.model';

import { UserService } from 'app/services/user/user.service';
import { PageScope } from 'app/enums/page-scope';
import { SchoolService } from 'app/services/school/school.service';
import { ClassService } from 'app/services/class/class.service';
import { ClassTableComponent } from '../shared/class-table/class-table.component';
import { ClassFilterComponent } from '../shared/class-filter/class-filter.component';
import { ClassQuery, ClassSummaryPagedResponse, Pagination, Sorting } from 'app/dto';

@Component({
  selector: 'app-data-lookup-class',
  templateUrl: './data-lookup-class.component.html',
  styleUrl: './data-lookup-class.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    ClassTableComponent,
    ClassFilterComponent,
    ScopeContextFilterComponent
  ]
})
export class DataLookupClassComponent {

  private _classTablePaginationSubject$ = new BehaviorSubject({ pageIndex: 0, pageSize: 50 } as Pagination);
  private _classTableSortingSubject$ = new BehaviorSubject({} as Sorting);
  private _scopeContextFilterSelectedDataSubject$ = new Subject<ScopeContextFilterModel.SelectedData>();
  private _classQuerySubject$ = new BehaviorSubject<ClassQuery>({});

  private _scopeContextFilterSelectedData$ = this._userProfileService.userProfile$.pipe(
    filter(userProfile => !!userProfile),
    switchMap(userProfile => this._scopeContextFilterSelectedDataSubject$.pipe(
      startWith({
        pageScope: PageScope.State,
        year: new Date().getFullYear(),
        schoolId: 0,
        districtId: 0
      }),
      distinctUntilChanged((prev, curr) => this.isCurrentScopeFilterSelectedDataEqualToCurrent(prev, curr)),
      map(query => ({
        sessionState: userProfile,
        ...query
      })),
      shareReplay(1)
    ))
  );

  private _selectedScopeDescriptor$ = this._scopeContextFilterSelectedData$.pipe(
    switchMap(scopeContextFilterData => {
      switch (scopeContextFilterData.pageScope) {
        case PageScope.State: {
          return of(`${scopeContextFilterData.year} - Statewide`);
        }
        case PageScope.District: {
          return this._schoolService.getDistrict('0').pipe(
            map(district => `${scopeContextFilterData.year} - ${district.name}`)
          );
        }
        case PageScope.School: {
          return this._schoolService.getSchools(scopeContextFilterData.sessionState.getScopeIds('school') as string[]).pipe(
            map(schools => `${scopeContextFilterData.year} - ${schools.map(_ => _.name).join(', ')}`)
          );
        }
      }
    })
  );


  private _classesPagedResponse$ = combineLatest([
    this._classQuerySubject$,
    this._classTablePaginationSubject$,
    this._classTableSortingSubject$
  ]).pipe(
    debounceTime(300),
    map(([classQuery, classPagination, classSorting]) => ({ classQuery, classPagination, classSorting })),
    switchMap(data =>
      this._classService.getClassesBySearchTerms({
        ...data.classQuery,
        pagination: data.classPagination,
        sorting: data.classSorting
      }).pipe(
        map((studentsPagedResponse: ClassSummaryPagedResponse) => studentsPagedResponse)
      )),
  );

  protected viewModel$ = combineLatest([
    this._userProfileService.userProfile$,
    this._scopeContextFilterSelectedData$,
    this._classesPagedResponse$,
    this._selectedScopeDescriptor$]).pipe(
      map(([
        userProfile,
        scopeContextFilterSelectedData,
        classesPagedResponse,
        selectedScopeDescriptor]) => ({
          userProfile,
          scopeContextFilterSelectedData,
          classesPagedResponse,
          selectedScopeDescriptor
        })),
      tap(_ => this.logger.debug('DataLookupClassComponent -> viewModel$ -> combineLatest', { data: _ })),
      map(data => ({
        userPageScope: PageScope.State,
        userScopeId: 0,
        selectedPageScope: data.scopeContextFilterSelectedData.pageScope,
        selectedYear: data.scopeContextFilterSelectedData.year,
        classData: data.classesPagedResponse?.data ?? [],
        totalRecords: data.classesPagedResponse?.totalRecords ?? 0,
        selectedScopeDescriptor: data.selectedScopeDescriptor,
        studentFilterTitle: this.getClassFilterTitle(data.scopeContextFilterSelectedData.pageScope)
      })),
      shareReplay(1)
    );

  public constructor(
    private _classService: ClassService,
    private _userProfileService: UserService,
    private _schoolService: SchoolService,
    private readonly logger: LoggingService
  ) { }

  public onContextFilterSearchClicked(selectedData: ScopeContextFilterModel.SelectedData): void {

    this._scopeContextFilterSelectedDataSubject$.next(selectedData);
  }

  public onSortClicked(sorting: Sorting): void {
    this._classTableSortingSubject$.next(sorting);
  }

  public onPageChanged(pagination: Pagination): void {
    this._classTablePaginationSubject$.next(pagination);
  }

  //Clark: move closer to ScopeContextFilterModel.SelectedData so it can be reused
  private isCurrentScopeFilterSelectedDataEqualToCurrent(previous: ScopeContextFilterModel.SelectedData, current: ScopeContextFilterModel.SelectedData): boolean {
    console.debug('onStudentFilterSearchClicked -> isCurrentScopeFilterSelectedDataEqualToCurrent:', previous, current);
    return false;
    // previous.year === current.year
    //   && previous.districtId === current.districtId
    //   && previous.schoolId === current.schoolId;
  }

  private getClassFilterTitle(selectedPageScope: PageScope): string {
    switch (selectedPageScope) {
      case PageScope.State: {
        return 'Statewide Search';
      }
      case PageScope.District: {
        return 'District Search';
      }
      case PageScope.School: {
        return 'School Search';
      }
    }
  }

  protected onStudentTableSortClicked(sorting: Sorting): void {
    this.logger.debug('DataLookupClassComponent -> onStudentTableSortClicked', { data: { sorting } });
    this._classTableSortingSubject$.next(sorting);
  }

  protected onStudentTablePageChanged(pagination: Pagination): void {
    this._classTablePaginationSubject$.next(pagination);
  }

  // Should receive the class service model -> classSearchTerms
  protected onClassFilterSearchClicked(classQuery: ClassQuery): void {
    this.logger.debug('DataLookupClassComponent -> onClassFilterSearchClicked', { data: { classQuery } });
    this._classQuerySubject$.next(classQuery);
  }

  protected onClassFilterResetClicked(): void {
    this._classQuerySubject$.next({});
  }
}
