import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { ErrorCategory } from '../enums/error-category';
import { ErrorLogService } from 'app/services/error-log/error-log.service';
import { Observable, filter, map, mergeMap} from 'rxjs';
import { ErrorSubCategory } from '../enums/error-subcategory';
import { PageScope } from 'app/enums/page-scope';
import { ErrorLogServiceModel } from 'app/services/error-log/error-log.model';
import { ErrorTableComponent } from '../shared/error-table/error-table.component';
import { UserService } from 'app/services/user/user.service';
import { UserProfile } from 'app/services/user/user.model';

@Component({
  selector: 'app-error-log-student',
  standalone: true,
  imports: [CommonModule, MatTabsModule, ErrorTableComponent],
  templateUrl: './error-log-student.component.html',
  styleUrl: './error-log-student.component.scss'
})
export class ErrorLogStudentComponent {

    protected subCategoryErrorLabels$ = this._userProfileService.userProfile$.pipe(
        /** For PageScope.State scopeId is not set until a district is selected
         * so the following filter cancels stream execution if scope id is falsy.
        */ 
        filter(userProfile => !!(userProfile)),
        mergeMap(userProfile => this.getCounts(userProfile).pipe(
          map(categoryErrorCounts => {
            const category = categoryErrorCounts.find(category => category.name === ErrorCategory.student);
            return category?.subCategoryErrorCounts ?? [];
          }),
          map(subCategoryErrorCounts => {
            subCategoryErrorCounts.push({ 
              name: ErrorSubCategory.total, 
              count: subCategoryErrorCounts.reduce((accumulator, currentValue) => accumulator + currentValue.count, 0)
            });
            return subCategoryErrorCounts;
          }),
          map(subCategoryErrorCounts => ({
            enrollment: `Enrollment Errors (${subCategoryErrorCounts.find(category => category.name === ErrorSubCategory.enrollment)?.count ?? 0})`,
            scheduling: `Scheduling Errors (${subCategoryErrorCounts.find(category => category.name === ErrorSubCategory.scheduling)?.count ?? 0})`,
            dailyReporting: `Daily Reporting Errors (${subCategoryErrorCounts.find(category => category.name === ErrorSubCategory.dailyReporting)?.count ?? 0})`,
            warning: `Warnings (${subCategoryErrorCounts.find(category => category.name === ErrorSubCategory.warning)?.count ?? 0})`,
            total: `All (${subCategoryErrorCounts.find(category => category.name === ErrorSubCategory.total)?.count ?? 0})`
          }))
        ))
    );
  
    public constructor(
      private _userProfileService: UserService,
      private _errorLogService: ErrorLogService) {
        //
      }
  
      private getCounts(userProfile: UserProfile): Observable<ErrorLogServiceModel.CategoryErrorCount[]>{
        switch(userProfile.pageScope){
          case PageScope.State:
          case PageScope.District: {
            return this._errorLogService.getDistrictCounts(userProfile.scopeDetails.districtIds);
          }
          case PageScope.School: {
            return this._errorLogService.getSchoolCounts(userProfile.scopeDetails.schoolIds);
          }
        }
      }
}
