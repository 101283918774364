import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { ErrorLogServiceModel } from './error-log.model';
import { ObjectUtilities } from 'app/utilities/object-utilities/object-utilities';
export { ErrorLogServiceModel as ErrorLogModel } from './error-log.model';

@Injectable({
  providedIn: 'root'
})
export class ErrorLogService {

  public constructor(private http: HttpClient) { }

  public static CountsUrl = environment.apiBaseUrl + 'ErrorLog/Counts';

  public static ErrorsUrl = environment.apiBaseUrl + 'ErrorLog/Errors';

  public getSchoolCounts(schoolIds: string[] ): Observable<ErrorLogServiceModel.CategoryErrorCount[]> {
    let params = new HttpParams();
    
    schoolIds.forEach(schoolId => {
      params = params.append('schoolIds', schoolId);
    });

    return this.http.get<ErrorLogServiceModel.CategoryErrorCount[]>(ErrorLogService.CountsUrl, { params });
  }

  public getDistrictCounts(districtIds: string[]): Observable<ErrorLogServiceModel.CategoryErrorCount[]> {
    let params = new HttpParams();

    districtIds.forEach(districtId => {
      params = params.append('districtIds', districtId);
    });

    return this.http.get<ErrorLogServiceModel.CategoryErrorCount[]>(ErrorLogService.CountsUrl, { params });
  }

  public getStateCounts(): Observable<ErrorLogServiceModel.CategoryErrorCount[]> {
    return this.http.get<ErrorLogServiceModel.CategoryErrorCount[]>(ErrorLogService.CountsUrl);
  }

  public getSchoolErrors(schoolIds: string[]): Observable<ErrorLogServiceModel.ErrorItem[]> {
    let params = new HttpParams();
    
    schoolIds.forEach(schoolId => {
      params = params.append('schoolIds', schoolId);
    });

    return this.http.get<ErrorLogServiceModel.ErrorItem[]>(ErrorLogService.ErrorsUrl, { params });
  }

  public getDistrictErrors(districtIds: string[]): Observable<ErrorLogServiceModel.ErrorItem[]> {
    let params = new HttpParams();

    districtIds.forEach(districtId => {
      params = params.append('districtIds', districtId);
    });

    return this.http.get<ErrorLogServiceModel.ErrorItem[]>(ErrorLogService.ErrorsUrl, { params });
  }

  /**
   * 
   * @param categoryErrorCounts 
   * @returns ErrorLogServiceModel.ErrorCounts
   * @description This is a hack so I wouldn't have to update components that depended on getting an instance of ErrorLogServiceModel.ErrorCounts. ~Clark
   */
  public static convertToErrorCounts(categoryErrorCounts: ErrorLogServiceModel.CategoryErrorCount[]): ErrorLogServiceModel.ErrorCounts {
    const errorCounts: ErrorLogServiceModel.ErrorCounts = {
        school: 0,
        class: 0,
        staff: 0,
        student: 0,
        total: 0
    };
    if(categoryErrorCounts?.length > 0){
      for (const category of categoryErrorCounts) {
        if (category.name in errorCounts) {
          errorCounts[category.name as keyof ErrorLogServiceModel.ErrorCounts] = category.count;
        }
      }
    }

    errorCounts.total = ObjectUtilities.sum(errorCounts);

    return errorCounts;
  }
}
