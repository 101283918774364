import { Pipe, PipeTransform } from '@angular/core';
import { ObjectUtilities } from 'app/utilities/object-utilities/object-utilities';

@Pipe({
  name: 'nestedProperty',
  standalone: true
})
export class NestedPropertyPipe implements PipeTransform {
  /**
   * Transforms the given `value` by looking up the nested property
   * specified in `path`. Underscores in `path` are replaced with dots.
   * If the property is not found, returns `undefined` but logs a warning.
   */
  public transform(value: unknown, path: string | undefined): unknown {
    if (!value || !path) {
      return '';
    }

    const realPath = path.replace(/_/g, '.');
    const result = this.getNestedProperty(value, realPath);

    if (result === undefined) {
      console.warn(
        `NestedPropertyPipe warning: Could not find property "${realPath}" on the given object:`,
        value
      );
    }

    return result;
  }

  /**
   * Wraps the call to ObjectUtilities.getNestedProperty.
   * Making this a protected instance method lets us spy on it in tests.
   */
  protected getNestedProperty(value: unknown, realPath: string): unknown {
    return ObjectUtilities.getNestedProperty(value, realPath);
  }
}