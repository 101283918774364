<div>
    <h2 mat-dialog-title>{{data.props?.label}} Filter</h2>
    <div mat-dialog-content>
        <form [formGroup]="formly">
            <formly-form [form]="formly" [fields]="[data]" [model]="model"></formly-form>
        </form>
    </div>
    <div mat-dialog-actions>
    <button mat-flat-button color="accent" (click)="dialogRef.close()">Cancel</button>
    <button mat-flat-button color="warn" (click)="dialogRef.close({ name: 'RESET', key: data.key })">Reset</button>
    <button mat-stroked-button color="primary" (click)="dialogRef.close(data)" [disabled]="!data.formControl?.valid">Apply filter</button>
    </div>
</div>