import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ClassDetail, ClassQuery, ClassSummary } from 'app/dto';
import { environment } from 'environments/environment';
import { LoggingService } from '@tdoe/design-system';
import { PagedResponse } from 'app/models';

@Injectable({
  providedIn: 'root'
})
export class ClassService {
  private http = inject(HttpClient);
  private logger = inject(LoggingService);

  public getClassesBySearchTerms(classQuery: ClassQuery): Observable<PagedResponse<ClassSummary>>  {
    this.logger.debug('ClassService -> getClassesBySearchTerms', { data: { classQuery } });
    return this.http.post<PagedResponse<ClassSummary>>(`${environment.apiBaseUrl}classes/search`, classQuery);
  }

  public getClass(id: string): Observable<ClassDetail> {
    return this.http.get<ClassDetail>(`${environment.apiBaseUrl}classes/${id}`);
  }
}
