import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as StudentFilterModel from './student-filter.model';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { BehaviorSubject, combineLatest, map, Observable, of } from 'rxjs';
import { CommonModule } from '@angular/common';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ObjectUtilities } from 'app/utilities/object-utilities/object-utilities';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { TdoeAccordionDirective, TdoeButtonDirective } from '@tdoe/design-system';
import { MatExpansionModule } from '@angular/material/expansion';

export * as StudentFilterModel from './student-filter.model';

@Component({
  selector: 'app-student-filter',
  templateUrl: './student-filter.component.html',
  styleUrl: './student-filter.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyModule,
    NgxSkeletonLoaderModule,
    MatDatepickerModule,
    FormlyMatDatepickerModule,
    MatNativeDateModule,
    TdoeButtonDirective,
    TdoeAccordionDirective,
    MatExpansionModule
  ],
  host: {
    class: 'student-filter'
  }
})
export class StudentFilterComponent {

  @Output() public searchClicked = new EventEmitter<StudentFilterModel.StudentSearchTerms>();
  @Output() public resetClicked = new EventEmitter<void>();
  
  @Input()
  public set studentSearchTerms(studentSearchTerms: StudentFilterModel.StudentSearchTerms){
    // Creates a new instance of studentSearchTerms so it doesn't have a reference 
    // to the search terms from the parent component
    this._studentSearchTermsSubject$.next({...studentSearchTerms}); 
  }

  @Input()
  public set showYearSelect(showYearSelect: boolean){
    this._showYearSelectSubject$.next(showYearSelect);
  }

  private _studentSearchTermsSubject$ = new BehaviorSubject<StudentFilterModel.StudentSearchTerms>({});

  private _showYearSelectSubject$ = new BehaviorSubject<boolean>(false);

  private _formlyFormGroup = new FormGroup({});
  private formlyFormFields: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'display-flex',
      fieldGroup: [
        {
          key: 'nameLast',
          type: 'tdoe-input',
          props: {
            label: 'Last Name',
            type: 'text',
            appearance: 'outline'
          },
          className: 'text-field'
        },
        {
          key: 'nameFirst',
          type: 'tdoe-input',
          props: {
            label: 'First Name',
            type: 'text',
            appearance: 'outline'
          },
          className: 'text-field'
        },
        {
          key: 'nameMiddle',
          type: 'tdoe-input',
          props: {
            label: 'Middle Name',
            type: 'text',
            appearance: 'outline'
          },
          className: 'text-field'
        },
        {
          key: 'ssid',
          type: 'tdoe-input',
          props: {
            label: 'SSID',
            type: 'text',
            appearance: 'outline'
          },
          className: 'ssid-field'
        },
        {
          key: 'grades',
          type: 'tdoe-select',
          props: {
            multiple: true,
            label: 'Grade',
            options: [
              { text: 'K', value: 'K' },
              { text: '1', value: '1' },
              { text: '2', value: '2' },
              { text: '3', value: '3' },
              { text: '4', value: '4' },
              { text: '5', value: '5' },
              { text: '6', value: '6' },
              { text: '7', value: '7' },
              { text: '8', value: '8' },
              { text: '9', value: '9' },
              { text: '10', value: '10' },
              { text: '11', value: '11' },
              { text: '12', value: '12' }
            ],
            appearance: 'outline',
          },
          className: 'grade-field'
        },
        {
          key: 'dateOfBirth',
          type: 'tdoe-date',
          props: {
            label: 'Date of Birth',
            appearance: 'outline'
          },
          className: 'date-field'
        }
      ]
    }
  ];

  public viewModel$ = combineLatest([this._studentSearchTermsSubject$]).pipe(
    map(([studentSearchTerms]) => ({studentSearchTerms})),
    map(data => ({
        formlyFormGroup: this._formlyFormGroup,
        formlyFormFields: this.formlyFormFields,
        studentSearchTerms: data.studentSearchTerms
      
    }))
  );
 
  protected onSearchClick(): void {
    this.searchClicked.emit(StudentFilterComponent.setEmptyStringsToUndefined(this._formlyFormGroup.value));
  }

  protected onResetClick(): void {
    this.studentSearchTerms = {};
    this.resetClicked.emit();
  }

  public static setEmptyStringsToUndefined(studentSearchTerms: StudentFilterModel.StudentSearchTerms): StudentFilterModel.StudentSearchTerms {
    const trimmedStudentSearchTerms = ObjectUtilities.trimStrings(studentSearchTerms);
    return Object.fromEntries(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        Object.entries(trimmedStudentSearchTerms).filter(([_, value]) => value !== '' && value !== undefined)
    ) as StudentFilterModel.StudentSearchTerms;
  }

  private getYears(): Observable<Array<{ text: string, value: number }>> {
    const years = Array.from({ length: 5 }, (_, i) => new Date().getFullYear() - i);
    return of(
      years.map(year => ({text: year.toString(), value: year}))
    );
  }
}