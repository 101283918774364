import { CommonModule } from '@angular/common';
import { AfterContentInit, Component, Signal, signal } from '@angular/core';
import { ScopeContextFilterComponent } from 'app/components/shared/scope-context-filter/scope-context-filter.component';
import { ScopeContextFilterModel } from 'app/components/shared/scope-context-filter/scope-context-filter.model';
import { combineLatest, debounceTime, filter, map, switchMap, tap } from 'rxjs';
import { StudentFilterComponent, StudentFilterModel } from '../shared/student-filter/student-filter.component';
import { StudentTableComponent } from '../shared/student-table/student-table.component';
import { StudentService } from 'app/services/student/student.service';
import { UserService } from 'app/services/user/user.service';
import { TableDataModel } from 'app/models/table-data-model';
import { StudentModel } from 'app/services/student/student.model';
import * as dto from 'app/dto';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { UserModel } from 'app/services/user/user.model';

@Component({
  selector: 'app-data-lookup-student',
  standalone: true,
  imports: [
    CommonModule,
    ScopeContextFilterComponent,
    StudentFilterComponent,
    StudentTableComponent
  ],
  templateUrl: './data-lookup-student.component.html',
  styleUrl: './data-lookup-student.component.scss'
})
export class DataLookupStudentComponent implements AfterContentInit {

  private readonly _studentTablePagination = signal({ pageIndex: 0, pageSize: 50} as TableDataModel.Pagination);
  private readonly _studentTableSorting = signal({} as TableDataModel.Sorting);

  protected userProfile: Signal<UserModel.UserProfile | undefined>;

  protected isLoading = true;

  protected searchTerms = signal<StudentModel.StudentSearchTerms>({});
  
  protected readonly studentsPagedResponse = toSignal(combineLatest([
    toObservable(this.searchTerms),
    toObservable(this._studentTablePagination),
    toObservable(this._studentTableSorting)
  ]).pipe(
    debounceTime(300),
    map(([searchTerms, studentPagination, studentSorting]) => ({searchTerms, studentPagination, studentSorting})),
    switchMap(data => {
      return this._studentService.getStudentsBySearchTerms(data.searchTerms, data.studentPagination, data.studentSorting)
        .pipe(map((studentsPagedResponse: TableDataModel.PagedResponse<dto.Student>) => studentsPagedResponse));
      })
  ));

  public constructor (
    private readonly _userProfileService: UserService,
    private readonly _studentService: StudentService
  ) {
    this.userProfile = toSignal<UserModel.UserProfile>(this._userProfileService.userProfile$.pipe(
      filter(userProfile => !!userProfile),
      tap(userProfile => { 
          this.searchTerms.update(searchTerms => ({
            ...searchTerms,
            schoolIds: userProfile?.scopeDetails.schoolIds,
            districtIds: userProfile?.scopeDetails.schoolIds
          }));
          this.isLoading = false;
        })
      ));
  }

  public ngAfterContentInit(): void {
    this.isLoading = !this.userProfile();
  }

  protected onStudentTableSortClicked(sorting: TableDataModel.Sorting): void {
    this._studentTableSorting.set(sorting);
  }

  protected onStudentTablePageChanged(pagination: TableDataModel.Pagination): void {
    this._studentTablePagination.set(pagination);
  }

  protected onContextFilterSearchClicked(selectedScopeContextFilterData: ScopeContextFilterModel.SelectedData): void {
    this.searchTerms.update(searchTerms => ({
      ...searchTerms,
      ...selectedScopeContextFilterData
    }));
  }

  protected onStudentFilterSearchClicked(studentSearchTerms: StudentFilterModel.StudentSearchTerms): void {
    this.searchTerms.update(searchTerms => ({
      ...searchTerms,
      ...studentSearchTerms
    }));
  }

  protected onStudentFilterResetClicked(): void {
    this.searchTerms.set({});
  }
}
