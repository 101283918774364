<div class="container header-title">
  <div class="container">
    <h1>Staff Details</h1>
  </div>
</div>

<div class="container" *ngIf="viewModel() as viewData">
  <div class="container" *ngIf="viewData.staffDetail as staffDetail">
    <div class="header">
      <div class="back-link">
        <a [routerLink]="['/data-lookup', 'staff']" [state]="breadcrumbData">
          <mat-icon>arrow_back</mat-icon> Back to Staff List
        </a>
      </div>
      <div class="buttons">
        <app-additional-info
          [additionalInfoFields]="additionalInfoFields"
          [contextKey]="getContextKey()"
          (fieldSelected)="onFieldSelected($event)">
        </app-additional-info>
        <button tdoe-button size="large" [matMenuTriggerFor]="menu">Export</button>
        <mat-menu #menu="matMenu" xPosition="before">
          <button mat-menu-item>PDF</button>
        </mat-menu>
      </div>
    </div>

    <div mat-accordion class="mat-accordion">
      <mat-expansion-panel
        *ngFor="let category of viewData.selectedCategories; trackBy: trackCategory"
        [expanded]="expandedStates[category.name]">

        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ category.name }}
          </mat-panel-title>
        </mat-expansion-panel-header>

        <ng-container *ngIf="category.isArray; else dataListBlock">
          <div class="sub-section">
            <div class="scrollinator">
              <mat-table [dataSource]="getArrayData(staffDetail, category.dataPath)">
                <ng-container
                  *ngFor="let col of category.fields"
                  [matColumnDef]="strip(col.key)">
                  
                  <mat-header-cell *matHeaderCellDef>{{ col.name }}</mat-header-cell>
                  <mat-cell *matCellDef="let item">
                    {{ item | nestedProperty: strip(col.key) }}
                  </mat-cell>
                </ng-container>

                <mat-header-row
                  *matHeaderRowDef="viewData.tableColumns[category.name]">
                </mat-header-row>
                <mat-row
                  *matRowDef="let row; columns: viewData.tableColumns[category.name]">
                </mat-row>

                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="4">
                    No {{ category.name | lowercase }} found
                    for '{{ staffDetail.nameLast }} {{ staffDetail.nameFirst }}'
                  </td>
                </tr>
              </mat-table>
            </div>
          </div>
        </ng-container>

        <ng-template #dataListBlock>
          <div class="sub-section">
            <div class="data-list">
              <div class="field" *ngFor="let field of category.fields">
                <span class="name">{{ field.name }}</span>
                <span class="value">
                  {{ staffDetail | nestedProperty: strip(category.dataPath ? category.dataPath + '.' + field.key : field.key) }}
                </span>
              </div>
            </div>
          </div>
        </ng-template>

      </mat-expansion-panel>
    </div>
  </div>
</div>