<div class="container">
    <div class="container">
        <div class="header">
            <h3>Staff Search</h3>
            <div class="controls">
                <div class="buttons">
                    <button tdoe-button>Additional Info</button>
                    <button tdoe-button (click)="toggleFilter()">
                        {{ isSimpleFilter ? 'Simple Filters' : 'Advanced Filters' }}
                    </button>
                    <button tdoe-button [matMenuTriggerFor]="menu">Export</button>
                    <mat-menu #menu="matMenu" xPosition="before">
                        <button mat-menu-item>CSV</button>
                        <button mat-menu-item>PDF</button>
                        <button mat-menu-item>Excel</button>
                    </mat-menu>
                </div>
            </div>
        </div>
        <div class="table-title">
            <span>Staff Data</span>
        </div>
        <table mat-table [dataSource]="dataSource">

            <ng-container matColumnDef="firstName">
                <th mat-header-cell *matHeaderCellDef> First Name </th>
                <td mat-cell *matCellDef="let staff"> {{staff.nameFirst}} </td>
            </ng-container>

            <ng-container matColumnDef="middleName">
                <th mat-header-cell *matHeaderCellDef> Middle Name </th>
                <td mat-cell *matCellDef="let staff"> {{staff.nameMiddle}} </td>
            </ng-container>

            <ng-container matColumnDef="lastName">
                <th mat-header-cell *matHeaderCellDef> Last Name </th>
                <td mat-cell *matCellDef="let staff"> {{staff.nameLast}} </td>
            </ng-container>

            <ng-container matColumnDef="teacherLicenseNumber">
                <th mat-header-cell *matHeaderCellDef> TLN </th>
                <td mat-cell *matCellDef="let staff"> {{staff.teacherLicenseNumber}} </td>
            </ng-container>

            <ng-container matColumnDef="school">
                <th mat-header-cell *matHeaderCellDef> School </th>
                <td mat-cell *matCellDef="let staff"> {{staff.school}} </td>
            </ng-container>

            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef> Staff Organization Email </th>
                <td mat-cell *matCellDef="let staff"> {{staff.email}} </td>
            </ng-container>

            <!-- Header and Row Definitions -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator #paginator [pageSize]="50" [pageSizeOptions]="[25, 50, 100, 500, 1000]"
            [showFirstLastButtons]="false" [hidePageSize]="false" aria-label="Select page">
        </mat-paginator>
    </div>

</div>