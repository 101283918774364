<div class="layout">
    <section class="table-section">
        <div class="table-wrapper">
        <div class="table-header">
            <div class="controls">
                <div class="buttons">
                    <button tdoe-button>Additional Info</button>
                    <button tdoe-button (click)="toggleFilter()">
                        {{ isSimpleFilter ? 'Simple Filters' : 'Advanced Filters' }}
                    </button>
                    <button tdoe-button [matMenuTriggerFor]="menu">Export</button>
                    <mat-menu #menu="matMenu" xPosition="before">
                        <button mat-menu-item>CSV</button>
                        <button mat-menu-item>PDF</button>
                        <button mat-menu-item>Excel</button>
                    </mat-menu>
                </div>
            </div>
        </div>
        <div class="table-title">
            <span>Staff Information</span>
        </div>
        <table mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="firstName">
                <th mat-header-cell *matHeaderCellDef> First Name </th>
                <td mat-cell *matCellDef="let staff"> {{ staff?.nameFirst || 'N/A' }} </td>
            </ng-container>
            <ng-container matColumnDef="middleName">
                <th mat-header-cell *matHeaderCellDef> Middle Name </th>
                <td mat-cell *matCellDef="let staff"> {{ staff?.nameMiddle || 'N/A' }} </td>
            </ng-container>
            <ng-container matColumnDef="lastName">
                <th mat-header-cell *matHeaderCellDef> Last Name </th>
                <td mat-cell *matCellDef="let staff"> {{ staff?.nameLast || 'N/A' }} </td>
            </ng-container>
            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef> Staff Organization Email </th>
                <td mat-cell *matCellDef="let staff"> {{staff.email}} </td>
            </ng-container>
            <ng-container matColumnDef="district">
                <th mat-header-cell *matHeaderCellDef> District </th>
                <td mat-cell *matCellDef="let staff"> {{ staff?.district || 'N/A' }} </td>
            </ng-container>
            <ng-container matColumnDef="school">
                <th mat-header-cell *matHeaderCellDef> School </th>
                <td mat-cell *matCellDef="let staff"> {{ staff?.school || 'N/A' }} </td>
            </ng-container>
            <ng-container matColumnDef="educationLevel">
                <th mat-header-cell *matHeaderCellDef> Education Level </th>
                <td mat-cell *matCellDef="let staff"> {{ staff?.educationLevel || 'N/A' }} </td>
            </ng-container>
            <ng-container matColumnDef="gender">
                <th mat-header-cell *matHeaderCellDef> Gender </th>
                <td mat-cell *matCellDef="let staff"> {{ staff?.gender || 'N/A' }} </td>
            </ng-container>
            <ng-container matColumnDef="experienceInYears">
                <th mat-header-cell *matHeaderCellDef> Experience (Years) </th>
                <td mat-cell *matCellDef="let staff"> {{ staff?.experienceInYears || 'N/A' }} </td>
            </ng-container>
            <ng-container matColumnDef="conferralDate">
                <th mat-header-cell *matHeaderCellDef> Conferral Date </th>
                <td mat-cell *matCellDef="let staff"> {{ staff?.conferralDate || 'N/A' }} </td>
            </ng-container>
            <ng-container matColumnDef="dateAddedToFile">
                <th mat-header-cell *matHeaderCellDef> Date Added to File </th>
                <td mat-cell *matCellDef="let staff"> {{ staff?.dateAddedToFile || 'N/A' }} </td>
            </ng-container>
            <ng-container matColumnDef="teacherLicenseNumber">
                <th mat-header-cell *matHeaderCellDef> License Number </th>
                <td mat-cell *matCellDef="let staff"> {{ staff?.teacherLicenseNumber || 'N/A' }} </td>
            </ng-container>
            <ng-container matColumnDef="licensureCheck">
                <th mat-header-cell *matHeaderCellDef> Licensure Check </th>
                <td mat-cell *matCellDef="let staff"> {{ staff?.licensureCheck || 'N/A' }} </td>
            </ng-container>

            <!-- Header and Row Definitions -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
        <mat-paginator #paginator [pageSize]="50" [pageSizeOptions]="[25, 50, 100, 500, 1000]"
            [showFirstLastButtons]="false" [hidePageSize]="false" aria-label="Select page">
        </mat-paginator>
    </section>
</div>