import { Component, Inject } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';

@Component({
  selector: 'app-filter-modal',
  standalone: true,
  imports: [
    FormlyModule,
    FormlySelectModule,
    FormlyMatDatepickerModule,
    MatDialogModule,
    MatButtonModule,
    ReactiveFormsModule
  ],
  templateUrl: './filter-modal.component.html',
  styleUrl: './filter-modal.component.scss'
})
export class FilterModalComponent {

  protected formly = new FormGroup({});
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected model: any = {};

  public constructor(
    @Inject(MAT_DIALOG_DATA) public data: FormlyFieldConfig, 
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public dialogRef: MatDialogRef<any>) {
     Object.assign(this.model, data.model);
  }

}
