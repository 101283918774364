/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { AfterViewInit, ChangeDetectorRef, Component, Input, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { FormsModule } from '@angular/forms';
import { TableDataModel } from 'app/models/table-data-model';
import { StaffMember } from 'app/dto';
import { TdoeButtonDirective } from '@tdoe/design-system';

@Component({
  selector: 'app-staff-table',
  templateUrl: './staff-table.component.html',
  styleUrls: ['./staff-table.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatMenuModule,
    MatPaginatorModule,
    MatTableModule,
    TdoeButtonDirective
  ]
})
export class StaffTableComponent implements AfterViewInit, OnChanges {
  [x: string]: any;

  @Input() staff: StaffMember[] = [];
  @Input() totalRecords = 0;
  @Input() pageScope: any;
  @Input() selectedDistrictIds: string[] = [];
  @Input() selectedSchoolIds: string[] = [];

  @Output() pageChanged = new EventEmitter<TableDataModel.Pagination>();
  @Output() sortClicked = new EventEmitter<TableDataModel.Sorting>();

  public dataSource = new MatTableDataSource<StaffMember>();
  public displayedColumns: string[] = [
    'firstName',
    'middleName',
    'lastName',
    'email',
    'district',
    'school',
    'educationLevel',
    'gender',
    'teacherLicenseNumber',
    'licensureCheck',
    'experienceInYears',
    'conferralDate',
    'dateAddedToFile',
  ];
  public isSimpleFilter = true;
  @ViewChild(MatPaginator)
  public paginator!: MatPaginator;

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {
    console.log('StaffTableComponent constructor');
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.paginator.page.subscribe((event: PageEvent) => this.onPageEvent(event));
    this.changeDetectorRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['staff'] && changes['staff'].currentValue) {
      this.dataSource.data = changes['staff'].currentValue;
    }
  }

  onPageSizeChange(event: Event): void {
    const pageSizeSelect = event.target as HTMLSelectElement;
    this.paginator._changePageSize(Number.parseInt(pageSizeSelect.value, 10));
  }

  onPageEvent(event: PageEvent): void {
    this.pageChanged.emit({
      pageIndex: event.pageIndex,
      pageSize: event.pageSize
    });
  }

  onSortEvent(sort: any): void {
    this.sortClicked.emit({
      sortColumn: sort.active,
      sortDirection: sort.direction
    });
  }
  toggleFilter(): void {
    this.isSimpleFilter = !this.isSimpleFilter;
  }
}
