/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component } from '@angular/core';
import { ScopeContextFilterModel } from '../shared/scope-context-filter/scope-context-filter.model';
import { ErrorCategoryCardsComponent } from './error-category-cards/error-category-cards.component';
import { CommonModule } from '@angular/common';
import { distinctUntilChanged,  filter,  map, startWith, Subject, switchMap } from 'rxjs';
import { PageScope } from 'app/enums/page-scope';
import { BreadcrumbComponent } from '../shared/breadcrumb/breadcrumb.component';
import { AverageDailyMembershipComponent } from './average-daily-membership/average-daily-membership.component';
import { DemographicsComponent } from './demographics/demographics.component';
import { ScopeContextFilterComponent } from '../shared/scope-context-filter/scope-context-filter.component';
import { UserService } from 'app/services/user/user.service';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    CommonModule,
    BreadcrumbComponent,
    ScopeContextFilterComponent,
    ErrorCategoryCardsComponent,
    AverageDailyMembershipComponent,
    DemographicsComponent
  ],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent {

  public _scopeContextFilterSelectedDataSubject$ = new Subject<ScopeContextFilterModel.SelectedData>();

  protected viewModel$ = this._userProfileService.userProfile$.pipe(
    filter(userProfile => !!userProfile),
    switchMap(userProfile =>  this._scopeContextFilterSelectedDataSubject$.pipe(
      distinctUntilChanged((prev, curr) => (
        prev.year === curr.year
        && prev.districtIds === curr.districtIds
        && prev.schoolIds === curr.schoolIds
      )),
      map(searchTerms => ({
        userProfile,
        isSelectedDataValid: this.isContextDataValid(
          searchTerms,
          userProfile.pageScope),
        ...searchTerms!
      })),
      startWith({
        userProfile,
        isSelectedDataValid: true,
        pageScope: userProfile.pageScope,
        year: new Date().getFullYear(),
        schoolIds: userProfile.scopeDetails.schoolIds,
        districtIds: userProfile.scopeDetails.districtIds
      })
    )),
    map(data => ({
      userPageScope: data.userProfile.pageScope,
      selectedPageScope: data.pageScope,
      selectedYear: data.year,
      selectedSchoolIds: data.schoolIds,
      selectedDistrictIds: data.districtIds,
      isSelectedDataValid: data.isSelectedDataValid,
      isUserPageScopeState: true,
      isUserPageScopeDistrict: false,
      isUserPageScopeSchool: false,
    }))
  );

  public constructor (private _userProfileService: UserService) { }

  public isContextDataValid(searchTerms: ScopeContextFilterModel.SelectedData, userPageScope: PageScope): boolean {
    switch(userPageScope){
      case PageScope.State: {
        return !! searchTerms.year;
      }
      case PageScope.District: 
      case PageScope.School: {
        return !! searchTerms.year && !! searchTerms.districtIds;
      }
      default: {
        throw new Error('User does not have page scope.');
      }
    }
  }

  public onSearchClicked(searchTerms: ScopeContextFilterModel.SelectedData): void {
    this._scopeContextFilterSelectedDataSubject$.next(searchTerms);
  }

  public onFilterChanged(source: 'school' | 'district', viewModel: any, selections?: string[]): void {
    switch (source) {
      case 'school':
        viewModel.selectedSchoolIds = selections;
        break;
      case 'district':
        viewModel.selectedDistrictIds = selections;
        break;
    }
    this._scopeContextFilterSelectedDataSubject$.next({
      pageScope: viewModel.userPageScope,
      year: viewModel.selectedYear
    });
  }
}
