import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { PageScope } from 'app/enums/page-scope';
import { MembershipModel } from 'app/services/membership/membership.model';
import { MembershipService } from 'app/services/membership/membership.service';
import { UserModel } from 'app/services/user/user.model';
import { BehaviorSubject, combineLatest, debounceTime, filter, map, mergeMap, Observable } from 'rxjs';

interface viewModel {
  year0MembershipSummary?: MembershipModel.MembershipSummary,
  year1MembershipSummary?: MembershipModel.MembershipSummary,
  year2MembershipSummary?: MembershipModel.MembershipSummary
}

@Component({
  selector: 'app-average-daily-membership',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './average-daily-membership.component.html',
  styleUrl: './average-daily-membership.component.scss'
})
export class AverageDailyMembershipComponent {


  @Input({required: true})
  public set pageScope(value: PageScope) {
    this._pageScopeSubject$.next(value);
  }

  @Input({required: true})
  public set year(value: number) {
    this._yearSubject$.next(value);
  }

  @Input({ required: true })
  public set scopeDetails(scopeDetails: UserModel.ScopeDetails) {
    this._scopeDetailSubject$.next(scopeDetails);
  }

  private _scopeDetailSubject$ = new BehaviorSubject<UserModel.ScopeDetails | undefined>(undefined);
  
  private _pageScopeSubject$ = new BehaviorSubject<PageScope | undefined>(undefined);
  
  private _yearSubject$ = new BehaviorSubject<number | undefined>(undefined);

  protected viewModel$: Observable<viewModel> =
    combineLatest([
      this._scopeDetailSubject$,
      this._pageScopeSubject$.pipe(filter(pageScope => pageScope !== undefined)),
      this._yearSubject$.pipe(filter(pageScope => pageScope !== undefined))
    ]).pipe(
      debounceTime(300),
      map(([scopeDetails, pageScope, year]) => ({scopeDetails, pageScope, year})),
      mergeMap(data => this.MembershipSummaries(data.pageScope!, data.year!, data.scopeDetails).pipe(
        map(membershipSummaries =>({
          year0MembershipSummary: membershipSummaries.find(membershipSummary => membershipSummary.year === data.year!),
          year1MembershipSummary: membershipSummaries.find(membershipSummary => membershipSummary.year === data.year! - 1),
          year2MembershipSummary: membershipSummaries.find(membershipSummary => membershipSummary.year === data.year! - 2)
        }))
      ))
    );

  public constructor(private _membershipService: MembershipService){}

  public MembershipSummaries(pageScope: PageScope, year: number, scopeDetail?: UserModel.ScopeDetails): Observable<MembershipModel.MembershipSummary[]>{

    console.debug('AverageDailyMembershipComponent -> MembershipSummaries', {
      pageScope,
      year,
      scopeDetail
    });

    switch(pageScope){
      case PageScope.State: {
        return this._membershipService.getStateMembershipSummary(year);
      }
      case PageScope.District: {
        return this._membershipService.getDistrictMembershipSummary(year, scopeDetail?.districtIds ?? []);
      }
      case PageScope.School: {
       return this._membershipService.getSchoolMembershipSummary(year, scopeDetail?.schoolIds ?? []);
      }
      default: {
        throw new Error('User has no page scope.');
      }
    }
  }
}
