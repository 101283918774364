import { NavItem } from '@tdoe/design-system';
import { District, School } from './dto';

export  class TypeGuards {
  // This one could be moved into tdoe-ds
  public static isNavItem(obj: unknown): obj is NavItem {
      return typeof obj === 'object' && obj !== null &&
        'id' in obj && typeof (obj as NavItem).id === 'string' &&
        'text' in obj && typeof (obj as NavItem).text === 'string';
  }

  public static isDistrict(obj: unknown): obj is District {
    return typeof obj === 'object' && 
           obj !== null && 
           'districtNumber' in obj &&
           'districtId' in obj &&
           'name' in obj &&
           !('schoolId' in obj) &&
           !('schoolNumber' in obj);
  }

  public static isSchool(obj: unknown): obj is School {
    return typeof obj === 'object' && 
           obj !== null && 
           'districtNumber' in obj &&
           'districtId' in obj &&
           'name' in obj &&
           'schoolId' in obj &&
           'schoolNumber' in obj;
  }
}

