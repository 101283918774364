<div class="container">
    <div class="container">
        <app-breadcrumb/>
        <h1>Dashboard</h1>
    </div>
</div>
<ng-container *ngIf="viewModel$ | async as viewModel">
    <div class="container">
        <div class="container">
            <app-scope-context-filter 
                [userPageScope]="viewModel.userPageScope"
                [selectedYears]="viewModel.selectedYears" 
                [selectedSchoolIds]="viewModel.selectedSchoolIds" 
                [selectedDistrictIds]="viewModel.selectedDistrictIds"
                [multipleYear]="false"
                (searchClick)="onSearchClicked($event)" />
        </div>
    </div>
    <app-error-category-cards  />
    <app-average-daily-membership />
    <app-demographics 
        [pageScope]="viewModel.selectedPageScope!" 
        [scopeDetails]="{ districtIds: viewModel.selectedDistrictIds ?? [], schoolIds: viewModel.selectedSchoolIds ?? []}"
        [year]="viewModel.selectedYears![0]" />
</ng-container>