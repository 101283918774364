/**
 * SWORD API
 * SWORD service API to be consumed by SWORD Angular SPA. Interfaces with Entra ID and DataBricks.
 *
 * OpenAPI spec version: v1
 * Contact: donnie.hyatt@tnedu.gov
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * School Calendar Annual Summary
 */
export interface SchoolCalendar { 
    /**
     * Number of annual abbreviated days
     */
    abbreviatedDays?: number;
    /**
     * Calendar name
     */
    calendarName?: string;
    /**
     * Calendar number
     */
    calendarNumber?: string;
    /**
     * Number of annual discretionary days
     */
    discretionaryDays?: number;
    /**
     * Number of annual Stockpile inclement weather days
     */
    inclementWeatherDays?: number;
    /**
     * Number of annual instructional days
     */
    instructionalDays?: number;
    /**
     * DNumber of annual in service days
     */
    inServiceDays?: number;
    /**
     * Number of annual optional in service days
     */
    inServiceOptionalDays?: number;
    /**
     * Number of annual make up days
     */
    makeUpDays?: number;
    /**
     * Number of annual parent/teacher conference days
     */
    parentTeacherConfDays?: number;
    /**
     * Numer of annual Stockpile professional development days
     */
    professionalDevelopmentDays?: number;
    /**
     * Number of annual teacher vacation days
     */
    teacherVacationDays?: number;
    /**
     * Number of annual waived days
     */
    waivedDays?: number;
}
