<div *ngIf="viewModel$ | async as vm">
    <div class="header">
        <div class="controls">
            <div class="buttons">
                <button tdoe-button [matMenuTriggerFor]="menu">Export</button>
                <mat-menu #menu="matMenu" xPosition="before">
                    <button mat-menu-item>CSV</button>
                    <button mat-menu-item>PDF</button>
                    <button mat-menu-item>Excel</button>
                </mat-menu>
            </div>
        </div>
    </div>
    <div class="table-title">
        <span>Error Data</span>
    </div>
    <mat-table [dataSource]="vm.data" matSort *ngIf="vm.data && vm.columns"
               (matSortChange)="onSortChanged($event)">
        
        @for (col of vm.columns;track col) {
            <ng-container [matColumnDef]="col.name">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    <span [innerText]="col.label"></span>
                </mat-header-cell>
                <mat-cell *matCellDef="let error">{{error[col.name]}}</mat-cell>
            </ng-container>
        }

        <mat-header-row *matHeaderRowDef="vm.columnNames"></mat-header-row>
        <mat-row *matRowDef="let row; columns: vm.columnNames"></mat-row>

        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="7">No errors to display</td>
        </tr>
    </mat-table>

    <mat-paginator
        #paginator
        [length]="vm.pagedData.totalRecords"
        [pageSize]="vm.pagedData.pageSize"
        [pageIndex]="vm.pagedData.pageIndex"
        [pageSizeOptions]="[25, 50, 100, 500, 1000]"
        [showFirstLastButtons]="false"
        (page)="onPageChanged($event)">
    </mat-paginator>
</div>