/**
 * SWORD API
 * SWORD service API to be consumed by SWORD Angular SPA. Interfaces with Entra ID and DataBricks.
 *
 * OpenAPI spec version: v1
 * Contact: donnie.hyatt@tnedu.gov
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { SchoolCalendarDay } from './schoolCalendarDay';

/**
 * Reporting period segmentation
 */
export interface SchoolReportingPeriod { 
    /**
     * Period start date
     */
    beginDate?: string;
    /**
     * Period end date
     */
    endDate?: string;
    /**
     * Number of days in the period
     */
    numberOfDays?: number;
    /**
     * Reporting period segment identifier
     */
    periodNumber?: number;
    /**
     * School calendar events
     */
    calendarDays?: Array<SchoolCalendarDay>;
}
