<mat-accordion tdoe-accordion>
    <mat-expansion-panel [expanded]="false">
        <mat-expansion-panel-header>
            <mat-panel-title>Quick Search</mat-panel-title>
        </mat-expansion-panel-header>
        <div class="student-search-terms">
            @if (viewModel$ | async; as viewModel) {
                <form [formGroup]="viewModel.formlyFormGroup">
                    <formly-form [form]="viewModel.formlyFormGroup" [model]="viewModel.studentSearchTerms" [fields]="viewModel.formlyFormFields"/>
                    <div class="action-row">
                        <button tdoe-button id="student-reset-button" type="secondary" (click)="onResetClick()">Reset</button>
                        <button tdoe-button id="student-search-button"  (click)="onSearchClick()">Search</button>
                    </div>
                </form>
            }
            @else {
                <ngx-skeleton-loader count="2" appearance="line"/>
            }
        </div>
    </mat-expansion-panel>
</mat-accordion>