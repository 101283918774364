import { CommonModule } from '@angular/common';
import { Component, DestroyRef, EventEmitter, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { TdoeDsModule } from '@tdoe/design-system';
import { AdditionalInfoService, AdditionalInfoModel } from 'app/services/additional-info/additional-info.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'app-additional-info',
  templateUrl: './additional-info.component.html',
  styleUrl: './additional-info.component.scss',
  standalone: true,
  imports: [
    FormsModule, 
    CommonModule, 
    MatExpansionModule, 
    MatIconModule, 
    TdoeDsModule
  ]
})
export class AdditionalInfoComponent implements OnInit{

  @Output()
  public fieldSelected = new EventEmitter<AdditionalInfoModel.Category[]>();

  private _categoriesSubject = new BehaviorSubject<AdditionalInfoModel.Category[]>([]);

  protected readonly categories$: Observable<AdditionalInfoModel.Category[]> = this._categoriesSubject.asObservable();

  protected isPanelVisible = false;

  public constructor(private additionalInfoService: AdditionalInfoService,
    private _destroyReference: DestroyRef){
    //
  }

  public ngOnInit(): void {
    this.additionalInfoService.getFieldState()
      .pipe(takeUntilDestroyed(this._destroyReference))
      .subscribe(categories => {
        this._categoriesSubject.next(categories);
        this.emitUpdatedCategories();
      });
  }

  protected onAdditionalInfoClick(): void {
    this.togglePanel();
  }

  protected onCloseClicked(): void {
    this.togglePanel();
  }

  protected onSaveClicked(): void {
    this.additionalInfoService.saveFieldStateToLocalStorage(this._categoriesSubject.getValue());
  }

  protected onResetClicked(): void {
    (this.additionalInfoService.getSavedFieldState() ?? this.additionalInfoService.getDefaultFieldState())
      .pipe(takeUntilDestroyed(this._destroyReference))
      .subscribe(categories => {
        this._categoriesSubject.next(categories);
        this.fieldSelected.emit(categories);
      });
  }

  protected onFieldClicked(field: AdditionalInfoModel.Field): void {
    field.selected = !field.selected;

    if (!field.selected) {
      if (field.filter?.props) {
        delete field.filter.props['form'];
        const changer = field.filter.props['changer'];
        if (changer && changer.unsubscribe) {
          changer.unsubscribe();
        }
      }
    }

    this.emitUpdatedCategories();
  }

  protected onSelectAllClick($event: MouseEvent, filterCategory: AdditionalInfoModel.Category): void {
    $event.stopPropagation();
    filterCategory.fields.forEach(field => {
      field.selected = true;
    });
    this.emitUpdatedCategories();
  }

  private emitUpdatedCategories(): void {
    const currentCategories = this._categoriesSubject.getValue();
    this._categoriesSubject.next([...currentCategories]);
    this.additionalInfoService.saveFieldStateToSessionStorage(currentCategories);
    this.fieldSelected.emit(currentCategories);
  }

  private togglePanel(): void {
    this.isPanelVisible = !this.isPanelVisible;
  }
}
